import { Injectable, Inject } from '@angular/core';
import { HttpService } from '@techops-ui/common/helpers';
import { Observable } from 'rxjs';
import { LtermData, StationCodes } from '../+state/lterm.interfaces';
import { LtermEnvironment } from '@techops-ui/common/interfaces/lterm';

@Injectable()
export class BackendService {
  constructor(@Inject('environment') private environment: LtermEnvironment, private httpService: HttpService) {}

  getLtermDataByUserid(userid: string): Observable<LtermData[]> {
    return this.httpService.get(this.environment.ltermApi + '/' + userid);
  }
  getLtermData(userid: string, station: string): Observable<LtermData[]> {
    return this.httpService.get(this.environment.ltermApi + '/' + userid + '/' + station);
  }
  getStationsData(): Observable<StationCodes[]> {
    return this.httpService.get(this.environment.ltermStationsApi);
  }
}
