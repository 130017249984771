import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { SiteminderAuthenticationService } from './siteminder-authentication.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[aaHasRole]'
})
export class HasRoleDirective implements OnInit {
  @Input()
  aaHasRole: string | string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: SiteminderAuthenticationService
  ) {}

  ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    if (typeof this.aaHasRole === 'string') {
      this.aaHasRole = [this.aaHasRole];
    }
    this.authService
      .hasAnyRole(this.aaHasRole)
      .pipe(take(1))
      .subscribe(authorized => {
        this.viewContainer.clear();
        if (authorized) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }
}
