import { InjectionToken } from '@angular/core';

export interface SiteminderAgentConfig {
  /**
   * Siteminder Agent Url.
   */
  url?: string;
  /**
   * Flag to specify whether to redirect user to Siteminder Login page automatically
   * after user session times out
   * Defaults to `true`
   */
  autoRedirect?: boolean;
  /**
   * Flag to specify whether to keep the session alive when the user is active
   * Defaults to `true`
   */
  keepAlive?: boolean;
  /**
   * Client Application Id which will be sent as X-Client-ID in every http request header if includeTransactionId is set to `true`
   * Defaults to ``
   */
  app_id?: string;
  /**
   * Flag to specify whether to include X-Client-ID in every http request header
   * Defaults to `false`
   */
  includeClientId?: boolean;
  /**
   * Flag to specify whether to include X-Transaction-ID in every http request header
   * Defaults to `false`
   */
  includeTransactionId?: boolean;
  /**
   * Flag to specify whether web agent is enabled
   * Defaults to `false`
   */
  enableWebAgent?: boolean;

  /**
   * Flag to specify whether to include SMSESSION in http request header
   * Defaults to `true`
   */
  includeSMSession?: boolean;
}

export const _SM_AGENT_CONFIG = new InjectionToken<SiteminderAgentConfig>('SMAgentConfig Internal');
export const SM_AGENT_CONFIG = new InjectionToken<SiteminderAgentConfig>('SMAgentConfig');
export const LOCATION_TOKEN = new InjectionToken('Window location object');
export const X_CLIENT_ID = 'X-Client-ID';
export const X_TRANSACTION_ID = 'X-Transaction-ID';
export const SMSESSION = 'SMSESSION';
export const AUTHORIZATION = 'Authorization';
export const ENABLE_WEB_AGENT = 'ENABLE_WEB_AGENT';
