import { Injectable } from '@angular/core';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/finally';
import { Store, select } from '@ngrx/store';
import { getUser } from '@techops-ui/common/siteminder-authentication';
import { CookieService } from 'ngx-cookie-service';

export interface AuthenticationData {
  session: string;
  identity: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  defaultStation: string;
}

export const COOKIE_LABEL_TEST_SESSION = 'SMSESSION';
export const COOKIE_LABEL_TEST_IDENTITY = 'SMIDENTITY';
export const COOKIE_LABEL_TEST_USER_ID = 'SM_USER';
export const COOKIE_LABEL_TEST_FIRST_NAME = 'Firstname';
export const COOKIE_LABEL_TEST_LAST_NAME = 'Lastname';
export const COOKIE_LABEL_TEST_EMAIL = 'Email';
export const COOKIE_LABEL_TEST_STATION = 'Location';
export const ALL_COOKIE_LABELS_TEST = [
  COOKIE_LABEL_TEST_SESSION,
  COOKIE_LABEL_TEST_IDENTITY,
  COOKIE_LABEL_TEST_USER_ID,
  COOKIE_LABEL_TEST_FIRST_NAME,
  COOKIE_LABEL_TEST_LAST_NAME,
  COOKIE_LABEL_TEST_EMAIL,
  COOKIE_LABEL_TEST_STATION
];

export const COOKIE_LABEL_PROD_SESSION = 'SMSESSION';
export const COOKIE_LABEL_PROD_IDENTITY = 'SMIDENTITY';
export const COOKIE_LABEL_PROD_USER_ID = 'SM_USER';
export const COOKIE_LABEL_PROD_FIRST_NAME = 'SM_USER_FIRSTNAME';
export const COOKIE_LABEL_PROD_LAST_NAME = 'SM_USER_LASTNAME';
export const COOKIE_LABEL_PROD_EMAIL = 'SM_USER_EMAIL';
export const COOKIE_LABEL_PROD_STATION = 'SM_STATION';
export const ALL_COOKIE_LABELS_PROD = [
  COOKIE_LABEL_PROD_SESSION,
  COOKIE_LABEL_PROD_IDENTITY,
  COOKIE_LABEL_PROD_USER_ID,
  COOKIE_LABEL_PROD_FIRST_NAME,
  COOKIE_LABEL_PROD_LAST_NAME,
  COOKIE_LABEL_PROD_EMAIL,
  COOKIE_LABEL_PROD_STATION
];

export const ALL_COOKIE_LABELS = [...ALL_COOKIE_LABELS_TEST, ...ALL_COOKIE_LABELS_PROD];

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private siteMinderData$ = this.store.pipe(select(getUser));
  private data: AuthenticationData = {
    session: '',
    identity: '',
    defaultStation: '',
    email: '',
    firstName: '',
    lastName: '',
    userId: ''
  };
  get SiteminderData() {
    return { ...this.data };
  }

  constructor(private store: Store<any>, private cookieService: CookieService) {
    this.siteMinderData$.subscribe(smd => {
      if (!smd.sm_session) {
        console.warn('No session token found!');
      }
      this.data = {
        session: smd.sm_session,
        identity: smd.sm_access_token,
        defaultStation: smd.location,
        email: smd.email,
        firstName: smd.firstname,
        lastName: smd.lastname,
        userId: smd.user
      };
    });
  }

  getAuthentication(): AuthenticationData {
    const cookieTable: any = {};
    ALL_COOKIE_LABELS.forEach(label => {
      cookieTable[label] = this.cookieService.get(label);
    });
    return {
      session: cookieTable[COOKIE_LABEL_PROD_SESSION] || cookieTable[COOKIE_LABEL_TEST_SESSION] || '',
      identity: cookieTable[COOKIE_LABEL_PROD_IDENTITY] || cookieTable[COOKIE_LABEL_TEST_IDENTITY] || '',
      userId: cookieTable[COOKIE_LABEL_PROD_USER_ID] || cookieTable[COOKIE_LABEL_TEST_USER_ID] || '',
      firstName: cookieTable[COOKIE_LABEL_PROD_FIRST_NAME] || cookieTable[COOKIE_LABEL_TEST_FIRST_NAME] || '',
      lastName: cookieTable[COOKIE_LABEL_PROD_LAST_NAME] || cookieTable[COOKIE_LABEL_TEST_LAST_NAME] || '',
      email: cookieTable[COOKIE_LABEL_PROD_EMAIL] || cookieTable[COOKIE_LABEL_TEST_EMAIL] || '',
      defaultStation: cookieTable[COOKIE_LABEL_PROD_STATION] || cookieTable[COOKIE_LABEL_TEST_STATION] || ''
    };
  }

  clearAuthentication() {
    ALL_COOKIE_LABELS.forEach(element => {
      this.cookieService.delete(element);
    });
  }
}
