import { SiteminderUserState } from './siteminder-authentication.reducer';

export const initialState: SiteminderUserState = {
  sm_login_url:
    'https://smlogin.qtcorpaa.aa.com/login/SMLogin.jsp?TARGET=$SM$https://demoapp.apps.depaas.qcorpaa.aa.com',
  sm_logout_url:
    'https://smlogin.qtcorpaa.aa.com/login/SMLogout.jsp?originalTarget=https://demoapp.apps.depaas.qcorpaa.aa.com',
  sm_session_expires: new Date(),
  sm_session_timeout: 900,
  sm_idle_threshold: 780,
  sm_keep_alive_interval: 720,
  sm_access_token: 'ACCESS_TOKEN',
  sm_session: 'SMSESSION',
  user: '00123456',
  sm_roles: ['role1', 'role2'],
  firstname: 'John',
  lastname: 'Doe',
  location: 'PHX',
  email: 'john.doe@aa.com',
  sm_is_idle: false,
  sm_seconds_to_timeout: -1,
  sm_timed_out: false,
  sm_redirect_url: ''
};
