import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, empty } from 'rxjs';
import * as smActions from './siteminder-authentication.actions';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { map, filter, catchError, withLatestFrom } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SM_AGENT_CONFIG, SiteminderAgentConfig, LOCATION_TOKEN } from '../siteminder-authentication-config';
import { SiteminderUser } from './siteminder-authentication.interfaces';
import { SiteminderAuthenticationService } from '../siteminder-authentication.service';

@Injectable()
export class SiteminderAuthenticationEffects {
  constructor(
    @Inject(SM_AGENT_CONFIG) private config: SiteminderAgentConfig,
    @Inject(LOCATION_TOKEN) private location: any,
    private idle: Idle,
    private keepAlive: Keepalive,
    private actions$: Actions,
    private authService: SiteminderAuthenticationService
  ) {}

  @Effect()
  onSessionRenew$: Observable<Action> = this.actions$.pipe(
    ofType(smActions.SiteminderAuthenticationActionTypes.SESSION_EXTEND),
    map(() => {
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.interrupt();
      return new smActions.SessionRenewCompleteAction();
    })
  );

  @Effect()
  onIdleStart$: Observable<Action> = this.idle.onIdleStart.pipe(
    map(() => {
      console.log('User goes idle at', new Date());
      this.idle.clearInterrupts();
      return new smActions.IdleStartAction();
    })
  );

  @Effect()
  onIdleEnd$: Observable<Action> = this.idle.onIdleEnd.pipe(
    map(() => {
      console.log('User session reactivated at ', new Date());
      return new smActions.IdleEndAction();
    })
  );

  @Effect()
  onTimeoutWarning$: Observable<Action> = this.idle.onTimeoutWarning.pipe(
    map(seconds => new smActions.TimeoutWarningAction(seconds))
  );

  @Effect()
  onTimeout$: Observable<Action> = this.idle.onTimeout.pipe(
    map(() => new smActions.UnauthorizedRequestAction(this.config.url))
  );

  @Effect()
  onPingResponse$: Observable<Action> = this.keepAlive.onPingResponse.pipe(
    filter((response: HttpResponse<any>) => response.type === HttpEventType.Response),
    map((response: HttpResponse<SiteminderUser>) => response),
    map(renewedUserSession => {
      if (!renewedUserSession.body || !renewedUserSession.body.sm_access_token) {
        console.log('User session timed out at ', new Date());
        return new smActions.UnauthorizedRequestAction(this.config.url);
      }
      console.log('User session renewed at ', new Date());
      return new smActions.SessionRenewAction(renewedUserSession.body);
    })
  );

  @Effect()
  onUnauthorizedRequest$ = this.actions$.pipe(
    ofType<smActions.UnauthorizedRequestAction>(smActions.SiteminderAuthenticationActionTypes.UNAUTHORIZED_REQUEST),
    filter(action => action.payload === this.config.url),
    withLatestFrom(this.authService.redirectUrl()),
    map(data => ({ action: data[0], redirectUrl: data[1] })),
    map(data => {
      if (this.config.autoRedirect === true) {
        this.location.href = data.redirectUrl;
      }
      return new smActions.TimeOutAction(data.redirectUrl);
    }),
    catchError((_error, _caught) => {
      this.location.reload();
      return empty();
    })
  );
}
