import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewayTFPApolloModule } from './gateway-apollo.module';
import { CommonTFPGatewayService } from './services/tfp-gateway.service';

@NgModule({
  imports: [CommonModule, GatewayTFPApolloModule.forRoot()],
  providers: [CommonTFPGatewayService]
})
export class CommonDataAccessTfpGatewayModule {}
