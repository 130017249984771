import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './lib/home/home.component';
import { ThresholdComponent } from './lib/threshold/threshold.component';
import { AdminComponent } from './lib/admin/admin.component';
import { ReportsComponent } from './lib/reports/reports.component';

import { AircraftMainComponent } from './lib/aircraft-main/aircraft-main.component';
import { DeletedOiladdComponent } from './lib/deleted-oiladd/deleted-oiladd.component';
import { OiladdComponent } from './lib/oiladd/oiladd.component';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { SafeOspLandingpageComponent } from './lib/safe-osp-landingpage/safe-osp-landingpage.component';

import { SafeOspOiladdComponent } from './lib/safe-osp-oiladd/safe-osp-oiladd.component';
import { SafeAddoilComponent } from './lib/safe-addoil/safe-addoil.component';
import { ViewFeedbackComponent } from './lib/view-feedback/view-feedback.component';
import { SafeSuccessScreenComponent } from './lib/safe-success-screen/safe-success-screen.component';
import { NotificationsComponent } from './lib/notifications/notifications.component';
import { PredicitveComponent } from './lib/predictive-oil-add/predictive.component';
import {  UserRoles } from '@techops-ui/osp/data-access-root';
import { UnauthorizationComponent } from './lib/unauthorization/unauthorization.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'home', component: HomeComponent, data: { title: 'Home', icon: '',roles: []  }, canActivate: [PingAuthGuard] },
  { path: 'threshold-list', component: ThresholdComponent, data: { title: 'Threshold List', icon: '',role:[] }, canActivate: [PingAuthGuard]},
  { path: 'admin', component: AdminComponent, data: { title: 'Admin', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'predictive-oil-add', component: PredicitveComponent, data: { title: 'Predictive Oil Add', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'reports', component: ReportsComponent, data: { title: 'Reports', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'aircraft-main', component: AircraftMainComponent, data: { title: 'Admin', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'oil-add', component: OiladdComponent, data: { title: 'Oil Add', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'safe-oil-add', component: SafeOspOiladdComponent, data: { title: 'Safe Oil Add', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'add-safe-oil/:acNumber', component: SafeAddoilComponent, data: { title: 'Add Safe Oil', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'deleted-oil-add', component:DeletedOiladdComponent , data: { title: 'Deleted oil add ', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'add-success-safe-oil', component: SafeSuccessScreenComponent, data: { title: 'Add Safe Oil', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'oil-add-landingpage', component: SafeOspLandingpageComponent, data: { title: 'Landing Page', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'view-feedback', component: ViewFeedbackComponent, data: { title: 'View Feedback', icon: '' }, canActivate: [PingAuthGuard] },
  { path: 'notification', component: NotificationsComponent, data: { title: 'Notification', icon: '',role:[UserRoles.OSP_ADMIN] }, canActivate: [PingAuthGuard] },
  { path: 'usersafeoiladd/:acNumber', component: SafeOspOiladdComponent, data: { title: 'Safe Oil Add', icon: '' }, canActivate: [PingAuthGuard] },
  {
    path: 'unauthorized',
    component: UnauthorizationComponent,
    data: { title: 'Unauthorization', icon: '' }
    

  },
  {path: '**', redirectTo: '/home'}
 // { path: '**', pathMatch: 'full', component: HomeComponent,  data: { title: 'Home', icon: '' } }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
