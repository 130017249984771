import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'techops-ui-unauthorization',
  templateUrl: './unauthorization.component.html',
  styleUrls: ['./unauthorization.component.css']
})
export class UnauthorizationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
