import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeInitials'
})
export class EmployeeInitialsPipe implements PipeTransform {
  transform(value: any): any {
    let initials = 'UK';
    if (value) {
      if (value.FirstName) {
        initials = value.FirstName[0];
      }
      if (value.LastName) {
        initials = initials + value.LastName[0];
      }
    }
    return initials;
  }
}
