import { OSPAppEnvironment } from '@techops-ui/osp/shared';
import { GatewayEnvironmentDev } from '@techops-ui/common/data-access/gateway';
import { TFPGatewayEnvironmentStage } from '@techops-ui/common/data-access/tfp-gateway';

export const OSPEnvironmentStage: OSPAppEnvironment = {
  production: false,
  ospeventsApi: 'https://osp-api-np.maverick.aa.com/teq/ospapi/api',
 
  //Gateway
  ...GatewayEnvironmentDev,
  // feature flags
  // if needed add dependency here

  //TFP
  tfpgatewayApi: TFPGatewayEnvironmentStage.tfpgatewayApi + 'graphql',
  tfpAppName: 'osp',
  tfpVersion: '1.0',
  username: '',
  password: ''
};
