import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'techops-ui-safe-success-screen',
  templateUrl: './safe-success-screen.component.html',
  styleUrls: ['./safe-success-screen.component.css']
})
export class SafeSuccessScreenComponent implements OnInit {
  viewGridData: any;
  alertMsgStyle = { color: 'black' };
  // [
  //   {
  //     name: 'apu',
  //     servicedByID: '854351',
  //     pintsAdded: 6,
  //     spotNormal: 0.2,
  //     spotHigh: 0.39,
  //     spotRate: 0.006,
  //     averageNormal: 'NA',
  //     averageHigh: 'NA',
  //     averageRate: 'NA',
  //     suddenIncreaseNormal: 5,
  //     predictedOilAdd: 'NA',
  //     NDCL: 0,
  //     ACL: 0,
  //     suddenIncreaseOption: 'Option C'
  //   }
  // ];
  viewDefaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#0000',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  addHeaderBorderRight = {
    'background-color': '#ffff',
    'border-right': '1px solid #b29f9f',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  addGroupHeaderRightStyle = {
    'background-color': 'rgb(159, 180, 192)',
    color: 'black',
    'line-height': '1em',

    'border-right': '1px solid rgb(182, 165, 165)',
    'text-align': 'center'
  };
  addCurrentHeaderBorderLeft = {
    'background-color': '#ffff',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  addGroupHeaderLeftStyle = {
    'background-color': 'rgb(159, 180, 192)',
    color: 'black',
    'line-height': '1em',
    'border-right': '1px solid rgb(134, 119, 119)',
    'border-left': '1px solid rgb(182, 165, 165)',
    'text-align': 'center'
  };
  addHeaderBorderLeft = {
    'background-color': '#ffff',
    'border-left': '1px solid #b29f9f',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  viewBorderLeft = {
    'border-left': '1px solid #b29f9f'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  viewBorderRight = {
    'border-right': '1px solid #b29f9f'
  };
  constructor() {}

  ngOnInit() {
    const editDetails = JSON.parse(sessionStorage.getItem('viewOSPDetails'));
    if (editDetails) {
      this.viewGridData = editDetails;
      (this.viewGridData.reportList || []).forEach((result: any) => {
        let printAdded = { color: 'black' };
        let spotRateCurrent = { color: 'black' };
        let averageRateCurrent = { color: 'black' };

        if (result.name === 'apu') {
          printAdded = this.getColorByConditionPintsAdded(result.pintsAdded, result.suddenIncreaseNormal);
          spotRateCurrent = this.getColorByConditionSportRate(result.spotRate, result.spotNormal);
        } else {
          printAdded = this.getColorByConditionPintsAddedEngines(result);
          spotRateCurrent = this.getColorByConditionSpotRateEngines(result.spotRate, result.spotNormal, result.spotHigh);
          averageRateCurrent = this.getColorByConditionAverageRate(result.averageRate, result.averageNormal, result.averageHigh);
        }
        if (printAdded.color === 'red' || spotRateCurrent.color === 'red' || averageRateCurrent.color === 'red') {
          this.alertMsgStyle = { color: 'red' };
        }
      });
    }
  }
  getColorByConditionPintsAdded(curentValue: number, compareValue: number) {
    if (curentValue >= compareValue) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValue) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }

  getColorByConditionPintsAddedEngines(data: any) {
    if (data !== null) {
      const suddenIncreaseOption = data.suddenIncreaseOption;
      const predictedOilAdd = data.predictedOilAdd;
      const NDCL = data.NDCL;
      const ACL = data.ACL;
      const pintsAdded = data.pintsAdded;
      const suddenIncreaseNormal = data.suddenIncreaseNormal;
      const spotNormal = data.spotNormal;
      const spotRate = data.spotRate;
      const spotHigh = data.spotHigh;
      if (suddenIncreaseOption === 'Option A') {
        const predictedOilAddResult: number = predictedOilAdd * 1;
        const acl = ACL * 1 + predictedOilAddResult * 1;
        const ndcl = NDCL * 1 + predictedOilAddResult * 1;
        const greenCondOptA = pintsAdded < acl;

        if (greenCondOptA) {
          return { color: 'green', 'font-weight': 'bold' };
        }

        const yellowCondOptA = pintsAdded > acl && pintsAdded <= ndcl;

        if (yellowCondOptA) {
          return { color: 'gold', 'font-weight': 'bold' };
        }

        const redCondOptionA = pintsAdded > ndcl;

        if (redCondOptionA) {
          return { color: 'red', 'font-weight': 'bold' };
        }
      } else if (suddenIncreaseOption === 'Option B') {
        const greenCondOptB = pintsAdded < suddenIncreaseNormal || spotRate < spotHigh;

        const redCondOptB = pintsAdded >= suddenIncreaseNormal && spotRate > spotNormal;

        if (greenCondOptB) {
          return { color: 'green', 'font-weight': 'bold' };
        }

        if (redCondOptB) {
          return { color: 'red', 'font-weight': 'bold' };
        }
      } else if (suddenIncreaseOption === 'Option C') {
        const greenCondOptC = pintsAdded < suddenIncreaseNormal;
        const redCondOptC = pintsAdded >= suddenIncreaseNormal;

        if (greenCondOptC) {
          return { color: 'green', 'font-weight': 'bold' };
        }

        if (redCondOptC) {
          return { color: 'red', 'font-weight': 'bold' };
        }
      }
      return null;
    }
    return null;
  }
  getColorByConditionSportRate(curentValue: number, compareValue: number) {
    if (curentValue > compareValue) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValue) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }
  getColorByConditionSpotRateEngines(curentValue: number, compareValueNormal: number, compareValueHigh: number) {
    if (curentValue >= compareValueNormal && curentValue < compareValueHigh) {
      return { color: 'gold', 'font-weight': 'bold' };
    }
    if (curentValue > compareValueHigh) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValueNormal) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }
  getColorByConditionAverageRate(curentValue: number, compareValueNormal: number, compareValueHigh: number) {
    if (curentValue >= compareValueNormal && curentValue < compareValueHigh) {
      return { color: 'gold', 'font-weight': 'bold' };
    }
    if (curentValue >= compareValueHigh) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValueNormal) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }
}
