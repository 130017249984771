import { Pipe, PipeTransform } from '@angular/core';
import { NavigationItem } from '@techops-ui/common/data-access/navigation';

@Pipe({
  name: 'isSelected',
  pure: true
})
export class IsSelectedPipe implements PipeTransform {
  transform(root: string, navItem: NavigationItem, path: string): any {
    const regexExact = new RegExp(path);
    if (path.indexOf(root) > -1 && root !== '') {
      if (navItem.SubNav) {
        navItem.Collapse = false;
      }
      if (regexExact.test(navItem.RouterLink) && root !== '') {
        return true;
      } else {
        return false;
      }
    } else {
      if (navItem.SubNav) {
        navItem.Collapse = true;
      }
      return false;
    }
  }
}
