import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpService {
  private httpOptions: any;
  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        TransactionID: this.guid()
      })
    };
  }

  get(url: string): Observable<any> {
    return this.http.get<any>(url, this.httpOptions).pipe(map(r => r));
  }

  post(url: string, body: any): Observable<any> {
    return this.http.post<any>(url, body, this.httpOptions).pipe(map(r => r));
  }

  put(url: string, body: any): Observable<any> {
    return this.http.put<any>(url, body, this.httpOptions).pipe(map(r => r));
  }

  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  private guid(): string {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }
}
