import { Component, OnDestroy, OnInit } from '@angular/core';
import { Employee } from '@techops-ui/common/techops-header';
import { Store, select } from '@ngrx/store';
import { LoadNavigation } from '@techops-ui/common/data-access/navigation';
import { filter, map } from 'rxjs/operators';
import { Observable, combineLatest, } from 'rxjs';
import { Subscription } from 'node_modules/rxjs';
import { AppNavigation } from '../navigation';
import { AuthService, PermissionOSP, UserRoles } from '@techops-ui/osp/data-access-root';
import { PingAuthenticationService, User } from '@techops-ui/ping-authentication';

@Component({
  selector: 'techops-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  navigation = AppNavigation;
  identity$: Observable<User>;
  firstName$: Observable<string>;
  isLoggedIn$: Observable<boolean>;
  logoutURL$: Observable<string>;
  employee$: Observable<Employee>;
  navigationSubscription: Subscription;
  roleAccess: PermissionOSP;
  constructor(private authService: PingAuthenticationService,private authServices: AuthService, private store: Store<any>) {
    this.employee$ = this.authService.profile$.pipe(
      map(
        data =>
          <Employee>{
            FirstName: data.first_name,
            LastName: data.last_name,
            Station: data['location'],
            EmployeeID: data.uid
          }
      )
    );
   
    //this.logoutURL$ = of('logout');
   
    this.logoutURL$ = combineLatest([this.authService.profile$, this.authService.identity$]).pipe(
      map(([profile, identity]) => {
       return `${profile ?  profile.iss :''}/idp/startSLO.ping?id_token_hint=${identity ? identity.id_token :''}&TargetResource=${encodeURIComponent(window.location.origin)}`;
         }) 
     );
  }
  ngOnInit() {
    this.isLoggedIn$ = this.authService.loggedIn$;
    this.identity$ = this.authService.identity$;
    this.firstName$ = this.identity$.pipe(
      filter(u => u && !!u.profile),
      map((u: User) => (u.profile.given_name || u.profile.uid) as string)
    );

    this.authServices.userDetails$.subscribe(x =>{
      if(x){
      // this.roleAccess = this.authServices.checkRoleAccess();
      // this.checkAdminPemissions(this.roleAccess);
    
       this.store.dispatch(new LoadNavigation(this.navigation));
       this.navigationSubscription = this.store.pipe(select('navigation')).subscribe(data => (this.navigation = data));
      }else{
        
      // this.roleAccess = this.authServices.checkRoleAccess();
       //this.checkAdminPemissions(this.roleAccess);
      
        this.store.dispatch(new LoadNavigation(this.navigation));
        this.navigationSubscription = this.store.pipe(select('navigation')).subscribe(data => (this.navigation = data));
      }
    });

    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      
     if(data){
      if(data.amrapposp && data.amrapposp.includes(UserRoles.POS_ADMIN)){
        this.checkAdminPemissions(true);
      }
     
     }
    });
  
  }
 
  checkAdminPemissions(isPermision:boolean) {
    const arrayData = this.navigation.NavItems.find(element => element.Text === 'Admin');
 const subArray = arrayData.SubNav[0].Items.find(element => element.Text === 'POS');
 subArray.Visible = isPermision;
    return;
  }
  
  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }
}
