import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NxModule } from '@nrwl/nx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OspFeatureShellModule } from '@techops-ui/osp/feature-shell';
import { CommonDataAccessTfpGatewayModule } from '@techops-ui/common/data-access/tfp-gateway';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { PingAuthenticationModule } from '@techops-ui/ping-authentication';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './http.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    PingAuthenticationModule.forRoot({
      load_from_url: '/assets/app_config.json',
      post_logout_redirect_uri: `${window.location.origin}`
      // idle_warning_dialog_component: CustomSessionTimeoutWarningDialogComponent,
    }),
    OspFeatureShellModule,
    NxModule.forRoot(),
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    CommonDataAccessTfpGatewayModule,
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
