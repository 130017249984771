// tslint:disable-line
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalDirective } from 'ngx-bootstrap';
import { Store, select } from '@ngrx/store';
import * as LtermActions from '@techops-ui/common/data-access-lterm';
import { LtermState } from '@techops-ui/common/data-access-lterm';
import { UserLterms } from '@techops-ui/common/data-access-lterm';
import * as ltermConstants from '../../constants/feature-lterm-constants';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { BackendService } from '@techops-ui/common/data-access-lterm';

@Component({
  selector: 'techops-ui-lterm',
  templateUrl: './lterm.component.html',
  styleUrls: ['./lterm.component.css']
})
export class LtermComponent implements OnInit {
  @ViewChild('ltermModal')
  public ltermModal: ModalDirective;
  isLoading: Observable<boolean>;
  public ltermForm: FormGroup;
  station: string;
  printers: LtermState;
  defaultStation: string;
  userid: string;
  isModalShown: boolean;
  successMessage: string;
  failMessage: string;
  chageStationTxtShow: boolean;
  isPrinterSearch: boolean;
  stationsList: string[] = [];
  isRestrcted = false;
  @Output()
  LtermActionType = new EventEmitter();
  @Input()
  initalPopupOnLoad: boolean;
  @Input()
  restrictJobPositions: string[];
  @Input()
  employee: string;
  UPPER = '';
  @Input()
  enableCloseIcon = false;
  UserLterms$ = this._store.pipe(select(UserLterms)).subscribe((ltermState: any) => {
    if (ltermState) {
      this.printers = ltermState;
      this.isLoading = ltermState.isLoading;
      if (ltermState.lterms.length && this.restrictJobPositions != null) {
        // tslint:disable-line
        this.restrictJobPositions.map(job => {
          if (ltermState.lterms[0].jobPosition.includes(job)) {
            this.isRestrcted = true;
          }
        });
      }
    }
  });

  constructor(private _cookieService: CookieService, private _store: Store<LtermState>, private fb: FormBuilder, private backEndService: BackendService) {}

  ngOnInit() {
    this.ltermForm = this.toFormGroup();
    this.isModalShown = true;
    this.isPrinterSearch = false;
    this.successMessage = '';
    this.failMessage = '';
    this.chageStationTxtShow = false;
    this.userid = this._cookieService.get('SM_USER');
    if (this.userid.length > 6 && this.userid.startsWith('00')) {
      this.userid = this.userid.substr(2, 6);
    }
    if (this.employee && this.employee.length > 0) {
      if (this.employee.length > 6 && this.employee.startsWith('00')) {
        this.userid = this.employee.substr(2, 6);
      } else {
        this.userid = this.employee;
      }
    }
    this.printers.lterms = this.printers.Savedlterms;
    if (this.printers.loadState === ltermConstants.INITIAL_STATE) {
      this.loadLtermsbyuserid(this.userid);
    } else {
      this.ltermForm.controls['defaultPrinter'].setValue(this.printers.defaultPrinter);
    }
  }

  toFormGroup(): FormGroup {
    const formGroup = this.fb.group({
      changeStation: new FormControl('', [Validators.required, this.validateStation.bind(this)]),
      defaultPrinter: new FormControl('', Validators.required)
    });
    return formGroup;
  }
  public saveLterm(SelectStationControl: string) {
    try {
      if (this.ltermForm.controls['changeStation'].value === '') {
        this.ltermForm.controls['changeStation'].setValue(this.printers.defaultStation);
      }
      this.loadLterms(this.userid, this.ltermForm.controls['changeStation'].value.toUpperCase());
      this._store.dispatch(
        new LtermActions.SaveUserLterms({
          defaultStation: this.ltermForm.controls['changeStation'].value.toUpperCase(),
          DefaultPrinter: this.ltermForm.controls['defaultPrinter'].value,
          Lterms: this.printers.lterms
        })
      );
      this.isModalShown = false;
      this.station = this.ltermForm.controls['changeStation'].value.toUpperCase();
      this.successMessage = ltermConstants.LTERM_SAVED;
      this.ltermForm.controls['changeStation'].setValue('');
      let selectDefaultStation: any;
      selectDefaultStation = document.getElementById(SelectStationControl);
      selectDefaultStation.checked = true;
      this.ltermForm.controls['changeStation'].markAsUntouched();
      this.chageStationTxtShow = false;
    } catch (Exception) {
      this.failMessage = ltermConstants.LTERM_ERROR;
      console.log(Exception);
    }
  }

  public loadLtermsbyuserid(userid: string) {
    try {
      this._store.dispatch(new LtermActions.LoadUserLtermsByUserId({ userid: userid }));
    } catch (Exception) {
      console.log(Exception);
    }
  }
  public loadLterms(userid: string, station: string) {
    try {
      this._store.dispatch(new LtermActions.LoadUserLterms({ userid: userid, station: station }));
    } catch (Exception) {
      console.log(Exception);
    }
  }

  public onStationChange() {
    try {
      this.ltermForm.controls['defaultPrinter'].markAsUntouched();
      this.ltermForm.controls['changeStation'].markAsUntouched();
      this.successMessage = '';
      this.failMessage = '';
      this.ltermForm.controls['changeStation'].setValue('');
      this.ltermForm.controls['defaultPrinter'].setValue(this.printers.defaultPrinter);
      this.chageStationTxtShow = false;
      this.loadLterms(this.userid, this.printers.defaultStation);
    } catch (Exception) {
      console.log(Exception);
    }
  }

  public onDefaultStationChangeSearch() {
    try {
      this.ltermForm.controls['defaultPrinter'].markAsUntouched();
      this.ltermForm.controls['changeStation'].markAsUntouched();
      this.successMessage = '';
      this.failMessage = '';
      this.ltermForm.controls['defaultPrinter'].setValue('');
      this.loadLterms(this.userid, this.ltermForm.controls['changeStation'].value.toUpperCase());
      this.isPrinterSearch = true;
    } catch (Exception) {
      console.log(Exception);
    }
  }

  public chooseAnotherStation() {
    try {
      this.backEndService.getStationsData().subscribe(response => {
        for (let i = 0; i < response.length; i++) {
          this.stationsList[i] = response[i].stationName;
        }
      });
      this.isPrinterSearch = false;
      this.successMessage = '';
      this.failMessage = '';
      this.ltermForm.controls['changeStation'].setValue('');
      this.ltermForm.controls['defaultPrinter'].setValue('');
      this.printers.lterms = [];
      this.chageStationTxtShow = true;
    } catch (Exception) {
      console.log(Exception);
    }
  }

  public hideModal() {
    this.ltermModal.hide();
    this.isModalShown = false;
  }
  public ShowModal() {
    this.ltermModal.show();
  }
  public clearPrintersList() {
    this.printers.lterms = [];
  }

  public UpdateLtermOutput(status: string) {
    this.LtermActionType.emit(status);
  }

  public clearUnsavedSelection(SelectStationControl: string) {
    this.printers.lterms = this.printers.Savedlterms;
    this.ltermForm.controls['defaultPrinter'].setValue(this.printers.defaultPrinter);
    this.ltermForm.controls['changeStation'].setValue('');
    let selectDefaultStation: any;
    selectDefaultStation = document.getElementById(SelectStationControl);
    selectDefaultStation.checked = true;
    this.ltermForm.controls['changeStation'].markAsUntouched();
    this.chageStationTxtShow = false;
  }
  saveDisabled() {
    if (this.chageStationTxtShow) {
      if (this.printers.lterms.length > 0 && this.ltermForm && this.ltermForm.controls['defaultPrinter'].valid && this.ltermForm.controls['changeStation'].valid) {
        return false;
      } else {
        return true;
      }
    } else if (this.ltermForm && this.ltermForm.controls['defaultPrinter'].valid) {
      return false;
    } else {
      return true;
    }
  }

  searchDisabled() {
    if (this.ltermForm && this.ltermForm.controls['changeStation'].valid) {
      return false;
    } else {
      return true;
    }
  }

  // Validator for changeStation FormControl
  validateStation(control: FormControl) {
    if (control.value && control.value.length > 0) {
      const stationKeyCount = this.stationsList.filter(stc => stc.startsWith(control.value.toUpperCase())).length;
      return stationKeyCount === -1 || stationKeyCount === 0 || stationKeyCount === 4 ? { invalidStation: true } : null;
    }
    return null;
  }
}
