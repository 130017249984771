import { OSPAppEnvironment } from '@techops-ui/osp/shared';
import { GatewayEnvironmentProd } from '@techops-ui/common/data-access/gateway';
import { TFPGatewayEnvironmentProd } from '@techops-ui/common/data-access/tfp-gateway';

export const OSPEnvironmentProd: OSPAppEnvironment = {
  production: true,
 // ospeventsApi: 'https://public.esoa.aa.com/api/ospapi/v1',
  //ospeventsApi: 'https://osp-api.maverick.aa.com/api',
  ospeventsApi: 'https://osp-api.maverick.aa.com/teq/ospapi/api',
  //Gateway
  ...GatewayEnvironmentProd,
  // feature flags
  // if needed add dependency here

  //TFP
  tfpgatewayApi: TFPGatewayEnvironmentProd.tfpgatewayApi + 'graphql',
  tfpAppName: 'osp',
  tfpVersion: '1.0',
  username: '',
  password: ''
};
