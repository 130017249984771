import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as smActions from './siteminder-authentication.actions';
import { initialState } from './siteminder-authentication.init';
import { SiteminderUser } from './siteminder-authentication.interfaces';

export type SiteminderUserState = SiteminderUser;

export function reducer(
  state: SiteminderUserState = initialState,
  action: smActions.SiteminderAuthenticationActions
): SiteminderUserState {
  switch (action.type) {
    case smActions.SiteminderAuthenticationActionTypes.SESSION_RENEW: {
      const act = action as smActions.SessionRenewAction;
      return { ...act.payload, ...{ sm_is_idle: false, sm_seconds_to_timeout: -1 } };
    }
    case smActions.SiteminderAuthenticationActionTypes.SESSION_RENEW_COMPLETE: {
      return { ...state, ...{ sm_is_idle: false, sm_seconds_to_timeout: -1 } };
    }
    case smActions.SiteminderAuthenticationActionTypes.SESSION_INITIALIZE_COMPLETE: {
      const act = action as smActions.SessionInitializeCompleteAction;
      return { ...act.payload, ...{ sm_is_idle: false, sm_seconds_to_timeout: -1 } };
    }
    case smActions.SiteminderAuthenticationActionTypes.IDLE_START:
      return { ...state, ...{ sm_is_idle: true } };
    case smActions.SiteminderAuthenticationActionTypes.IDLE_END:
      return { ...state, ...{ sm_is_idle: false } };
    case smActions.SiteminderAuthenticationActionTypes.TIMEOUT_WARNING: {
      const act = action as smActions.TimeoutWarningAction;
      return { ...state, ...{ sm_is_idle: true, sm_seconds_to_timeout: act.payload } };
    }
    case smActions.SiteminderAuthenticationActionTypes.TIME_OUT: {
      return { ...state, ...{ sm_is_idle: true, sm_seconds_to_timeout: 0, sm_timed_out: true } };
    }
    default: {
      return state;
    }
  }
}
export interface SiteminderAuthenticationState {
  user: SiteminderUserState;
}

//Selector Functions
export const SITEMINDER_AUTHENTICATION_FEATURE = 'siteminderAuthenticationFeature';

export const getSiteminderAuthenticationState: MemoizedSelector<
  any,
  SiteminderAuthenticationState
> = createFeatureSelector<SiteminderAuthenticationState>(SITEMINDER_AUTHENTICATION_FEATURE);

export const getUser = createSelector(
  getSiteminderAuthenticationState,
  state => state.user
);

// export const getUserLogInUrl = createSelector(getUser, (state: SiteminderUser) => state.sm_login_url);
// export const getUserLogOutUrl = createSelector(getUser, (state: SiteminderUser) => state.sm_logout_url);
// export const getUserSessionExpires = createSelector(getUser, (state: SiteminderUser) => state.sm_session_expires);
// export const getFirstName = createSelector(getUser, (state: SiteminderUser) => state.firstname);
// export const getLastName = createSelector(getUser, (state: SiteminderUser) => state.lastname);
// export const getBadgeNo = createSelector(getUser, (state: SiteminderUser) => state.sm_user);
// export const getEMail = createSelector(getUser, (state: SiteminderUser) => state.email);
export const getIsUserIdle = createSelector(
  getUser,
  (state: SiteminderUser) => state.sm_is_idle
);
export const getSecondsToTimeOut = createSelector(
  getUser,
  (state: SiteminderUser) => state.sm_seconds_to_timeout
);
export const getAccessToken = createSelector(
  getUser,
  (state: SiteminderUser) => state.sm_access_token
);
export const getSMSession = createSelector(
  getUser,
  (state: SiteminderUser) => state.sm_session
);
export const getIsUserInitialized = createSelector(
  getUser,
  (state: SiteminderUser) => !!state.sm_access_token
);
export const getIsUserTimedOut = createSelector(
  getUser,
  (state: SiteminderUser) => state.sm_timed_out
);
export const getRedirectUrl = createSelector(
  getUser,
  (state: SiteminderUser) => state.sm_redirect_url
);
export const getAttributeValue = (key: string) =>
  createSelector(
    getUser,
    (user: SiteminderUser) => user[key]
  );
export const getHasAnyRole = (roles: string[]) =>
  createSelector(
    getUser,
    (user: SiteminderUser) => user.sm_roles.some((role: string) => roles.indexOf(role) > -1)
  );
export const getHasRole = (role: string) =>
  createSelector(
    getUser,
    (user: SiteminderUser) => user.sm_roles.indexOf(role) > -1
  );
