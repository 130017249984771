import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthService) {}
  includes(roles: any, role: any) {
    let returnValue = false;
    const pos = roles.indexOf(role);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }
  canActivate(route: ActivatedRouteSnapshot) {
    const currentUser = this.authenticationService.getUserRoles();
    const isUserLogin = this.authenticationService.user.SM_USER && this.authenticationService.user.SM_USER !== "" ? true : false; 
    if (isUserLogin && currentUser) {
      const intersection = currentUser.filter((element: any) => this.includes(route.data.roles, element));
      if (route.data.roles && intersection.length === 0) {
        this.router.navigate(['/unauthorization']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
    
  }
}
