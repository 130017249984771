import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'aa-loading-fly',
  templateUrl: './loading-fly.component.html',
  styleUrls: ['./loading-fly.component.css']
})
export class LoadingFlyComponent implements OnInit {
  @Input()
  loaderText: string;
  @Input()
  zIndex: number;
  @Input()
  imgSize = 120;

  constructor() {}

  ngOnInit() {
    //If the 'loaderText' input is not set from Parent Component, the text is set to 'Loading...' as default.
    this.loaderText = (this.loaderText && this.loaderText.trim()) || 'Loading...';
  }
}
