import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { ApolloQueryResult } from 'apollo-client';

@Injectable({
  providedIn: 'root'
})
export class CommonTFPGatewayService {
  constructor(private apollo: Apollo) {}

  query<T>(query: any, variables?: any): Observable<ApolloQueryResult<T>> {
    return this.apollo.use('TFPGateway').watchQuery({
      query: query,
      variables: variables
    }).valueChanges as Observable<ApolloQueryResult<T>>;
  }
  mutation<T>(mutation: any, variables?: any, refetchQueries?: any): Observable<FetchResult<T>> {
    return this.apollo.use('TFPGateway').mutate({
      mutation: mutation,
      variables: variables,
      refetchQueries: refetchQueries
    });
  }
}
