import { NgModule } from '@angular/core';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
/* ::: kendo modules*/
import { ButtonsModule, ButtonGroupModule } from '@progress/kendo-angular-buttons';
import { CalendarModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  imports: [],
  declarations: [],
  exports: [
    ButtonsModule,
    ButtonGroupModule,
    DateInputsModule,
    DialogsModule,
    DropDownsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    InputsModule,
    LayoutModule,
    MenuModule,
    PopupModule,
    RippleModule,
    ScrollViewModule,
    SortableModule,
    TreeViewModule,
    TooltipModule,
    CalendarModule,
    CodeHighlighterModule
  ]
})
export class OspFeatureKendoMaterialModule {}
