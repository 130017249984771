import { Injectable } from '@angular/core';

@Injectable()
export class PrintService {
  constructor() {}

  print(printEl: HTMLElement) {
    let printContainer: HTMLElement = <HTMLElement>document.querySelector('#PrintContainer');

    if (!printContainer) {
      printContainer = document.createElement('div');
      printContainer.id = 'PrintContainer';
    }

    printContainer.innerHTML = '';
    printContainer.outerHTML = '';
    const old = document.querySelector('#PrintContainer');
    if (old) {
      old.innerHTML = '';
      old.outerHTML = '';
    }
    const elementCopy = printEl.cloneNode(true);
    printContainer.appendChild(elementCopy);
    document.body.appendChild(printContainer);

    window.print();
  }
}
