import { Injectable } from '@angular/core';
import { HttpService } from '@techops-ui/common/helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthDotNetService {
  constructor(private httpService: HttpService) {}

  getAuth(): Observable<any> {
    return this.httpService.get('.auth/me');
  }
}
