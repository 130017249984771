import { Component, Input } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { Navigation } from '@techops-ui/common/data-access/navigation';

@Component({
  selector: 'aa-apps-popup',
  templateUrl: './apps-popup.component.html',
  styleUrls: ['./apps-popup.component.css']
})
export class AppsPopupComponent {
  @Input()
  show: boolean;
  @Input()
  anchor: string;
  @Input()
  items: Navigation;

  anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  margin = { horizontal: -15, vertical: 20 };
  duration = 300;
  type = 'fade';
  direction = 'down';
  apps: Array<string> = ['Safe', 'Engineering', 'Receiving', 'Stores', 'Ordering'];

  get animate(): any {
    return {
      type: this.type,
      direction: this.direction,
      duration: this.duration
    };
  }
}
