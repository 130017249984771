import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { TechopsHeaderModule } from '@techops-ui/common/techops-header';
import { NxModule } from '@nrwl/nx';
import { RouterModule } from '@angular/router';
import { OspFeatureOspModule } from '@techops-ui/osp/feature-osp';
import { OspDataAccessRootModule } from '@techops-ui/osp/data-access-root';

@NgModule({
  imports: [CommonModule, TechopsHeaderModule, OspFeatureOspModule, OspDataAccessRootModule, NxModule.forRoot(), RouterModule.forRoot([])],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: []
})
export class OspFeatureShellModule {}
