import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BackendService, IAircraftReport, IFleet,AuthService ,PermissionOSP,RolePermission  } from '@techops-ui/osp/data-access-root';
import { process, State, SortDescriptor } from '@progress/kendo-data-query';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'techops-ui-aircraft-main',
  templateUrl: './aircraft-main.component.html',
  styleUrls: ['./aircraft-main.component.css']
})
export class AircraftMainComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  editAircraftRecordModel = false;
  editAircraftRecordForm: FormGroup;
  formGroup: FormGroup;
  public filterOpened = false;
  public gridView: GridDataResult;
  public pageSize = 15;
  public skip = 0;
  public isFormEdit = false;
  public carrierList: Array<string> = ['AA', 'US'];
  submitted: boolean;
  public selectedFleet = '';
  addAircraftForm: FormGroup;
  isLoading = false;
  // @Input() editData: any;
  editData: any;
  public dataSourceList: Array<string> = ['FOS', 'SCEPTRE'];
  public statusList: Array<string> = ['Active', 'Inactive'];
  public fleetList: GridDataResult;
  fleetData: IFleet[];
  gridData: IAircraftReport[];
  fleetlist: Array<{ fleet: string; carrier: string; ospUserFleetMapping: any; id: number }> = [];
  @Output() createEvent = new EventEmitter<boolean>();

  public state: State = {
    skip: 0,
    take: 15,
    sort: [
      {
        field: 'acid',
        dir: 'asc'
      }
    ],
    filter: {
      logic: 'and',
      filters: [{ field: 'carrier', operator: 'contains', value: '' }]
    }
  };

  groupHeaderLeftStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  groupHeaderRightStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-right': '1px solid #5f5353',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  subHeaderBorderBottom = {
    'border-bottom': '1px solid #6e8999'
  };
  headerBorderLeft = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  headerBorderRight = {
    'background-color': 'rgb(228, 231, 235)',
    'border-right': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  defaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#e4e7eb',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  firstCellBorder = {
    'border-top': '1px solid red'
  };
  roleAccess: PermissionOSP = RolePermission.Default;
  constructor(private backendservice: BackendService, private formBuilder: FormBuilder, private authServices:AuthService) {}

  ngOnInit() {
    this.roleAccess = this.authServices.checkRoleAccess();
    this.initLoadReport();
    this.getFleet();
    this.addAircraftForm = this.formBuilder.group({
      aircraft: ['', [Validators.required, Validators.maxLength(3)]],
      engineType: ['', [Validators.required, Validators.maxLength(20)]],
      dataSource: ['', [Validators.required]],
      fleet: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });
  }

  public pageChange(event: PageChangeEvent): void {
    if (this.state.skip !== event.skip) {
      this.state = { ...this.state, skip: event.skip };
      this.gridView = process(this.gridData, this.state);
    }
  }

  public sortChange(event: Array<SortDescriptor>): void {
    this.state = { ...this.state, sort: event, skip: 0 };
    const temp = process(this.gridData, this.state);
    this.gridView = temp;
  }

  private initLoadReport(): void {
    this.isLoading = true;
    this.backendservice.getAircraftList().subscribe(
      (result: IAircraftReport[]) => {
        this.gridData = result.map((mapresult: IAircraftReport) => {
          return { ...mapresult };
        });
        this.gridView = {
          data: this.gridData.slice(this.skip, this.skip + this.pageSize),
          total: this.gridData.length
        };
        this.isLoading = false;
      },
      (error: any) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }
  private initAddEditForm() {
    this.editAircraftRecordForm = this.formBuilder.group({
      // acid: ['', Validators.required],
      ac: ['', [Validators.required, Validators.maxLength(20)]],
      cc: ['', Validators.required],
      fleet: ['', Validators.required],
      status: ['', Validators.required],
      engineType: ['', [Validators.required, Validators.maxLength(20)]],
      dataSource: ['', Validators.required]
    });

    if (!this.isFormEdit) {
      this.updateFormValues();
      this.editAircraftRecordForm.controls['ac'].disable();
      this.editAircraftRecordForm.controls['engineType'].disable();
    } else {
      this.editAircraftRecordForm.reset();
      this.editAircraftRecordForm.controls['ac'].enable();
      this.editAircraftRecordForm.controls['engineType'].enable();
      this.submitted = false;
    }
    this.loadFleets();
  }

  private loadFleets() {
    this.backendservice.getFleetList().subscribe(
      (result: IFleet[]) => {
        this.fleetData = result.map((mapresult: IFleet) => {
          return { ...mapresult };
        });
        this.fleetList = {
          data: this.fleetData.slice(),
          total: this.gridData.length
        };
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  updateFormValues() {
    const aircraftEdit = this.editData;

    this.editAircraftRecordForm.patchValue({
      acid: aircraftEdit.acid,
      ac: aircraftEdit.ac,
      cc: aircraftEdit.cc,
      fleet: aircraftEdit.fleet,
      engineType: aircraftEdit.engineType,
      status: aircraftEdit.status,
      dataSource: aircraftEdit.dataSource
    });
    this.selectedFleet = aircraftEdit.fleet;
  }

  editAircraftRecord(editStatus: boolean, editData?: any) {
    this.editAircraftRecordModel = true;
    if (editStatus) {
      this.isFormEdit = true;
      this.editData = editData;
    } else {
      this.isFormEdit = false;
      this.editData = editData;
    }
    this.initAddEditForm();
  }
  closeAircraftRecord() {
    this.editAircraftRecordModel = false;
    this.initLoadReport();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    const temp = process(this.gridData, this.state);
    this.gridView = temp;
  }

  public onAccept() {
    this.submitted = true;
    if (this.editAircraftRecordForm.invalid) {
      return;
    }
    const formData = this.editAircraftRecordForm.value;
    if (!this.isFormEdit) {
      formData.acid = Number(this.editData.acid);
    }
    formData.ac = formData.ac;
    formData.cc = formData.cc;
    formData.fleet = formData.fleet;
    formData.engineType = formData.engineType;
    formData.status = formData.status;
    formData.dataSource = formData.dataSource;
    if (!this.isFormEdit) {
      this.backendservice.updateAircraftRecord(formData, this.editData.acid).subscribe(
        data => {
          if (data) {
            alert('Aircraft update successfully');
            this.createEvent.emit();
            this.editAircraftRecordModel = false;
            this.submitted = true;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      this.backendservice.postAircraftList(formData).subscribe(
        data => {
          if (data) {
            alert('Aircraft added successfully');
            this.createEvent.emit();
            this.editAircraftRecordModel = false;
            this.submitted = true;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
    this.initLoadReport();
  }
  editCreation() {
    this.editAircraftRecordModel = false;
    this.initLoadReport();
  }

  private getFleet(): void {
    this.backendservice.getFleetList().subscribe(
      (data: any) => {
        this.fleetlist = data;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
