import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewayDotNetTFPApolloModule } from './gateway-apollo-dotnet.module';
import { CommonTFPGatewayService } from './services/tfp-gateway.service';

@NgModule({
  imports: [CommonModule, GatewayDotNetTFPApolloModule.forRoot()],
  providers: [CommonTFPGatewayService]
})
export class CommonDataAccessDotNetTfpGatewayModule {}
