import { Navigation } from '@techops-ui/common/data-access/navigation';

export const AppNavigation: Navigation = {
  NavItems: [
    {
      Text: 'Home',
      Icon: 'home',
      Root: '',
      RouterLink: '/home',
      Path: '',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Threshold',
      Icon: 'home',
      Root: '',
      RouterLink: '/threshold-list',
      Path: '',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Oil Add',
      Icon: 'home',
      Root: '',
      RouterLink: '/oil-add',
      Path: '',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    
    {
      Text: 'Admin',
      Icon: '',
      Root: '',
      RouterLink: '',
      Path: '',
      Visible: true,
      Collapse: true,
      SubNav: [
        {
          GroupName: '',
          Items: [
            {
              Text: 'OSP Admin',
              Icon: 'home',
              Root: '',
              RouterLink: '/admin',
              Path: '',
              Visible: true
            },
            {
              Text: 'POS',
              Icon: 'home',
              Root: '',
              RouterLink: '/predictive-oil-add',
              
              Path: '',
              Visible: false
            },
            
          ]
        }
      ]
    },
    {
      Text: 'Reports',
      Icon: 'home',
      Root: '',
      RouterLink: '/reports',
      Path: '',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Aircraft',
      Icon: 'home',
      Root: '',
      RouterLink: '/aircraft-main',
      Path: '',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Feedback',
      Icon: 'home',
      Root: '',
      RouterLink: '/view-feedback',
      Path: '',
      Visible: true,
      Collapse: false,
      SubNav: []
    }, {
      Text: 'Deleted Oil Adds',
      Icon: 'home',
      Root: '',
      RouterLink: '/deleted-oil-add',
      Path: '',
      Visible: true,
      Collapse: false,
      SubNav: []
    },

    {
      Text: 'SAFE Oil Add',
      Icon: 'home',
      Root: '',
      RouterLink: '/oil-add-landingpage',
      Path: '',
      Visible: true,
      Collapse: false,
      SubNav: []
    },
    {
      Text: 'Notification',
      Icon: 'home',
      Root: '',
      RouterLink: '/notification',
      Path: '',
      Visible: true,
      Collapse: false,
      SubNav: []
    }
  ],
  HelpItems: [
    {
      Text: '1 (866)523-5333',
      Icon: 'aa-icon-phone',
      Root: '',
      RouterLink: '',
      Path: '',
      Collapse: true,
      Visible: true,
      SubNav: []
    },
    {
      Text: `Link to User Guide`,
      Icon: '',
      Root: '',
      RouterLink: '',
      Path: 'https://spteam.aa.com/sites/TEQ/MRP/SiteAssets/Forms/AllItems.aspx?FolderCTID=0x0120004547446AED34EE488498FE05EFF405F7&isAscending=true&id=%2Fsites%2FTEQ%2FMRP%2FSiteAssets%2Fdocs&sortField=Editor&viewid=cbac35be%2D5eeb%2D4c6d%2D9acb%2D31b4b9242ec8',
      Collapse: true,
      Visible: true,
      SubNav: []
    }
  ],
  AccountItems: [],
  Version: '2.0.0',
  Opened: false
};
