import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BackendService, INotification, IFleet,AuthService ,PermissionOSP,RolePermission  } from '@techops-ui/osp/data-access-root';
import { process, State, SortDescriptor } from '@progress/kendo-data-query';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { map } from 'rxjs/operators';

@Component({
  selector: 'techops-ui-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  employee: any ={uid:''};
  @ViewChild(GridComponent) grid: GridComponent;
  editAircraftRecordModel = false;
  editAircraftRecordForm: FormGroup;
  formGroup: FormGroup;
  public filterOpened = false;
  public gridView: GridDataResult;
  public pageSize = 15;
  public skip = 0;
  public isFormEdit = false;
  public carrierList: Array<string> = ['AA', 'US'];
  submitted: boolean;
  public selectedFleet = '';
  errorMessage: string;
  errordialogeopend = false;
  isLoading = false;
  // @Input() editData: any;
  editData: any;
  public dataSourceList: Array<string> = ['FOS', 'SCEPTRE'];
  public statusList: Array<string> = ['Active', 'Inactive'];
  public fleetList: GridDataResult;
  fleetData: IFleet[];
  gridData: INotification[];
  isError = false;
  fleetlist: Array<{ fleet: string; carrier: string; ospUserFleetMapping: any; id: number }> = [];
  @Output() createEvent = new EventEmitter<boolean>();

  public state: State = {
    skip: 0,
    take: 15,
    sort: [
     
    ],
    filter: {
      logic: 'and',
      filters: [{ field: 'decsFleet', operator: 'contains', value: '' }]
    }
  };

  groupHeaderLeftStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  groupHeaderRightStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-right': '1px solid #5f5353',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  subHeaderBorderBottom = {
    'border-bottom': '1px solid #6e8999'
  };
  headerBorderLeft = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  headerBorderRight = {
    'background-color': 'rgb(228, 231, 235)',
    'border-right': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  defaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#e4e7eb',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  firstCellBorder = {
    'border-top': '1px solid red'
  };
  roleAccess: PermissionOSP = RolePermission.Default;
  constructor(private backendservice: BackendService, private formBuilder: FormBuilder, private authServices:AuthService, private authService: PingAuthenticationService,) {}

  ngOnInit() {
    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      this.employee = data;
      
    });
   
    this.roleAccess = this.authServices.checkRoleAccess();
    this.initAddEditForm();
    this.initLoadReport();
    this.getFleet();
 
  }

  public pageChange(event: PageChangeEvent): void {
    if (this.state.skip !== event.skip) {
      this.state = { ...this.state, skip: event.skip };
      this.gridView = process(this.gridData, this.state);
    }
  }

  public sortChange(event: Array<SortDescriptor>): void {
    this.state = { ...this.state, sort: event, skip: 0 };
    const temp = process(this.gridData, this.state);
    this.gridView = temp;
  }

  private initLoadReport(): void {
    this.isLoading = true;
    this.backendservice.getNotificationList().subscribe(
      (result: INotification[]) => {
        this.gridData = result.map((mapresult: INotification) => {
          return { ...mapresult };
        });
        this.gridView = {
          data: this.gridData.slice(this.skip, this.skip + this.pageSize),
          total: this.gridData.length
        };
        this.isLoading = false;
      },
      (error: any) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }
  get emails() {
    return this.editAircraftRecordForm.controls["emails"] as FormArray;
  }

  emailForm(): FormGroup {
    return this.formBuilder.group({
      email: ['',[ Validators.required, Validators.email, Validators.maxLength(40)]],
    })
 }
 removeEmail(i:number) {
  this.emails.removeAt(i);
}
 addSkills() {
  this.emails.push(this.emailForm());
}
  private initAddEditForm() {
    this.editAircraftRecordForm = this.formBuilder.group({
      id : [''],
     
      emails:this.formBuilder.array([this.emailForm()]),
      decsFleet: ['', [Validators.required,Validators.maxLength(3)]],
     // emails: ['',[ Validators.required, Validators.email]],
      phone: ['', [Validators.required,Validators.pattern('^[0-9-/\\s]+$'), Validators.maxLength(50)]],
      employeeId: ['', [Validators.required, Validators.maxLength(6)]],
      carrier:['', [Validators.required, Validators.maxLength(2)]]
    });
 
   
    this.loadFleets();
  }

  private loadFleets() {
    this.backendservice.getFleetList().subscribe(
      (result: IFleet[]) => {
        this.fleetData = result.map((mapresult: IFleet) => {
          return { ...mapresult };
        });
        this.fleetList = {
          data: this.fleetData.slice(),
          total: this.fleetData.length
        };
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  updateFormValues() {
    const aircraftEdit = this.editData;

    this.editAircraftRecordForm.patchValue({
      id :aircraftEdit.id,
      decsFleet: aircraftEdit.decsFleet,
     // emails: aircraftEdit.emails,
      phone: aircraftEdit.phone,
      employeeId:aircraftEdit.employeeId,
      carrier:aircraftEdit.carrier
    });
    const updateEmailsCount = aircraftEdit.emails.split(";");
    
    const emailsPatch = updateEmailsCount.map((emails:string)=>{
      this.addSkills();
      return {email:emails};
    })
    
   const patchValues =  this.editAircraftRecordForm.controls["emails"] as FormArray;
   
   patchValues.patchValue(emailsPatch);
    this.editAircraftRecordForm.controls['decsFleet'].disable();
    this.editAircraftRecordForm.controls['employeeId'].disable();
    this.editAircraftRecordForm.controls['carrier'].disable();
    
    if (updateEmailsCount.length < patchValues.length) {
      for (let i = patchValues.length - 1; i > 0; i--) {
        const isEmpty = patchValues.value[i];
        if (isEmpty && (isEmpty.email === '' || !isEmpty.email)) {
          this.removeEmail(i);
        }
      }
    }
   
  }

  resetForm() {
    const emailsList = this.emails;
    for (let i = emailsList.controls.length - 1; i > 0; i--) {
      this.removeEmail(i);
    }
  }
  editAircraftRecord(editStatus: boolean, editData?: any) {
    this.editAircraftRecordForm.reset();
   
    this.submitted = false;
    this.editAircraftRecordModel = true;
    this.editAircraftRecordForm.controls['decsFleet'].enable();
    this.editAircraftRecordForm.controls['employeeId'].enable();
    this.editAircraftRecordForm.controls['carrier'].enable();
   
    if(this.emails.controls.length>1){
      this.resetForm();
    }
    if (editStatus) {
      this.isFormEdit = true;
      this.editData = editData;
      this.editAircraftRecordForm.patchValue({
        employeeId:isNaN(this.employee.uid) ? '' : Number(this.employee.uid),
      });
    } else {
      this.isFormEdit = false;
      this.editData = editData;
      this.updateFormValues();
    }
    
  }
  closeAircraftRecord() {
    this.editAircraftRecordModel = false;
    this.editAircraftRecordForm.reset();
    this.submitted = false;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    const temp = process(this.gridData, this.state);
    this.gridView = temp;
  }
  closeErrorDialog(){
    this.errordialogeopend = false;
   
  }
  public onAccept() {
    this.submitted = true;
    this.isError = false;
    if (this.editAircraftRecordForm.invalid) {
      return;
    }
    const formData = this.editAircraftRecordForm.getRawValue();
    formData.phone = formData.phone.trim();
    formData.carrier = formData.carrier.toUpperCase();
    formData.emails =  formData.emails.map((emails:any)=>emails.email).join(";");
    formData.employeeId = isNaN(this.employee.uid) ? '' : Number(this.employee.uid);

    if( formData.emails.length>200){
      alert("All Email Maximum lenght should be 200");
      return;
    }
    if (!this.isFormEdit) {
      this.backendservice.putNotification(formData).subscribe(
        data => {
          if (data) {
            //alert('Notification updated successfully');
            this.createEvent.emit();
            this.editAircraftRecordModel = false;
            this.submitted = false;
            this.initLoadReport();
            this.errorMessage = data;
   
       
        
            this.errordialogeopend = true;
            this.editAircraftRecordForm.reset();
            this.submitted = false;
          }
        },
        (error: any) => {
          console.log(error);
          this.errorMessage =error.error;
          this.errordialogeopend = true;
          this.isError = true;
        }
      );
    } else {
      delete formData.id;
      this.backendservice.postNotification(formData).subscribe(
        data => {
          if (data) {
           // alert('Notification added successfully');
            this.createEvent.emit();
            this.editAircraftRecordModel = false;
            this.submitted = true;
            this.initLoadReport();
            this.errorMessage =data;
            this.errordialogeopend = true;
            this.editAircraftRecordForm.reset();
            this.submitted = false;
          }
        },
        (error: any) => {
          console.log(error);
          this.errorMessage =error.error;
          this.errordialogeopend = true;
          this.isError = true;
        }
      );
    }
    
  }
  editCreation() {
    this.editAircraftRecordModel = false;
    this.initLoadReport();
  }

  private getFleet(): void {
    this.backendservice.getFleetList().subscribe(
      (data: any) => {
        this.fleetlist = data;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

}
