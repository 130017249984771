import gql from 'graphql-tag';
export const GET_ENGINE_INFO = gql`
  query GetEngineInfo($input: EngineRequestInput!) {
    engines(input: $input) {
      engines {
        componentStatus {
          partNumber
          serialNumber
          mfgSerialNumber
          partDescription
          positionCode
          tso {
            cycles
            hours
          }
          tsoAtInstall {
            cycles
            hours
          }
          tsr {
            cycles
            hours
          }
          totalTime {
            hours
            cycles
          }
        }
      }
    }
  }
`;
