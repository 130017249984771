import { Component, ViewChild, OnInit } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BackendService, IAdminReport,AuthService ,PermissionOSP,RolePermission } from '@techops-ui/osp/data-access-root';
import { process, State, SortDescriptor } from '@progress/kendo-data-query';
@Component({
  selector: 'techops-ui-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  editAdminRecordModel = false;
  public filterOpened = false;
  public gridView: GridDataResult;
  public pageSize = 15;
  public skip = 0;
  public isFormEdit = false;
  isLoading = false;
  editData: any;
  public carrierSelection: Array<string> = ['AA', 'US'];
  public stationClassification: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  gridData: IAdminReport[];
  roleAccess: PermissionOSP = RolePermission.Default;
  public state: State = {
    skip: 0,
    take: 15,
    sort: [
      {
        field: 'id',
        dir: 'asc'
      }
    ],
    filter: {
      logic: 'and',
      filters: [{ field: 'carrier', operator: 'contains', value: '' }]
    }
  };

  groupHeaderLeftStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  groupHeaderRightStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-right': '1px solid #5f5353',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  subHeaderBorderBottom = {
    'border-bottom': '1px solid #6e8999'
  };
  headerBorderLeft = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  headerBorderRight = {
    'background-color': 'rgb(228, 231, 235)',
    'border-right': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  defaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#e4e7eb',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  firstCellBorder = {
    'border-top': '1px solid red'
  };

  constructor(private backendservice: BackendService, private authServices:AuthService) {}

  ngOnInit() {
    this.roleAccess = this.authServices.checkRoleAccess();
    this.initLoadReport();
  }

  public pageChange(event: PageChangeEvent): void {
    if (this.state.skip !== event.skip) {
      this.state = { ...this.state, skip: event.skip };
      this.gridView = process(this.gridData, this.state);
    }
  }

  public sortChange(event: Array<SortDescriptor>): void {
    this.state = { ...this.state, sort: event, skip: 0 };
    const temp = process(this.gridData, this.state);
    this.gridView = temp;
  }

  private initLoadReport(): void {
    this.isLoading = true;
    this.backendservice.getAdminReport().subscribe(
      (result: IAdminReport[]) => {
        this.gridData = result.map((mapresult: IAdminReport) => {
          return { ...mapresult };
        });
        this.gridView = {
          data: this.gridData.slice(this.skip, this.skip + this.pageSize),
          total: this.gridData.length
        };
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
    // this.loadData();
  }
  editAdminRecord(editStatus: boolean, editData?: any) {
    this.editAdminRecordModel = true;
    if (editStatus) {
      this.isFormEdit = true;
      this.editData = editData;
    } else {
      this.isFormEdit = false;
      this.editData = editData;
    }
  }
  closeAdminRecord() {
    this.editAdminRecordModel = false;
  }

  public filterClose() {
    this.filterOpened = false;
  }
  public filterOpen() {
    this.filterOpened = true;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    const temp = process(this.gridData, this.state);
    this.gridView = temp;
  }
  editCreation() {
    this.editAdminRecordModel = false;
    this.initLoadReport();
  }
}
