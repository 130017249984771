// tslint:disable-line
import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import {
  LtermActionTypes,
  LoadUserLterms,
  LoadUserLtermsSuccess,
  LoadUserLtermsFail,
  LoadUserLtermsByUserId,
  LoadUserLtermsSuccessByUserId,
  LoadUserLtermsFailByUserId
} from './lterm.actions';
import { LtermState, LtermData } from './lterm.interfaces';
import { DataPersistence } from '@nrwl/nx';
import { BackendService } from '../services/backendservice';
import { map } from 'rxjs/operators';

@Injectable()
export class LtermEffects {
  dataLog: any;
  @Effect()
  LoadUserLterms$ = this.dataPersistence.fetch(LtermActionTypes.LoadUserLterms, {
    run: (action: LoadUserLterms) => {
      this.dataLog = this.backendService.getLtermData(action.payload.userid, action.payload.station).pipe(map((response: LtermData[]) => new LoadUserLtermsSuccess(response)));
      return this.dataLog;
    },

    onError: (action: LoadUserLterms, error) => {
      console.log(action); // TODO: find out how to avoid this line
      return new LoadUserLtermsFail(error);
    }
  });
  @Effect()
  LoadUserLtermsbyUserId$ = this.dataPersistence.fetch(LtermActionTypes.LoadUserLtermsByUserId, {
    run: (action: LoadUserLtermsByUserId) => {
      return this.backendService.getLtermDataByUserid(action.payload.userid).pipe(map((response: LtermData[]) => new LoadUserLtermsSuccessByUserId(response)));
    },

    onError: (action: LoadUserLtermsByUserId, error) => {
      console.log(action); // TODO: find out how to avoid this line
      return new LoadUserLtermsFailByUserId(error);
    }
  });
  constructor(private dataPersistence: DataPersistence<LtermState>, private backendService: BackendService) {}
}
