import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendService, IEngineDetails } from '@techops-ui/osp/data-access-root';
import { GET_AIRCRAFT_INFO } from '../queries/aircraft.query';
import { GET_ENGINE_INFO } from '../queries/engine.query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { DatePipe } from '@angular/common';

import { CommonTFPGatewayService } from '@techops-ui/common/data-access/tfp-gateway';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';


@Component({
  selector: 'techops-ui-safe-addoil',
  templateUrl: './safe-addoil.component.html',
  styleUrls: ['./safe-addoil.component.css'],
  providers: [DatePipe]
})
export class SafeAddoilComponent implements OnInit {
  showSubmitConfirm = false;
  aircraftDetails: any;
  oilAddForm: FormGroup;
  submitted = false;
  GQLEngineDetails: any;
  fleetName = '';
  finalOildAdd: any;
  isLoading = false;
  employee: any;
  paramACNumber: number;
  errordialogeopend = false;
  confirmerrordialogeopend = false;
  warningdialogeopend = false;
  stationwarningdialogeopend = false;
  showWarningMessage = true;
  stationshowWarningMessage = true;
  errormessage: string;
  servicedDetails = { isEng1ServicedStyle: {}, isEng2ServicedStyle: {}, isAPUServicedStyle: {} };
  defaultStyle = { 'background-color': '#659b65', color: 'white' };
  defaultServiced = 'Serviced';
  aircraftquery: any;
  ammApuUrl = '';
  ammEngUrl = '';
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private gatewayService: CommonTFPGatewayService,
    private route: ActivatedRoute,
    private backendservice: BackendService,
    private authService: PingAuthenticationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.oilAddForm = this.formBuilder.group({
      station: ['', [Validators.required, Validators.maxLength(3)]],
      eng1: [],
      eng1ServicedByID: [{value: '', disabled: true}],
      eng1PintsAdded: [{value: '', disabled: true}],
      eng2: [],
      eng2ServicedByID: [{value: '', disabled: true}],
      eng2PintsAdded: [{value: '', disabled: true}],
      apu: [],
      apuServicedByID: [{value: '', disabled: true}],
      apuPintsAdded: [{value: '', disabled: true}],
      apuHours: [{value: '', disabled: true}]
    });
    this.route.params.subscribe(params => {
      if (params['acNumber']) {
        this.paramACNumber = params['acNumber'];
        this.getFleetDetails(params['acNumber']);
        this.isLoading = true;
        this.getEngineInfo(params['acNumber']).subscribe(result => {
          if (result && result.engines.length) {
            this.GQLEngineDetails = result.engines;
           
           this.getAircraftDetailsAC(params['acNumber'],true);
          }
        },error=>{
          console.error(error); 
          this.getAircraftDetailsAC(params['acNumber'],false);

          this.isLoading = false;
        });
        
      }
    });
    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      this.employee = data;
      
    });

    const eng1 = this.oilAddForm.get('eng1');
    const eng1ServicedByID = this.oilAddForm.get('eng1ServicedByID');
    const eng1PintsAdded = this.oilAddForm.get('eng1PintsAdded');
    const eng2 = this.oilAddForm.get('eng2');
    const eng2ServicedByID = this.oilAddForm.get('eng2ServicedByID');
    const eng2PintsAdded = this.oilAddForm.get('eng2PintsAdded');
    const apu = this.oilAddForm.get('apu');
    const apuServicedByID = this.oilAddForm.get('apuServicedByID');
    const apuPintsAdded = this.oilAddForm.get('apuPintsAdded');
    const apuHours = this.oilAddForm.get('apuHours');
    eng1.valueChanges.subscribe(val => {
      if (val) {
        eng1PintsAdded.setValidators([Validators.required, Validators.pattern(/^\d*\.?\d+$/)]);
        eng1ServicedByID.markAsPristine();
        eng1ServicedByID.markAsUntouched();
        eng1PintsAdded.markAsUntouched();
        eng1PintsAdded.markAsPristine();
        eng1ServicedByID.setValidators([Validators.required]);
        eng1ServicedByID.setValue(this.employee ? Number(this.employee.uid): '');
        eng1ServicedByID.enable();
        eng1PintsAdded.enable();
      } else {
        eng1PintsAdded.setValidators([]);
        eng1ServicedByID.setValidators([]);
        eng1ServicedByID.setValue('');
        eng1PintsAdded.setValue('');
        eng1ServicedByID.disable();
        eng1PintsAdded.disable();
      }
      eng1PintsAdded.updateValueAndValidity();
      eng1ServicedByID.updateValueAndValidity();
    });
    eng2.valueChanges.subscribe(val => {
      if (val) {
        eng2PintsAdded.setValidators([Validators.required, Validators.pattern(/^\d*\.?\d+$/)]);
        eng2ServicedByID.setValidators([Validators.required]);
        eng2ServicedByID.setValue(this.employee ? Number(this.employee.uid):'');
        eng2PintsAdded.enable();
        eng2ServicedByID.enable();
        eng2PintsAdded.markAsUntouched();
        eng2ServicedByID.markAsUntouched();
        eng2PintsAdded.markAsPristine();
        eng2ServicedByID.markAsPristine();
      } else {
        eng2PintsAdded.setValidators([]);
        eng2ServicedByID.setValidators([]);
        eng2ServicedByID.setValue('');
        eng2PintsAdded.setValue('');
        eng2PintsAdded.disable();
        eng2ServicedByID.disable();
      }
      eng2PintsAdded.updateValueAndValidity();
      eng2ServicedByID.updateValueAndValidity();
    });
    apu.valueChanges.subscribe(val => {
      if (val) {
        apuPintsAdded.setValidators([Validators.required, Validators.pattern(/^\d*\.?\d+$/)]);
        apuServicedByID.setValidators([Validators.required]);
        apuHours.setValidators([Validators.required]);
        apuServicedByID.setValue(this.employee ? Number(this.employee.uid):'');
        apuPintsAdded.enable();
        apuHours.enable();
        apuServicedByID.enable();
        apuPintsAdded.markAsUntouched();
        apuServicedByID.markAsUntouched();
        apuHours.markAsUntouched();
        apuPintsAdded.markAsPristine();
        apuServicedByID.markAsPristine();
        apuHours.markAsPristine();
      } else {
        apuPintsAdded.setValidators([]);
        apuHours.setValidators([]);
        apuServicedByID.setValue('');
        apuPintsAdded.setValue('');
        apuPintsAdded.disable();
        apuHours.disable();
        apuServicedByID.disable();
      }
      apuServicedByID.updateValueAndValidity();
      apuPintsAdded.updateValueAndValidity();
      apuHours.updateValueAndValidity();
    });
  }

  get isEng1Checked(){
    return this.oilAddForm.get('eng1').value?'':'NOT CHECKED';
  }
  get isEng2Checked(){
    return this.oilAddForm.get('eng2').value?'':'NOT CHECKED';
  }
  get isAPUChecked(){
    return this.oilAddForm.get('apu').value?'':'NOT CHECKED';
  }
  private getAircraftDetailsAC(acNumber: string,isFromGQl:boolean): void {
    //this.isLoading = true;
    const partNumber = this.getURL();
    this.backendservice.getAircraftDetailsForURL(acNumber, 'US',partNumber,isFromGQl).subscribe(
      (result: IEngineDetails[]) => {
        
        const aircraftDetails = result;
        this.aircraftDetails = aircraftDetails.length ? aircraftDetails[0] : null;

        if (this.aircraftDetails) {
          this.ammApuUrl = this.aircraftDetails.ammApuUrl;
          this.ammEngUrl = this.aircraftDetails.ammEngUrl;
        }
        this.isLoading = false;
        this.oilAddForm.patchValue({ apuHours: this.aircraftDetails.apuHours });
        const servicedEng = this.aircraftDetails.servicedEngines || [];

        if (servicedEng.includes('1')) {
          this.oilAddForm.get('eng1').disable();
          this.oilAddForm.get('eng1ServicedByID').disable();
          this.oilAddForm.get('eng1PintsAdded').disable();
          this.servicedDetails.isEng1ServicedStyle = this.defaultStyle;
          this.oilAddForm.get('eng1ServicedByID').setValue(this.defaultServiced);
          this.oilAddForm.get('eng1PintsAdded').setValue(this.defaultServiced);
        }
        if (servicedEng.includes('2')) {
          this.oilAddForm.get('eng2').disable();
          this.oilAddForm.get('eng2ServicedByID').disable();
          this.oilAddForm.get('eng2PintsAdded').disable();
          this.oilAddForm.get('eng2ServicedByID').setValue(this.defaultServiced);
          this.oilAddForm.get('eng2PintsAdded').setValue(this.defaultServiced);
          this.servicedDetails.isEng2ServicedStyle = this.defaultStyle;
        }
        if (servicedEng.includes('A')) {
          this.oilAddForm.get('apu').disable();
          this.oilAddForm.get('apuServicedByID').disable();
          this.oilAddForm.get('apuPintsAdded').disable();
          this.oilAddForm.get('apuHours').disable();
          this.oilAddForm.get('apuServicedByID').setValue(this.defaultServiced);
          this.oilAddForm.get('apuPintsAdded').setValue(this.defaultServiced);
          this.oilAddForm.get('apuHours').setValue(this.defaultServiced);
          this.servicedDetails.isAPUServicedStyle = this.defaultStyle;
        }
      },
      (error: any) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }
  getFleetDetails(noseNumber: string) {
    this.getFleetInfo(noseNumber).subscribe(fleetDetails => {
      if (fleetDetails) {
        this.fleetName = fleetDetails.aircraftDetails.fleet.fleetName;
      }
    });
  }
  getFleetInfo(noseNumber: string): Observable<any> {
    this.aircraftquery = this.gatewayService.query<any>(GET_AIRCRAFT_INFO, {
      input: {
        airlineCode: 'US',
        noseNumber: noseNumber
      }
    });

    return (this.aircraftquery = this.aircraftquery.pipe(
      map((res: ApolloQueryResult<any>) => {
        return res.data.aircraft;
      }) /*  */
    ));
  }

  getEngineInfo(noseNumber: string) {
    const enginequery = this.gatewayService.query<any>(GET_ENGINE_INFO, {
      input: {
        noseNumber: noseNumber
      }
    });
    return enginequery.pipe(
      map((res: ApolloQueryResult<any>) => {
        return res.data.engines;
      })
    );
  }
  addThereshold(ospReport: any) {
    const viewOSPDetails: any = {
      currentStation: ospReport.station,
      flightNumber: ospReport.flight,
      apuHours: ospReport.apuHours,
      reportList: [],
      authorizationCode: ospReport.authorizationCode,
      phone: ospReport.phone,
      aircraft: this.paramACNumber
    };
    const reportList = [];

    const setAPUData = (value: string, type: string) => {
      return type === 'apu' ? 'NA' : value;
    };
    for (const property in ospReport) {
      if ((property === 'engine1' || property === 'engine2' || property === 'apu') && ospReport[property] !== null) {
        const reportData = ospReport[property];
        reportList.push({
          name: property,
          servicedByName: reportData && reportData.servicedByName !== null ? reportData.servicedByName : null,
          servicedByID: reportData && reportData.servicedById !== null ? reportData.servicedById : null,
          pintsAdded: reportData && reportData.pintsAdded !== null ? reportData.pintsAdded : null,
          spotNormal: reportData && reportData.spotNormal !== null ? reportData.spotNormal : null,
          spotHigh: reportData && reportData.spotHigh !== null ? reportData.spotHigh : null,
          spotRate: reportData && reportData.spotRate !== null ? reportData.spotRate : null,
          averageNormal: setAPUData(reportData.averageNormal, property),
          averageHigh: setAPUData(reportData.averageHigh, property),
          averageRate: setAPUData(reportData.averageRate, property),
          suddenIncreaseNormal: reportData && reportData.suddenIncreaseNormal !== null ? reportData.suddenIncreaseNormal : null,
          predictedOilAdd: setAPUData(reportData.predictedOilAdd, property),
          NDCL: reportData.ndcl,
          ACL: reportData.acl,
          suddenIncreaseOption: reportData.suddenIncreaseOption
        });
      }
    }
    viewOSPDetails['reportList'] = reportList;
    sessionStorage.setItem('viewOSPDetails', JSON.stringify(viewOSPDetails));
  }
  confirmSubmit() {
    this.isLoading = true;
    this.backendservice.createOildAddEvent(this.finalOildAdd).subscribe(
      (result: any) => {
        if (result.length) {
          this.isLoading = false;
          this.addThereshold(result[0]);
          this.oilAddForm.reset();
          this.submitted = false;
          this.showSubmitConfirm = false;
          this.router.navigate(['add-success-safe-oil']);
        }
      },
      (_error: any) => {
        this.isLoading = false;
        this.confirmerrordialogeopend = true;
        this.showSubmitConfirm = false;
        this.errormessage = 'There is an issue with the submission please contact MOC';
        // console.error(error);
      }
    );
  }
  openErrorDialog() {
    this.errordialogeopend = true;
  }
  closeErrorDialog() {
    this.errordialogeopend = false;
  }
  confirmCloseErrorDialog() {
    this.confirmerrordialogeopend = false;
  }
  redirectBack() {
    this.router.navigate(['oil-add-landingpage']);
  }

  openWarningDialog() {
    this.warningdialogeopend = true;
  }

  closeWarningDialog() {
    this.warningdialogeopend = false;
  }

  openStationMissMatchWarningDialog() {
    this.stationwarningdialogeopend = true;
  }

  closeStationMissMatchWarningDialog() {
    this.stationwarningdialogeopend = false;
  }
  confirmStationWarning() {
    this.closeStationMissMatchWarningDialog();
    this.stationshowWarningMessage = false;
    this.submitData();
  }
  confirmWarning() {
    this.closeWarningDialog();
    this.showWarningMessage = false;
    this.submitData();
  }

  keyPressAlph(event: any) {
    const input = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  getURL() {
    const GQLEngDetails = this.GQLEngineDetails;
    const eng1Details = GQLEngDetails && GQLEngDetails[0] && GQLEngDetails[0].componentStatus && GQLEngDetails[0].componentStatus[0] ? GQLEngDetails[0].componentStatus[0] : {};
    const eng2Details = GQLEngDetails && GQLEngDetails[1] && GQLEngDetails[1].componentStatus && GQLEngDetails[1].componentStatus[0] ? GQLEngDetails[1].componentStatus[0] : {};
    const apuDetails = GQLEngDetails && GQLEngDetails[2] && GQLEngDetails[2].componentStatus && GQLEngDetails[2].componentStatus[0] ? GQLEngDetails[2].componentStatus[0] : {};
    let urlPrepare = '';
    if (eng1Details && eng1Details.positionCode && (eng1Details.positionCode.includes('E1'))) {
      urlPrepare += `${eng1Details.partNumber},E;`;
    }
    if (urlPrepare === '' && eng2Details && eng2Details.positionCode && (eng2Details.positionCode.includes('E2'))) {
      urlPrepare += `${eng2Details.partNumber},E;`;
    }
    if (apuDetails && apuDetails.positionCode && apuDetails.positionCode.includes('APU')) {
      urlPrepare += `${apuDetails.partNumber},A`;
    }

    return urlPrepare;
    
  }

  submitData() {
    this.submitted = true;

    if (this.oilAddForm.invalid) {
      return;
    }
    if (!this.fleetName) {
      this.errormessage = `fleet details not available.`;
      this.openErrorDialog();
      return;
    }
    const formInput = this.oilAddForm.value;
    const aircraftDetails = this.aircraftDetails;
    const GQLEngDetails = this.GQLEngineDetails;
    const eng1Details = GQLEngDetails && GQLEngDetails[0] && GQLEngDetails[0].componentStatus && GQLEngDetails[0].componentStatus[0] ? GQLEngDetails[0].componentStatus[0] : {};
    const eng2Details = GQLEngDetails && GQLEngDetails[1] && GQLEngDetails[1].componentStatus && GQLEngDetails[1].componentStatus[0] ? GQLEngDetails[1].componentStatus[0] : {};
    const apuDetails = GQLEngDetails && GQLEngDetails[2] && GQLEngDetails[2].componentStatus && GQLEngDetails[2].componentStatus[0] ? GQLEngDetails[2].componentStatus[0] : {};
    this.finalOildAdd = {
      aircraft: aircraftDetails.number,
      carrier: aircraftDetails.carrier,
      station: formInput.station.toUpperCase(),
      flight: aircraftDetails.flight,
      createdBy: formInput.apuServicedByID,
      dateAddedLocal: this.datePipe.transform(new Date(), 'MM-dd-yyyy'),
      timeAddedLocal: this.datePipe.transform(new Date(), 'HH:mm'),
      dateAddedUTC: this.datePipe.transform(new Date(), 'MM-dd-yyyy'),
      totalShipTime: aircraftDetails.totalShipHours,
      apuHours: formInput.apuHours,
      isSafeApp: true,
      oldHourMeter: 0,
      newHourMeter: 0,
      sendMail: true,
      fleet: this.fleetName,
      reason: '',
      editedDateAddedLocal: '1900-01-01',
      hourMeterReplacedDate: '1900-01-01',
      engine1: formInput.eng1
        ? {
            pintsAdded: Number(formInput.eng1PintsAdded),
            servicedByID: formInput.eng1ServicedByID,
            servicedByName: `${this.employee.firstname} ${this.employee.last_name}`,
            serialNumber: eng1Details.mfgSerialNumber,
            cpn: eng1Details.partNumber
          }
        : null,
      engine2: formInput.eng2
        ? {
            pintsAdded: Number(formInput.eng2PintsAdded),
            servicedByID: formInput.eng2ServicedByID,
            servicedByName: `${this.employee.firstname} ${this.employee.last_name}`,
            serialNumber: eng2Details.mfgSerialNumber,
            cpn: eng2Details.partNumber
          }
        : null,
      apu: formInput.apu
        ? {
            pintsAdded: Number(formInput.apuPintsAdded),
            servicedByID: formInput.apuServicedByID,
            servicedByName: `${this.employee.firstname} ${this.employee.last_name}`,
            serialNumber: apuDetails.mfgSerialNumber,
            cpn: apuDetails.partNumber
          }
        : null
    };

    if (this.stationshowWarningMessage && formInput.station.toUpperCase() !== this.aircraftDetails.station) {
      this.errormessage = 'Station mismatch. Please contact MOC to do desktop osp oiladd and update acars location.';
      this.openStationMissMatchWarningDialog();
      return;
    }
    if (formInput.apu && this.showWarningMessage && Number(formInput.apuHours) < this.aircraftDetails.apuHours) {
      this.errormessage = 'Apu hours needs to be larger than previous oil Add unless there is APU meter change or APU change. Do you want to proceed';
      this.openWarningDialog();
      return;
    }
    if (Number(formInput.eng1PintsAdded) > 40) {
      this.errormessage = `You entered a value greater than 40 for Eng1 pints added.`;
      this.openErrorDialog();
      return;
    }
    if (Number(formInput.eng2PintsAdded) > 40) {
      this.errormessage = `You entered a value greater than 40 for Eng2 pints added.`;
      this.openErrorDialog();
      return;
    }
    if (Number(formInput.apuPintsAdded) > 40) {
      this.errormessage = `You entered a value greater than 40 for APU pints added.`;
      this.openErrorDialog();
      return;
    }

    if (!formInput.eng1 && !formInput.eng2 && !formInput.apu) {
      this.errormessage = 'You must select at least one engine.';
      this.openErrorDialog();
      return;
    }

    // if (formInput.apu && Number(formInput.apuHours) - this.aircraftDetails.apuHours >= 200) {
    //   this.errormessage =
    //     'APU hours is more than 200 hours greater than previously recorded. The APU may have been recently replaced.  Contact MOC to review APU serial number history.';
    //   this.openErrorDialog();
    //   return;
    // }
    this.showSubmitConfirm = true;
  }

  confirmClose() {
    this.showSubmitConfirm = false;
    this.showWarningMessage = true;
    this.stationshowWarningMessage = true;
  }
}
