import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonGatewayService } from './services/gateway.service';
import { GatewayApolloModule } from './gateway-apollo.module';

@NgModule({
  imports: [CommonModule, GatewayApolloModule.forRoot()],
  providers: [CommonGatewayService]
})
export class CommonDataAccessGatewayModule {}
