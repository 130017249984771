/*  */ import { Component, ViewChild, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BackendService, IOilAdd,AuthService ,PermissionOSP,RolePermission} from '@techops-ui/osp/data-access-root';
import { SortDescriptor } from '@progress/kendo-data-query';
import { process, State } from '@progress/kendo-data-query';
import { CommonTFPGatewayService } from '@techops-ui/common/data-access/tfp-gateway';
import { EngineComponentStatus, EngineDetails } from '../models/component-status';
import { Observable } from 'rxjs';
import { GET_ENGINE_INFO } from '../queries/engine.query';
import {  map } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Component({
  selector: 'techops-ui-oiladd',
  templateUrl: './oiladd.component.html',
  styleUrls: ['./oiladd.component.css']
})
export class OiladdComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  @ViewChildren(GridComponent) private grids: QueryList<GridComponent>;
  applyFilterForm: FormGroup;
  @Input() noseNumber: string;
  addTheresholdModal = false;
  isLoading = false;
  oilAddEditModel = false;
  viewTheresholdModal = false;
  viewSubTheresholdDetailsModal = false;
  viewSubTheresholdList: any = [];
  public filterOpened = false;
  public allowUnsort = false;
  public gridView: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  editData: any;
  ospData: any;
  rgnData: any;
  gqlData: any;
  showEngine: boolean;
  enginequery: any;
  showLoading: boolean;
  enginesList: EngineDetails[];
  safeospaircraft: any;
  isOverRide = false;
  overRideEngForm: FormGroup;
  showOverRideDetails = { eng1: false, eng2: false, apu: false };
  public state: State = {
   
    sort: [{ dir: 'asc', field: 'cpn' }]
  };
  public isFormCreate = false;

  gridData: IOilAdd[];
  errordialogeopend = false;
  overrideerrordialogeopend = false;
  errorMessage: string;
  public sort: SortDescriptor[] = [
    {
      field: 'cpn',
      dir: 'asc'
    }
  ];
  viewOSPDetails: any;

  hideSubGridHeader = {
    display: 'none'
  };
  groupHeaderLeftStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  groupHeaderRightStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-right': '1px solid #5f5353',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  addGroupHeaderRightStyle = {
    'background-color': 'rgb(159, 180, 192)',
    color: 'black',
    'line-height': '1em',

    'border-right': '1px solid rgb(182, 165, 165)',
    'text-align': 'center'
  };
  addGroupHeaderLeftStyle = {
    'background-color': 'rgb(159, 180, 192)',
    color: 'black',
    'line-height': '1em',
    'border-right': '1px solid rgb(134, 119, 119)',
    'border-left': '1px solid rgb(182, 165, 165)',
    'text-align': 'center'
  };
  subHeaderBorderBottom = {
    'border-bottom': '1px solid #6e8999'
  };
  headerBorderLeft = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  addHeaderBorderLeft = {
    'background-color': '#ffff',
    'border-left': '1px solid #b29f9f',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  addCurrentHeaderBorderLeft = {
    'background-color': '#ffff',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  headerBorderRight = {
    'background-color': 'rgb(228, 231, 235)',
    'border-right': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };

  addHeaderBorderRight = {
    'background-color': '#ffff',
    'border-right': '1px solid #b29f9f',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  defaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#e4e7eb',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  viewDefaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#0000',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  viewBorderLeft = {
    'border-left': '1px solid #b29f9f'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  viewBorderRight = {
    'border-right': '1px solid #b29f9f'
  };

  firstCellBorder = {
    'border-top': '1px solid red'
  };
  submitted = false;
  overRideSubmitted = false;
  employee: any;
  roleAccess: PermissionOSP = RolePermission.Default;
  constructor(
    private backendservice: BackendService,
    private formBuilder: FormBuilder,
    private gatewayService: CommonTFPGatewayService,
    private authService: PingAuthenticationService,
    private authServices:AuthService
  ) {}

  ngOnInit() {
    // this.getEngineInfo(this.noseNumber);
    this.roleAccess = this.authServices.checkRoleAccess();
   
    this.initLoadReport(null);
    this.applyFilterForm = this.formBuilder.group({
      from: [''],
      to: [''],
      fleets: ['', [Validators.maxLength(30)]],
      carrier: ['', [Validators.maxLength(30)]],
      aircraft: ['', [Validators.maxLength(3)]],
      station: ['', [Validators.maxLength(30)]],
      flight: ['', [Validators.maxLength(30)]],
      authorization: ['', [Validators.maxLength(30)]],
      nogo: [false],
      serialNumber: ['', [Validators.maxLength(30)]],
      visualOption: ['', [Validators.maxLength(30)]],
      engine: ['', [Validators.maxLength(30)]]
    });

    this.overRideEngForm = this.formBuilder.group({
      engine1Comments: [''],
      engine2Comments: [''],
      apuComments: ['']
    });
    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      this.employee = data;
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.gridData, this.state);
  }

  onCreatSuccess(eventData: any) {
    this.addTheresholdModal = false;
    
    if(this.isEmptyObject()){
      this.initLoadReport(eventData);
    }else{
      this.applyFilter();
    }
    
  }
  closeAddThereshold() {
    this.viewTheresholdModal = false;
  }

  viewOliAddDetails(ospReport: any) {
    this.viewSubTheresholdList = ospReport;
    this.viewSubTheresholdDetailsModal = true;
  }
  closeOliAddDetails() {
    this.viewSubTheresholdDetailsModal = false;
  }

  expandGrid() {
    setTimeout(() => {
      (this.viewOSPDetails.reportList || []).forEach((report: { comments: string }, index: number) => {
        if (report.comments) {
          this.grids.toArray()[1].expandRow(index);
        }
      });
    }, 0);
  }
  addThereshold(ospReport: any) {
    this.viewTheresholdModal = true;
    this.viewOSPDetails = ospReport;
    const reportList = [];
    this.isOverRide = false;
    const setAPUData = (value: string, type: string) => {
      return type === 'apu' ? 'NA' : value;
    };
    console.log(ospReport);
    for (const property in ospReport) {
      if ((property === 'engine1' || property === 'engine2' || property === 'apu') && ospReport[property] !== null) {
        const reportData = ospReport[property];
        reportList.push({
          name: property,
          servicedByName: reportData && reportData.servicedByName !== null ? reportData.servicedByName : null,
          servicedByID: reportData && reportData.servicedById !== null ? reportData.servicedById : null,
          pintsAdded: reportData && reportData.pintsAdded !== null ? reportData.pintsAdded : null,
          spotNormal: reportData && reportData.spotNormal !== null ? reportData.spotNormal : null,
          spotHigh: reportData && reportData.spotHigh !== null ? reportData.spotHigh : null,
          spotRate: reportData && reportData.spotRate !== null ? reportData.spotRate : null,
          averageNormal: setAPUData(reportData.averageNormal, property),
          averageHigh: setAPUData(reportData.averageHigh, property),
          averageRate: setAPUData(reportData.averageRate, property),
          suddenIncreaseNormal: reportData && reportData.suddenIncreaseNormal !== null ? reportData.suddenIncreaseNormal : null,
          predictedOilAdd: setAPUData(reportData.predictedOilAdd, property),
          ndcl: reportData.ndcl,
          acl: reportData.acl,
          suddenIncreaseOption: reportData.suddenIncreaseOption,
          comments: reportData.comments
        });
      }
    }

    this.viewOSPDetails['reportList'] = reportList;
    this.showOverRideByValue(reportList, ospReport.authorizationCode);
    this.expandGrid();
    // Running GQL service
    // this.noseNumber = this.viewOSPDetails.aircraft; // "3AC" // "702"
    // this.showEngine = true;
    // this.showLoading = true;
    // this.getEngineInfo(this.noseNumber).subscribe(x => {
    //   if (x) {
    //     this.enginesList = x;
    //     this.showLoading = false;
    //   }
    // });
    // this.getSafeAircraftDetails(this.noseNumber);
  }

  showOverRideByValue(reportList: any, authorizationCode: string) {
    const apuComments = this.overRideEngForm.get('apuComments');
    const engine2Comments = this.overRideEngForm.get('engine2Comments');
    const engine1Comments = this.overRideEngForm.get('engine1Comments');
    this.showOverRideDetails.apu = false;
    this.showOverRideDetails.eng1 = false;
    this.showOverRideDetails.eng2 = false;
    (reportList || []).forEach((engDetails: any) => {
      let engStyles;

      if (engDetails.name === 'apu') {
        engStyles = this.getColorByConditionPintsAdded(engDetails.pintsAdded, engDetails.suddenIncreaseNormal);
        if (engStyles && engStyles.color !== 'red') {
          engStyles = this.getColorByConditionSportRate(engDetails.spotRate, engDetails.spotNormal);
        }
      } else {
        engStyles = this.getColorByConditionPintsAddedEngines(engDetails);

        if (engStyles && engStyles.color !== 'red') {
          engStyles = this.getColorByConditionSpotRateEngines(engDetails.spotRate, engDetails.spotNormal, engDetails.spotHigh);
        }
      }

      if (engDetails.averageRate !== 'NA' && engStyles && engStyles.color !== 'red') {
        engStyles = this.getColorByConditionAverageRate(engDetails.averageRate, engDetails.averageNormal, engDetails.averageHigh);
      }
      if (engDetails.suddenIncreaseNormal && engStyles && engStyles.color !== 'red') {
        engStyles = this.getColorByConditionSuddenIncress(engDetails);
      }
     
      if (engDetails.name === 'apu' && engStyles && engStyles.color === 'red' && !engDetails.comments) {
        this.showOverRideDetails.apu = true;
      } else if (engDetails.name === 'engine1' && engStyles && engStyles.color === 'red' && !engDetails.comments) {
        this.showOverRideDetails.eng1 = true;
      } else if (engDetails.name === 'engine2' && engStyles && engStyles.color === 'red' && !engDetails.comments) {
        this.showOverRideDetails.eng2 = true;
      }

      if (!authorizationCode && engDetails.name === 'apu') {
        this.showOverRideDetails.apu = true;
      } else if (!authorizationCode && engDetails.name === 'engine1') {
        this.showOverRideDetails.eng1 = true;
      } else if (!authorizationCode && engDetails.name === 'engine2') {
        this.showOverRideDetails.eng2 = true;
      }
    });

    if (this.showOverRideDetails.apu) {
      apuComments.setValidators([]);
    } else {
      apuComments.setValidators([]);
    }
    apuComments.updateValueAndValidity();

    if (this.showOverRideDetails.eng1) {
      engine1Comments.setValidators([]);
    } else {
      engine1Comments.setValidators([]);
    }
    engine1Comments.updateValueAndValidity();

    if (this.showOverRideDetails.eng2) {
      engine2Comments.setValidators([]);
    } else {
      engine2Comments.setValidators([]);
    }
    engine2Comments.updateValueAndValidity();
  }

  getColorByConditionPintsAdded(curentValue: number, compareValue: number) {
    if (curentValue >= compareValue) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValue) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }

  getColorByConditionSpotRateEngines(curentValue: number, compareValueNormal: number, compareValueHigh: number) {
    if (curentValue >= compareValueNormal && curentValue < compareValueHigh) {
      return { color: 'gold', 'font-weight': 'bold' };
    }
    if (curentValue > compareValueHigh) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValueNormal) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }
  getColorByConditionPintsAddedEngines(data: any) {
    if (data !== null) {
      const suddenIncreaseOption = data.suddenIncreaseOption;
      const predictedOilAdd = data.predictedOilAdd;
      const NDCL = data.ndcl;
      const ACL = data.acl;
      const pintsAdded = data.pintsAdded;
      const suddenIncreaseNormal = data.suddenIncreaseNormal;
      const spotNormal = data.spotNormal;
      const spotRate = data.spotRate;
      const spotHigh = data.spotHigh;

      if (suddenIncreaseOption === 'Option A') {
        const predictedOilAddResult: number = predictedOilAdd * 1;
        const acl = ACL * 1 + predictedOilAddResult * 1;
        const ndcl = NDCL * 1 + predictedOilAddResult * 1;
        const greenCondOptA = pintsAdded < acl;

        if (greenCondOptA) {
          return { color: 'green', 'font-weight': 'bold' };
        }

        const yellowCondOptA = pintsAdded > acl && pintsAdded <= ndcl;

        if (yellowCondOptA) {
          return { color: 'gold', 'font-weight': 'bold' };
        }

        const redCondOptionA = pintsAdded > ndcl;

        if (redCondOptionA) {
          return { color: 'red', 'font-weight': 'bold' };
        }
      } else if (suddenIncreaseOption === 'Option B') {
        const greenCondOptB = pintsAdded < suddenIncreaseNormal || spotRate < spotHigh;

        const redCondOptB = pintsAdded >= suddenIncreaseNormal && spotRate > spotNormal;

        if (greenCondOptB) {
          return { color: 'green', 'font-weight': 'bold' };
        }

        if (redCondOptB) {
          return { color: 'red', 'font-weight': 'bold' };
        }
      } else if (suddenIncreaseOption === 'Option C') {
        const greenCondOptC = pintsAdded < suddenIncreaseNormal;
        const redCondOptC = pintsAdded >= suddenIncreaseNormal;

        if (greenCondOptC) {
          return { color: 'green', 'font-weight': 'bold' };
        }

        if (redCondOptC) {
          return { color: 'red', 'font-weight': 'bold' };
        }
      }
      return null;
    }
    return null;
  }
  getColorByConditionAverageRate(curentValue: number, compareValueNormal: number, compareValueHigh: number) {
    if (curentValue >= compareValueNormal && curentValue < compareValueHigh) {
      return { color: 'gold', 'font-weight': 'bold' };
    }
    if (curentValue >= compareValueHigh) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValueNormal) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }
  getColorByConditionSuddenIncress(dataItem: any) {
    if (dataItem) {
      const averageRate = dataItem.averageRate;
      const averageNormal = dataItem.averageNormal;
      const averageHigh = dataItem.averageHigh;

      if (dataItem.suddenIncreaseOption === 'Option A' || dataItem.suddenIncreaseOption === 'Option B' || dataItem.suddenIncreaseOption === 'Option C') {
        const yellowCond = averageRate >= averageNormal && averageRate < averageHigh;
        if (yellowCond) {
          return { color: 'gold', 'font-weight': 'bold' };
        }
        if ((averageRate !== 'NA' && averageHigh !== 'NA') && averageRate >= averageHigh) {
          return { color: 'red', 'font-weight': 'bold' };
        }
        if ((averageRate ! == 'NA' && averageNormal !== 'NA') && averageRate < averageNormal) {
          return { color: 'green', 'font-weight': 'bold' };
        }
      }
    }

    return null;
  }

  getColorByConditionSportRate(curentValue: number, compareValue: number) {
    if (curentValue > compareValue) {
      return { color: 'red', 'font-weight': 'bold' };
    }
    if (curentValue < compareValue) {
      return { color: 'green', 'font-weight': 'bold' };
    }
    return null;
  }

  closeErrorDialog() {
    this.errordialogeopend = false;
    if(this.isEmptyObject()){
    this.initLoadReport(null);
    }else{
    this.applyFilter();
    }
  }

  overridecloseErrorDialog() {
    this.overrideerrordialogeopend = false;
    if(this.isEmptyObject()){
    this.initLoadReport(this.viewOSPDetails);
    }else{
      this.applyFilter();
    }
  }
  closeSuccessDialog() {
    this.errordialogeopend = false;
    if(this.isEmptyObject()){
    this.initLoadReport(null);
    }else{
      this.applyFilter();
    }
  }
  //For deletee oil add

  deleteOilAdd(oilAddId: number) {
    this.backendservice.deleteOilAdd(oilAddId).subscribe(
      (result: string) => {
        if (result) {
          this.errorMessage = 'Oil Add Deleted Successfully';
          this.viewTheresholdModal = false;
          this.errordialogeopend = true;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  overRide() {
    this.isOverRide = true;
  }

  backOverRide() {
    this.isOverRide = false;
  }

  submitOverRide() {
    this.overRideSubmitted = true;
    if (this.overRideEngForm.invalid) {
      return;
    }
    const forValues = this.overRideEngForm.value;
    const finalFormValue = {
      id: this.viewOSPDetails.id,
      overriddenBy: this.employee ? Number(this.employee.uid).toString() : '',
      ...forValues
    };
    // delete finalFormValue.engine2Comments;
    this.backendservice.overRideEngDetails(finalFormValue).subscribe(
      (_result: any) => {
        this.backOverRide();
        this.closeAddThereshold();
        this.overRideEngForm.reset();
        this.errorMessage = 'Oil Add Override Completed';
        this.overrideerrordialogeopend = true;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  ///inital get reports//
  private initLoadReport(viewOilAddData: any): void {
    this.isLoading = true;
    this.backendservice.getOilAddList().subscribe(
      (result: IOilAdd[]) => {
        this.gridData = result.map((mapresult: any) => {
          mapresult.isExpanded = false;
          mapresult.isExpandedShow = mapresult.voids && mapresult.voids.length > 0 ? true : false;

          return { ...mapresult };
        });
        this.gridData = result;

        this.gridView = process(this.gridData, this.state);
        this.isLoading = false;
        if (viewOilAddData) {
          const finalResult: any = this.gridData.find((gridresult: any) => gridresult.id === viewOilAddData.id);
          if (finalResult) {
            this.addThereshold(finalResult);
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
private isEmptyObject(){
  const removeEmpty = (obj: any) => {
    Object.keys(obj).forEach(k => !obj[k] && obj[k] !== undefined && delete obj[k]);
    return obj;
  };
  const filterValues = this.applyFilterForm.value;
  const filterInput = removeEmpty(filterValues);
  return Object.keys(filterInput).length === 0;
}
filterModalClose(){
  this.filterOpened = false;
  this.applyFilterForm.reset();
  
}

  public filterClose() {
    this.filterOpened = false;
    this.applyFilterForm.reset();
    this.initLoadReport(null);
  }

  applyFilterFromList(){
    this.submitted = true;
    const filterValues = this.applyFilterForm.value;
    
    if(!filterValues.aircraft){
      alert('Aircraft number is required');
      return;
    }
    
    if(this.applyFilterForm.invalid){
     
        return; 
    }
    this.applyFilter();
  }
  applyFilter() {
    const removeEmpty = (obj: any) => {
      Object.keys(obj).forEach(k => !obj[k] && obj[k] !== undefined && delete obj[k]);
      return obj;
    };
    this.submitted = true;
    const filterValues = this.applyFilterForm.value; 
    filterValues.aircraft =  filterValues.aircraft ?  filterValues.aircraft.toUpperCase():'';
    const filterInput = removeEmpty(filterValues);
    this.isLoading = true;
    this.filterOpened = false;
    this.backendservice.getOilAddListByApplyFilter(filterInput).subscribe(
      (result: IOilAdd[]) => {
        this.gridData = result.map((mapresult: any) => {
          mapresult.isExpanded = false;
          mapresult.isExpandedShow = mapresult.voids && mapresult.voids.length > 0 ? true : false;

          return { ...mapresult };
        });
        this.gridData = result;
        this.gridView = process(this.gridData, this.state);
       
        this.filterOpened = false;
        this.isLoading = false;
        //this.applyFilterForm.reset();
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  public filterOpen() {
    this.filterOpened = true;
  }

  /*   private getSafeAircraftDetails(acNumber: string): void {
    this.backendservice.getSafeAircraftDetails(acNumber, 'US').subscribe(
      (result: ISafeOspAircraft[]) => {
        const safeaircraftDetails = result;                 
        this.safeospaircraft = safeaircraftDetails.length ? safeaircraftDetails[0] : null;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
 */

  addOil(addStatus: boolean, editData?: any, ospData?: any, rgnData?: any, gqlData?: any) {
    this.addTheresholdModal = true;
    this.viewSubTheresholdDetailsModal = false;
    const finalEditData = {
      viewOSPDetails: editData
    };
    if (addStatus) {
      this.isFormCreate = true;
      this.editData = editData;
      this.ospData = ospData;
      this.rgnData = rgnData;
      this.gqlData = gqlData;
    } else {
      this.isFormCreate = false;
      this.editData = finalEditData;
      this.viewTheresholdModal = false;
      this.ospData = ospData;
      this.rgnData = rgnData;
      this.gqlData = gqlData;
    }
  }
  closeThereshold() {
    this.addTheresholdModal = false;
  }
  getEngineInfo(noseNumber: string): Observable<any> {
    this.enginequery = this.gatewayService.query<any>(GET_ENGINE_INFO, {
      input: {
        noseNumber: noseNumber
      }
    });

    return (this.enginequery = this.enginequery.pipe(
      map((res: ApolloQueryResult<any>) => {
        return this.mapEngine(res.data.engines);
      }) /*  */
    ));
  }

  mapEngine(data: any) {
    const engineArray: EngineDetails[] = [];
    if (data) {
      let engineDetail: EngineDetails;
      data.engines.forEach((item: any) => {
        engineDetail = {
          componentStatus: this.mapComponentStatus(item.componentStatus)
        };
        engineArray.push(engineDetail);
      });
    }

    return engineArray;
  }

  mapComponentStatus(componentStatus: any): EngineComponentStatus[] {
    const componentStatusArray: EngineComponentStatus[] = [];
    componentStatus.forEach((item: any) => {
      const engineTimeCycles: EngineComponentStatus = {
        partNumber: item.partNumber,
        serialNumber: item.serialNumber,
        mfgSerialNumber: item.mfgSerialNumber,
        description: item.partDescription,
        positionCode: item.positionCode,
        tsoCycles: item.tso.cycles,
        tsoHours: item.tso.hours,
        totalTimeCycles: item.totalTime.cycles,
        totalTimeHours: item.totalTime.hours,
        tsoAtInstallCycles: item.tsoAtInstall.cycles,
        tsoAtInstallHours: item.tsoAtInstall.hours,
        tsrCycles: item.tsr.cycles,
        tsrHours: item.tsr.hours
      };
      componentStatusArray.push(engineTimeCycles);
    });
    return componentStatusArray;
  }
}
