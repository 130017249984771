import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as smActions from './+state/siteminder-authentication.actions';
import { SiteminderUser } from './+state/siteminder-authentication.interfaces';
import * as fromRoot from './+state/siteminder-authentication.reducer';
import { LOCATION_TOKEN, SiteminderAgentConfig, SM_AGENT_CONFIG } from './siteminder-authentication-config';

@Injectable({
  providedIn: 'root'
})
export class SiteminderAuthenticationService {
  constructor(
    @Inject(SM_AGENT_CONFIG) private config: SiteminderAgentConfig,
    @Inject(LOCATION_TOKEN) private location: any,
    private idle: Idle,
    private keepAlive: Keepalive,
    private http: HttpClient,
    private store: Store<any>
  ) {}

  initialize(): void {
    this.store.dispatch(new smActions.SessionInitializeAction());
  }

  loadUser(): Observable<SiteminderUser> {
    return this.http.get<SiteminderUser>(this.config.url);
  }

  renewSession() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.interrupt();
    this.store.dispatch(new smActions.SessionRenewCompleteAction());
  }

  unAuthorizeRequest(url: string): void {
    this.store.dispatch(new smActions.UnauthorizedRequestAction(url));
  }

  setupIdleTimer(user: SiteminderUser) {
    this.store.dispatch(new smActions.SessionInitializeCompleteAction(user));
    // https://hackedbychinese.github.io/ng2-idle/
    this.idle.setIdle(user.sm_idle_threshold);
    this.idle.setTimeout(user.sm_session_timeout - user.sm_idle_threshold);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    if (this.config.keepAlive === true) {
      this.keepAlive.interval(user.sm_keep_alive_interval);
      this.keepAlive.request(this.config.url);
    }
    this.idle.watch();
  }
  user(): Observable<SiteminderUser> {
    return this.store.pipe(
      select(fromRoot.getUser),
      filter(u => u.sm_access_token !== '')
    );
  }
  isAuthenticated(): Observable<boolean> {
    return this.user().pipe(map(u => !!u));
  }

  displayName(): Observable<string> {
    return this.user().pipe(map(u => `${u.firstname} ${u.lastname}`));
  }

  firstName(): Observable<string> {
    return this.user().pipe(map(u => u.firstname));
  }

  lastName(): Observable<string> {
    return this.user().pipe(map(u => u.lastname));
  }

  email(): Observable<string> {
    return this.user().pipe(map(u => u.email));
  }

  sessionExpires(): Observable<Date> {
    return this.user().pipe(map(u => u.sm_session_expires));
  }

  badgeNo(): Observable<string> {
    return this.user().pipe(map(u => u.sm_user));
  }

  roles(): Observable<string[]> {
    return this.user().pipe(map(u => u.sm_roles));
  }

  hasRole(role: string): Observable<boolean> {
    return this.user().pipe(map(u => u.sm_roles.indexOf(role) > -1));
  }

  hasAnyRole(roles: string[]): Observable<boolean> {
    return this.user().pipe(map(u => u.sm_roles.some((role: string) => roles.indexOf(role) > -1)));
  }

  accessToken(): Observable<string> {
    return this.user().pipe(map(u => u.sm_access_token));
  }

  smSession(): Observable<string> {
    return this.user().pipe(map(u => u.sm_session));
  }

  logOutUrl(): Observable<string> {
    return this.user().pipe(map(u => u.sm_logout_url));
  }

  logInUrl(): Observable<string> {
    return this.user().pipe(map(u => u.sm_login_url));
  }
  isUserIdle(): Observable<boolean> {
    return this.user().pipe(map(u => u.sm_is_idle));
  }

  secondsLeftToTimeout(): Observable<number> {
    return this.user().pipe(map(u => u.sm_seconds_to_timeout));
  }

  redirectUrl(): Observable<string> {
    return this.user().pipe(
      map(u => u.sm_logout_url),
      map(logoutUrl => {
        const [sm_url] = logoutUrl.split('=');
        const redirectUrl = `${sm_url}=${encodeURIComponent(this.location.href)}`;
        return redirectUrl;
      })
    );
  }
  isUserTimedOut(): Observable<boolean> {
    return this.user().pipe(map(u => u.sm_timed_out));
  }

  otherAttribute(attribute: string): Observable<any> {
    return this.user().pipe(map((u: SiteminderUser) => u[attribute]));
  }
}
