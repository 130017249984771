import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import * as moment from 'moment-timezone';
import { NavigationItem } from '@techops-ui/common/data-access/navigation';
import { Employee } from '../../models/employee.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'aa-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.css']
})
export class UserPopupComponent {
  @Input()
  show: boolean;
  @Input()
  showPreferences: boolean;
  @Input()
  anchor: string;
  @Input()
  settingsUrl: string;
  @Input()
  logoutUrl: Observable<string> ;
  @Input()
  items: NavigationItem[];
  @Output()
  showPrefsPopup = new EventEmitter();
  @Input()
  userRole: string;
  @Input()
  employee: Employee;

  anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  margin = { horizontal: -15, vertical: 20 };
  duration = 300;
  type = 'fade';
  direction = 'down';
  now: Date = new Date();
  tz = moment.tz.guess();
  zone = moment.tz(this.tz).format('z');
  localNow = this.addZero(this.now.getHours()) + ':' + this.addZero(this.now.getMinutes()) + ' ' + this.zone;
  gmtNow: string = this.addZero(this.now.getUTCHours()) + ':' + this.addZero(this.now.getUTCMinutes()) + ' GMT';

  constructor() {
    setInterval(() => {
      this.now = new Date();
      this.localNow = this.addZero(this.now.getHours()) + ':' + this.addZero(this.now.getMinutes()) + ' ' + this.zone;
      this.gmtNow = this.addZero(this.now.getUTCHours()) + ':' + this.addZero(this.now.getUTCMinutes()) + ' GMT';
    }, 10000);
  }
  SignOutUser(){
    localStorage.clear();
    sessionStorage.clear();
    return true;
  }
  get empName(): string {
    
    if (this.employee) {
      return this.employee.FirstName + ' ' + this.employee.LastName + ' (' + this.employee.EmployeeID + ')';
    }
    return 'ACCOUNT';
  }

  get animate(): any {
    return {
      type: this.type,
      direction: this.direction,
      duration: this.duration
    };
  }

  addZero(i: any) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  userPreferencesClicked() {
    this.show = false;
    this.showPrefsPopup.emit();
  }
  close() {
    //Missing
  }
}
