// tslint:disable-line
import { NgModule, Inject } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { createPersistedQueryLink } from 'apollo-angular-link-persisted';
import { split, ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
import { GatewayEnvironment } from '@techops-ui/common/interfaces/gateway';

@NgModule({
  declarations: [],
  imports: [ApolloModule, HttpClientModule, HttpLinkModule],
  providers: [],
  exports: [ApolloModule]
})
export class GatewayApolloModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GatewayApolloModule,
      providers: []
    };
  }

  constructor(@Inject('environment') private env: GatewayEnvironment, apollo: Apollo, httpLink: HttpLink) {
    const http = createPersistedQueryLink().concat(
      httpLink.create({
        uri: this.env.gatewayApi
      })
    );

    const ws = new WebSocketLink({
      uri: this.env.gatewayApi.startsWith('https') ? this.env.gatewayApi.replace('https', 'wss') : this.env.gatewayApi.replace('http', 'ws'),
      options: {
        reconnect: true
      }
    });

    const errorLink = onError(({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError.message}`);
      }
    });

    // since the getMainDefinition can return either a OperationDefinitionNode or a FragmentDefinitinoNode,
    // but only the OperationDefinitionNode has an operation property, supply a nullable interface.
    // https://github.com/apollographql/apollo-client/issues/3090
    interface Definition {
      kind: string;
      operation?: string;
    }

    const splitLink = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation }: Definition = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      http
    );

    const link = ApolloLink.from([errorLink, splitLink]);

    apollo.createNamed('Gateway', {
      link,
      cache: new InMemoryCache()
    });
  }
}
