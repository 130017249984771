import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from '../app-routing.module';
import { OspFeatureKendoMaterialModule } from './kendo-material.module';
import { ThresholdComponent } from './threshold/threshold.component';
import { ViewThresholdScreenComponent } from './view-threshold-screen/view-threshold-screen.component';
import { AdminComponent } from './admin/admin.component';
import { EditAdminRecordComponent } from './edit-admin-record/edit-admin-record.component';
import { AircraftMainComponent } from './aircraft-main/aircraft-main.component';
import { ReportsComponent } from './reports/reports.component';
import { OiladdComponent } from './oiladd/oiladd.component';
import { DeletedOiladdComponent } from './deleted-oiladd/deleted-oiladd.component';
import { ViewOiladdScreenComponent } from './view-oiladd-screen/view-oiladd-screen.component';
import { SafeOspOiladdComponent } from './safe-osp-oiladd/safe-osp-oiladd.component';
import { SafeAddoilComponent } from './safe-addoil/safe-addoil.component';
import { CommonTFPGatewayService } from '@techops-ui/common/data-access/tfp-gateway';
import { Apollo } from 'apollo-angular';
import { SafeSuccessScreenComponent } from './safe-success-screen/safe-success-screen.component';
import { SafeOspLandingpageComponent } from './safe-osp-landingpage/safe-osp-landingpage.component';
import { CommonComponentsLoadingModule } from '@techops-ui/common/components/loading';
import { ViewFeedbackComponent } from './view-feedback/view-feedback.component';
import { UnauthorizationComponent } from './unauthorization/unauthorization.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PredicitveComponent } from './predictive-oil-add/predictive.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AppRoutingModule, OspFeatureKendoMaterialModule, CommonComponentsLoadingModule],
  declarations: [
    HomeComponent,
    ThresholdComponent,
    ViewThresholdScreenComponent,
    AdminComponent,
    EditAdminRecordComponent,
    AircraftMainComponent,
    ReportsComponent,
    OiladdComponent,
    DeletedOiladdComponent,
    ViewOiladdScreenComponent,
    SafeOspOiladdComponent,
    SafeAddoilComponent,
    SafeSuccessScreenComponent,
    SafeOspLandingpageComponent,
    ViewFeedbackComponent,
    UnauthorizationComponent,
    NotificationsComponent,
    PredicitveComponent,
    

  ],
  providers: [CommonTFPGatewayService, Apollo, DatePipe]
})
export class OspFeatureOspModule {}
