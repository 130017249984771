export class StringHelperService {
  constructor(public stringValue: string) {}

  replaceCharacter(originalDelimiter: string, updatedDelimiter: string): string {
    return this.replaceCharacterConditionally(originalDelimiter, updatedDelimiter, () => false);
  }

  replaceCharacterConditionally(originalDelimiter: string, updatedDelimiter: string, evalMethod: (currentStr: string) => boolean): string {
    return this.replaceStartAndEndWithCharacterOnCondition(originalDelimiter, updatedDelimiter, evalMethod, false);
  }

  replaceLineFeedConditionally(evalMethod: (currentStr: string) => boolean, shouldIncludeLineEnding: boolean) {
    return this.replaceStartAndEndWithCharacterOnCondition('\n', ' ', evalMethod, shouldIncludeLineEnding);
  }

  replaceStartAndEndWithCharacterOnCondition(
    originalDelimiter: string,
    updatedDelimiter: string,
    evalMethod: (currentStr: string) => boolean,
    shouldIncludeLineEnding: boolean
  ): string {
    const strArray: string[] = this.stringValue.split(originalDelimiter);
    let outputString = '';
    let shouldApplyToNextLine = false;

    for (let i = 0, len = strArray.length; i < len; ++i) {
      const str: string = strArray[i];

      if (str == null || str === '') {
        continue;
      }

      if (outputString === '') {
        outputString = str;
        continue;
      }

      let delimiter = updatedDelimiter;

      if (evalMethod != null) {
        const shouldNotUpdateDelimiter = evalMethod(str);
        if (shouldNotUpdateDelimiter || shouldApplyToNextLine) {
          delimiter = originalDelimiter;
          if (shouldIncludeLineEnding) {
            shouldApplyToNextLine = !shouldApplyToNextLine;
          }
        }
      }

      outputString += delimiter + str;
    }
    return outputString;
  }
}
