import { Action } from '@ngrx/store';
import { SiteminderUser } from './siteminder-authentication.interfaces';

export enum SiteminderAuthenticationActionTypes {
  IDLE_START = '[SiteminderAuthentication] Idle Start',
  IDLE_END = '[SiteminderAuthentication] Idle End',
  TIME_OUT = '[SiteminderAuthentication] Time Out',
  UNAUTHORIZED_REQUEST = '[SiteminderAuthentication] Unauthorized Request',
  TIMEOUT_WARNING = '[SiteminderAuthentication] Timeout Warning',
  SESSION_INITIALIZE = '[SiteminderAuthentication] User Session Initialize',
  SESSION_INITIALIZE_COMPLETE = '[SiteminderAuthentication] User Session Initialize Complete',
  SESSION_EXTEND = '[SiteminderAuthentication] User Session Extend',
  SESSION_RENEW = '[SiteminderAuthentication] User Session Renewal',
  SESSION_RENEW_COMPLETE = '[SiteminderAuthentication] User Session Renewal Complete'
}
/**
 * An action dispatched when the user goes idle.
 */
export class IdleStartAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.IDLE_START;
  constructor() {}
}
/**
 * An action dispatched when the user becomes active.
 */
export class IdleEndAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.IDLE_END;
  constructor() {}
}
/**
 * An action dispatched when the user session times out.
 */
export class TimeOutAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.TIME_OUT;
  constructor(/** Siteminder Logout url to redirect after session timeout */ public payload: string) {}
}
/**
 * An action dispatched when the application makes an unauthorized request to backend services.
 */
export class UnauthorizedRequestAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.UNAUTHORIZED_REQUEST;
  constructor(/** Requested Url */ public payload: string) {}
}
/**
 * An action dispatched when the user is currently idle.
 */
export class TimeoutWarningAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.TIMEOUT_WARNING;
  constructor(/** No of seconds left before timeout */ public payload: number) {}
}

/**
 * An action dispatched when the user session is initiated.
 */
export class SessionInitializeAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.SESSION_INITIALIZE;
  constructor() {}
}

/**
 * An action dispatched when the user session is initialized.
 */
export class SessionInitializeCompleteAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.SESSION_INITIALIZE_COMPLETE;
  constructor(/** Siteminder User Details */ public payload: SiteminderUser) {}
}

/**
 * An action dispatched when the user session is to be extended.
 * This will be initiated from the application end after user clicks 'Extend Session' button
 * on siteminder session timeout warning dialog
 */
export class SessionExtendAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.SESSION_EXTEND;
  constructor() {}
}

/**
 * An action dispatched when the user session is to be renewed.
 */
export class SessionRenewAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.SESSION_RENEW;
  constructor(/** Siteminder User Details */ public payload: SiteminderUser) {}
}

/**
 * An action dispatched when the user session is renewal completed.
 */
export class SessionRenewCompleteAction implements Action {
  readonly type = SiteminderAuthenticationActionTypes.SESSION_RENEW_COMPLETE;
  constructor() {}
}

export type SiteminderAuthenticationActions =
  | IdleStartAction
  | IdleEndAction
  | TimeOutAction
  | UnauthorizedRequestAction
  | TimeoutWarningAction
  | SessionInitializeAction
  | SessionInitializeCompleteAction
  | SessionRenewAction
  | SessionRenewCompleteAction;
