import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'aa-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  @Input()
  version: string;

  constructor() {}

  ngOnInit() {}
}
