export enum UserRoles {
  OSP_END_USER = 'OSP End Users',
  MOC_TECHNICIANS = 'MOC Technician', 
  OSP_ENGINEERING = 'OSP Engineering',
  OSP_ADMIN = 'Admin',
  MOC_SUPER_USER = 'MOC Super User',
  POS_ADMIN = 'POS ADMIN'
}

export interface PermissionOSP{
  isThresholdView:boolean;
isThresholdAdd:boolean;
isThresholdEdit:boolean;
isThresholdDelete:boolean;
isOiladdView:boolean;
isOiladdAdd:boolean;
isOiladdEdit:boolean;
isOiladdDelete:boolean;
isOiladdHardDelete:boolean;
isOiladdOverride:boolean;
isAdminView:boolean;
isAdminEdit:boolean;
isReportsView:boolean;
isAircraftAdd:boolean;
isAircraftEdit:boolean;
isFeedbackView:boolean;
isFeedbackAdd:boolean;
isNotificationShow:boolean;
predictiveOilAdd:boolean;
}

export class RolePermission {
  public static Default: PermissionOSP = {
    isThresholdView:false,
isThresholdAdd:false,
isThresholdEdit:false,
isThresholdDelete:false,
isOiladdView:false,
isOiladdAdd:false,
isOiladdHardDelete:false,
isOiladdEdit:false,
isOiladdDelete:false,
isOiladdOverride:false,
isAdminView:false,
isAdminEdit:false,
isReportsView:false,
isAircraftAdd:false,
isAircraftEdit:false,
isFeedbackView:true,
isFeedbackAdd:true,
isNotificationShow:false,
predictiveOilAdd:false,
  };
 
 
  public static MOCTechPermission: PermissionOSP = {
    isThresholdView:false,
    isThresholdAdd:false,
    isThresholdEdit:false,
    isThresholdDelete:false,
    isOiladdView:true,
    isOiladdAdd:true,
    isOiladdEdit:true,
    isOiladdDelete:true,
    isOiladdHardDelete:false,
    isOiladdOverride:true,
    isAdminView:false,
    isAdminEdit:false,
    isReportsView:false,
    isAircraftAdd:false,
    isAircraftEdit:false,
    isFeedbackView:true,
    isFeedbackAdd:true,
    isNotificationShow:false,
    predictiveOilAdd:false
  };
  public static OSPEngPermission: PermissionOSP = {
    isThresholdView:false,
    isThresholdAdd:true,
    isThresholdEdit:true,
    isThresholdDelete:true,
    isOiladdHardDelete:false,
    isOiladdView:true,
    isOiladdAdd:false,
    isOiladdEdit:false,
    isOiladdDelete:false,
    isOiladdOverride:false,
    isAdminView:false,
    isAdminEdit:true,
    isReportsView:false,
    isAircraftAdd:true,
    isAircraftEdit:true,
    isFeedbackView:false,
    isFeedbackAdd:false,
    isNotificationShow:false,
    predictiveOilAdd:false
  };
  public static OSPAdmin: PermissionOSP = {
    isThresholdView:true,
    isThresholdAdd:true,
    isThresholdEdit:true,
    isThresholdDelete:true,
    isOiladdView:true,
    isOiladdAdd:true,
    isOiladdEdit:true,
    isOiladdDelete:true,
    isOiladdHardDelete:true,
    isOiladdOverride:true,
    isAdminView:true,
    isAdminEdit:true,
    isReportsView:true,
    isAircraftAdd:true,
    isAircraftEdit:true,
    isFeedbackView:true,
    isFeedbackAdd:true,
    isNotificationShow:true,
    predictiveOilAdd:false,
  };

  public static AdminPOS: PermissionOSP = {
    isThresholdView:true,
    isThresholdAdd:true,
    isThresholdEdit:true,
    isThresholdDelete:true,
    isOiladdView:true,
    isOiladdAdd:true,
    isOiladdEdit:true,
    isOiladdDelete:true,
    isOiladdHardDelete:true,
    isOiladdOverride:true,
    isAdminView:true,
    isAdminEdit:true,
    isReportsView:true,
    isAircraftAdd:true,
    isAircraftEdit:true,
    isFeedbackView:true,
    isFeedbackAdd:true,
    isNotificationShow:true,
    predictiveOilAdd:true
  };
}
