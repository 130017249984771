import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackendService } from './services/backend.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.services';
import { AuthGuard } from './services/auth.gaurd';
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [BackendService,  AuthService,
    AuthGuard,]
})
export class OspDataAccessRootModule {}
