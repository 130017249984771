import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';

@Injectable()
export class CommonGatewayService {
  constructor(private apollo: Apollo) {}

  query<T>(query: any, variables?: any): QueryRef<T> {
    return this.apollo.use('Gateway').watchQuery({
      query: query,
      variables: variables
    }) as QueryRef<T>;
  }
}
