import { NgModule, Inject, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { createPersistedQueryLink } from 'apollo-angular-link-persisted';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Observable, Subscription } from 'rxjs';
import { TFPGatewayEnvironment } from '@techops-ui/common/interfaces/tfp-gateway';
import { AuthDotNetService } from './services/auth-dotnet.service';

@NgModule({
  declarations: [],
  imports: [ApolloModule, HttpClientModule, HttpLinkModule],
  providers: [],
  exports: [ApolloModule]
})
export class GatewayDotNetTFPApolloModule {
  $identity: Observable<any>;
  identitySubscription: Subscription;
  token: string;

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GatewayDotNetTFPApolloModule,
      providers: []
    };
  }

  constructor(@Inject('environment') private env: TFPGatewayEnvironment, apollo: Apollo, httpLink: HttpLink, private authService: AuthDotNetService) {
    this.identitySubscription = this.authService.getAuth().subscribe(data => {
      if (data) {
        this.token = data.access_token;
      }
    });

    const auth = setContext((_request, _previousContext) => {
      if (!this.token) {
        return {};
      }
      return {
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token}`,
          'apollographql-client-name': '' + this.env.tfpAppName,
          'apollographql-client-version': this.env.tfpVersion
        })
      };
    });
    const http = createPersistedQueryLink().concat(
      ApolloLink.from([
        auth,
        httpLink.create({
          uri: this.env.tfpgatewayApi
        })
      ])
    );

    apollo.createNamed('TFPGateway', {
      link: http,
      cache: new InMemoryCache(),
      name: this.env.tfpAppName,
      version: this.env.tfpVersion
    });
  }
}
