import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { Employee } from '../../models';
import { Navigation, ToggleSideMenu } from '@techops-ui/common/data-access/navigation';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { techopsApps } from '../../apps';
import { LtermComponent } from '@techops-ui/common/feature-lterm';
import * as svg4everybody_ from 'svg4everybody';
const svg4everybody = svg4everybody_;

@Component({
  selector: 'aa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', './c.topbar.css', './c.leftnav-drawer.css']
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  product: string;
  @Input()
  productRoute: string;
  @Input()
  productUrl: string;
  @Input()
  appName: string;
  @Input()
  appRoute: string;
  @Input()
  appUrl: string;
  @Input()
  sectionName: string;
  @Input()
  sectionRoute: string;
  @Input()
  sectionUrl: string;
  @Input()
  employee: Employee;
  @Input()
  logoutUrl: string;
  @Input()
  showApps: boolean;
  @Input()
  showPreferences: boolean;
  @Input()
  initalPopupOnLoad: boolean;
  @Input()
  restrictJobPositions: string[];
  @Input()
  userRole: string;

  @Input()
  set openPreferences(value: boolean) {
    if (value) {
      this.ltermModal.ShowModal();
    }
  }

  @ViewChild('apps')
  appsAnchor: ElementRef;
  @ViewChild('appsPopup', { read: ElementRef })
  appsPopup: ElementRef;
  @ViewChild('help')
  helpAnchor: ElementRef;
  @ViewChild('helpPopup', { read: ElementRef })
  helpPopup: ElementRef;
  @ViewChild('user')
  userAnchor: ElementRef;
  @ViewChild('userPopup', { read: ElementRef })
  userPopup: ElementRef;
  @ViewChild('ltermModal', { read: LtermComponent })
  ltermModal: LtermComponent;

  showMenuPopup = false;
  showAppsPopup = false;
  showHelpPopup = false;
  showUserPopup = false;
  enabled = true;
  readonly defaultRoute = '/';

  today: number;
  navigation: Navigation;
  safeApps = techopsApps;
  subscription: Subscription;
  LtermActionMessage: string;
  hover = false;

  constructor(private store: Store<any>) {
    this.today = Date.now();
    this.utcTime();
  }

  ngOnInit() {
    svg4everybody({ polyfill: true });

    this.subscription = this.store.pipe(select('navigation')).subscribe(data => (this.navigation = data));
  }

  ngOnChanges() {
    if (!this.appRoute && this.appRoute !== '' && !this.appUrl) {
      this.appRoute = this.defaultRoute;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (this.containsApps(event.target)) {
      this.onHelpToggle(false);
      this.onUserToggle(false);
    } else if (this.containsHelp(event.target)) {
      this.onAppsToggle(false);
      this.onUserToggle(false);
    } else if (this.containsUser(event.target)) {
      this.onAppsToggle(false);
      this.onHelpToggle(false);
    } else {
      this.onAppsToggle(false);
      this.onHelpToggle(false);
      this.onUserToggle(false);
    }
  }

  utcTime(): void {
    setInterval(() => {
      this.today = Date.now();
    }, 1000);
  }

  onMenuToggle(show?: boolean): void {
    this.store.dispatch(new ToggleSideMenu(show !== undefined ? show : !this.navigation.Opened));
  }

  leftnav_drawer_on() {
    this.showMenuPopup = true;
  }

  leftnav_drawer_off() {
    this.showMenuPopup = false;
  }

  onAppsToggle(show?: boolean): void {
    this.showAppsPopup = show !== undefined ? show : !this.showAppsPopup;
  }

  onHelpToggle(show?: boolean): void {
    this.showHelpPopup = show !== undefined ? show : !this.showHelpPopup;
  }

  onUserToggle(show?: boolean): void {
    this.showUserPopup = show !== undefined ? show : !this.showUserPopup;
  }

  openUserPreferences() {
    this.ltermModal.ShowModal();
  }

  displayLtermmessage(LtermActionMessage: any) {
    this.LtermActionMessage = LtermActionMessage;
    console.log(LtermActionMessage);
  }

  panLeft(evt: any) {
    console.log(evt.center.x);
  }

  private containsApps(target: any): boolean {
    if (this.appsAnchor) {
      return this.appsAnchor.nativeElement.contains(target) || (this.appsPopup ? this.appsPopup.nativeElement.contains(target) : false);
    }
    return false;
  }

  private containsHelp(target: any): boolean {
    if (this.helpAnchor) {
      return this.helpAnchor.nativeElement.contains(target) || (this.helpPopup ? this.helpPopup.nativeElement.contains(target) : false);
    }
    return false;
  }

  private containsUser(target: any): boolean {
    if (this.userAnchor) {
      return this.userAnchor.nativeElement.contains(target) || (this.userPopup ? this.userPopup.nativeElement.contains(target) : false);
    }
    return false;
  }
}
