import { Action } from '@ngrx/store';
import { Navigation } from './navigation.reducer';

export enum NavigationActionTypes {
  LoadNavigation = '[Navigation] Load Data',
  ToggleNavigationItem = '[Navigation] Toggle Navigation Item',
  ToggleSideMenu = '[Navigation] Toggle Side Menu'
}

export class LoadNavigation implements Action {
  readonly type = NavigationActionTypes.LoadNavigation;
  constructor(public payload: Navigation) {}
}

export class ToggleNavigationItem implements Action {
  readonly type = NavigationActionTypes.ToggleNavigationItem;
  constructor(public payload: { id: number; text: string }) {}
}

export class ToggleSideMenu implements Action {
  readonly type = NavigationActionTypes.ToggleSideMenu;
  constructor(public payload: boolean) {}
}

export type NavigationActions = LoadNavigation | ToggleNavigationItem | ToggleSideMenu;
