import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendService, IEngineDetails } from '@techops-ui/osp/data-access-root';

@Component({
  selector: 'techops-ui-safe-osp-oiladd',
  templateUrl: './safe-osp-oiladd.component.html',
  styleUrls: ['./safe-osp-oiladd.component.css']
})
export class SafeOspOiladdComponent implements OnInit {
  engineDetails: IEngineDetails;
  engine1Details: IEngineDetails;
  apuDetails: IEngineDetails;
  aircraftDetails: any;
  paramACNumber: string;
  isLoading = false;
  loaderList: Array<number> = [];
  constructor(private router: Router, private backendservice: BackendService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['acNumber']) {
        this.paramACNumber = params['acNumber'];
        this.isLoading = true;
        this.getEng1DetailsByAC(params['acNumber']);
        this.getAPUDetailsByAC(params['acNumber']);
        this.getEng2DetailsByAC(params['acNumber']);
        this.getAircraftDetailsAC(params['acNumber']);
      }
    });
    this.route.queryParams
      .subscribe(params => {
       if (params['ac']) {
        this.paramACNumber = params['ac'];
        this.isLoading = true;
        this.getEng1DetailsByAC(params['ac']);
        this.getAPUDetailsByAC(params['ac']);
        this.getEng2DetailsByAC(params['ac']);
        this.getAircraftDetailsAC(params['ac']);
      }
      }
    );
  }
  private getEng1DetailsByAC(acNumber: string): void {
    this.backendservice.getEngineDetails('GetEngine1Details', acNumber).subscribe(
      (result: IEngineDetails[]) => {
        const engineDetails = result;
        this.engineDetails = engineDetails.length ? engineDetails[0] : null;
        this.hideLoader();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  private getEng2DetailsByAC(acNumber: string): void {
    this.backendservice.getEngineDetails('GetEngine2Details', acNumber).subscribe(
      (result: IEngineDetails[]) => {
        const engineDetails = result;
        this.engine1Details = engineDetails.length ? engineDetails[0] : null;
        this.hideLoader();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  private getAircraftDetailsAC(acNumber: string): void {
    this.backendservice.getAircraftDetails(acNumber, 'US').subscribe(
      (result: IEngineDetails[]) => {
        const aircraftDetails = result;
        this.aircraftDetails = aircraftDetails.length ? aircraftDetails[0] : null;
        this.hideLoader();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  private getAPUDetailsByAC(acNumber: string): void {
    this.backendservice.getEngineDetails('GetApuDetails', acNumber).subscribe(
      (result: IEngineDetails[]) => {
        const apuDetails = result;
        this.apuDetails = apuDetails.length ? apuDetails[0] : null;
        this.hideLoader();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  hideLoader() {
    const numOfAPICalls = 4;
    this.loaderList.push(this.loaderList.length + 1);
    if (this.loaderList.length === numOfAPICalls) {
      this.isLoading = false;
    }
  }
  oliAdd() {
    this.router.navigate(['add-safe-oil', this.paramACNumber]);
  }
  goBack() {
    this.router.navigate(['oil-add-landingpage'], { queryParams: { airCraftNumber: this.paramACNumber } });
  }
}
