import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionExtendAction } from '../+state/siteminder-authentication.actions';
import { SiteminderUser } from '../+state/siteminder-authentication.interfaces';
import { getIsUserIdle, getSecondsToTimeOut } from '../+state/siteminder-authentication.reducer';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'aa-common-siteminder-session-timeout-warning-dialog',
  templateUrl: './siteminder-session-timeout-warning-dialog.component.html',
  styleUrls: ['./siteminder-session-timeout-warning-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SiteminderSessionTimeoutWarningDialogComponent implements OnInit {
  isUserIdle$: Observable<boolean>;
  secondsLeftToTimeout$: Observable<number>;
  formattedDurationLeft$: Observable<string>;
  constructor(private store: Store<SiteminderUser>) {
    this.isUserIdle$ = store.pipe(select(getIsUserIdle));
    this.secondsLeftToTimeout$ = store.pipe(select(getSecondsToTimeOut));
    //this.formattedDurationLeft$ = this.secondsLeftToTimeout$.pipe(map(s=> moment.duration(s, 'seconds').humanize()));
    this.formattedDurationLeft$ = this.secondsLeftToTimeout$.pipe(
      map(s => moment.utc(moment.duration(s, 'seconds').as('milliseconds')).format('mm:ss') + ' seconds')
    );
  }

  ngOnInit() {}

  public renewSession(): void {
    this.store.dispatch(new SessionExtendAction());
  }
}
