import { Component, ViewChild, OnInit } from '@angular/core';
import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BackendService, IThresholdReport,AuthService,PermissionOSP,RolePermission } from '@techops-ui/osp/data-access-root';
import { SortDescriptor } from '@progress/kendo-data-query';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'techops-ui-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.css']
})
export class ThresholdComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  addTheresholdModal = false;
  isLoading = false;
  public allowUnsort = false;
  public gridView: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  editData: any;
  public state: State = {
    skip: 0,
    take: 10,
    sort: [{ dir: 'asc', field: 'cpn' }]
  };
  public isFormCreate = false;
  gridData: IThresholdReport[];
  public sort: SortDescriptor[] = [
    {
      field: 'cpn',
      dir: 'asc'
    }
  ];
  groupHeaderLeftStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-left': '1px solid #5f5353',
    'border-right': '1px solid #5f5353',
    'text-align': 'center'
  };
  groupHeaderRightStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-right': '1px solid #5f5353',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  subHeaderBorderBottom = {
    'border-bottom': '1px solid #6e8999'
  };
  headerBorderLeft = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  headerBorderRight = {
    'background-color': 'rgb(228, 231, 235)',
    'border-right': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  defaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#e4e7eb',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  firstCellBorder = {
    'border-top': '1px solid red'
  };
  roleAccess: PermissionOSP = RolePermission.Default;
  constructor(private backendservice: BackendService, private authServices:AuthService) {}

  ngOnInit() {
    this.roleAccess = this.authServices.checkRoleAccess();
    this.initLoadReport();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.gridData, this.state);
  }

  thresholdCreation() {
    this.addTheresholdModal = false;
    this.initLoadReport();
  }
  expandAndCollapse(index: number, isExpand: boolean) {
    if (isExpand) {
      this.grid.expandRow(index);
      this.gridView.data[index].isExpanded = true;
    } else {
      this.grid.collapseRow(index);
      this.gridView.data[index].isExpanded = false;
    }
  }

  private initLoadReport(): void {
    this.isLoading = true;
    this.backendservice.getThresholdList().subscribe(
      (result: IThresholdReport[]) => {
        this.gridData = result.map((mapresult: IThresholdReport) => {
          mapresult.isExpanded = false;
          return { ...mapresult };
        });
        this.isLoading = false;
        this.gridView = process(this.gridData, this.state);
      },
      (error: any) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  addThereshold(addStatus: boolean, editData?: any) {
    this.addTheresholdModal = true;
    if (addStatus) {
      this.isFormCreate = true;
    } else {
      this.isFormCreate = false;
      this.editData = editData;
    }
  }
  closeThereshold() {
    this.addTheresholdModal = false;
  }
}
