import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { LoadingFlyComponent } from './loading-fly/loading-fly.component';
@NgModule({
  imports: [CommonModule],
  declarations: [LoadingComponent, LoadingFlyComponent],
  exports: [LoadingComponent, LoadingFlyComponent]
})
export class CommonComponentsLoadingModule {}
