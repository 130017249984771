import { Component, OnInit, Input } from '@angular/core';
import { NavigationItem } from '@techops-ui/common/data-access/navigation';

@Component({
  selector: 'aa-header-app-item',
  templateUrl: './app-item.component.html',
  styleUrls: ['./app-item.component.css']
})
export class AppItemComponent implements OnInit {
  @Input()
  app: NavigationItem;

  constructor() {}

  ngOnInit() {}
}
