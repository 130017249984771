import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LtermComponent } from './components/lterm/lterm.component';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsLoadingModule } from '@techops-ui/common/components/loading';

@NgModule({
  imports: [CommonComponentsLoadingModule, ReactiveFormsModule, CommonModule, FormsModule, ModalModule.forRoot()],
  exports: [LtermComponent, ModalModule],
  declarations: [LtermComponent],
  providers: [CookieService],
  bootstrap: [LtermComponent]
})
export class CommonLtermUiModule {}
