import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ltermReducer } from './+state/lterm.reducer';
import { LtermEffects } from './+state/lterm.effects';
import { BackendService } from './services/backendservice';
import { HttpService } from '@techops-ui/common/helpers';
import { HttpClientModule } from '@angular/common/http';
import { initialUserLtermState } from './+state/lterm.init';
@NgModule({
  imports: [CommonModule, HttpClientModule, StoreModule.forFeature('lterms', ltermReducer, { initialState: initialUserLtermState }), EffectsModule.forFeature([LtermEffects])],
  providers: [LtermEffects, BackendService, HttpService, HttpClientModule]
})
export class CommonDataAccessLtermModule {}
