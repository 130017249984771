import { LtermActions, LtermActionTypes } from './lterm.actions';
import { initialUserLtermState } from './lterm.init';
import { LtermState } from './lterm.interfaces';

const LOAD_USER_LTERMS = 'Loading Lterms data';
const LOAD_LTERMS_SUCCESS = 'LTERM Data load success';
const LOAD_LTERMS_FAIL = 'LTERM Data load failed';
const LOAD_USER_LTERMS_USERID = 'Loading Lterms data by userid';
const LOAD_LTERMS_SUCCESS_USERID = 'LTERM Data load success by userid';
const LOAD_LTERMS_FAIL_USERID = 'LTERM Data load failed by userid';

export function ltermReducer(state = initialUserLtermState, action: LtermActions): LtermState {
  switch (action.type) {
    case LtermActionTypes.LoadUserLterms:
      return {
        ...state,
        userid: action.payload.userid,
        stationCode: action.payload.station,
        loadState: LOAD_USER_LTERMS,
        isLoading: true,
        errors: null
      };

    case LtermActionTypes.LoadUserLtermsSuccess:
      return {
        ...state,
        lterms: action.payload,
        loadState: LOAD_LTERMS_SUCCESS,
        isLoading: false
      };

    case LtermActionTypes.LoadUserLtermsFail:
      return {
        ...state,
        lterms: [],
        loadState: LOAD_LTERMS_FAIL,
        isLoading: false,
        errors: action.payload
      };

    case LtermActionTypes.LoadUserLtermsByUserId:
      return {
        ...state,
        userid: action.payload.userid,
        loadState: LOAD_USER_LTERMS_USERID,
        isLoading: true,
        errors: null
      };

    case LtermActionTypes.LoadUserLtermsSuccessByUserId:
      return {
        ...state,
        lterms: action.payload,
        defaultStation:
          action.payload[0] == null || action.payload[0].stationCode === 'undefined'
            ? ''
            : action.payload[0].stationCode,
        loadState: LOAD_LTERMS_SUCCESS_USERID,
        isLoading: false
      };

    case LtermActionTypes.LoadUserLtermsFailByUserId:
      return {
        ...state,
        lterms: [],
        loadState: LOAD_LTERMS_FAIL_USERID,
        isLoading: false,
        errors: action.payload
      };
    case LtermActionTypes.SaveUserLterms:
      return {
        ...state,
        stationCode: action.payload.defaultStation,
        defaultStation: action.payload.defaultStation,
        defaultPrinter: action.payload.DefaultPrinter,
        Savedlterms: action.payload.Lterms,
        errors: null
      };
    default:
      return state;
  }
}
