import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { navigationReducer, initialState as navigationInitialState } from './+state/navigation.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('navigation', navigationReducer, { initialState: navigationInitialState })
  ]
})
export class CommonDataAccessNavigationModule {}
