import gql from 'graphql-tag';
export const GET_AIRCRAFT_INFO = gql`
  query GetAircraftInfo($input: AircraftInput!) {
    aircraft(input: $input) {
      noseNumber
      nextFlightNumber
      aircraftDetails {
        fleet {
          fleetName
        }
      }
    }
  }
`;
