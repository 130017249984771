import { Action } from '@ngrx/store';
import { BehaviorSubject, OperatorFunction, Observable } from 'rxjs';
import { SiteminderUser } from './siteminder-authentication.interfaces';

export const dummyUser: SiteminderUser = {
  // tslint:disable-next-line:max-line-length
  sm_access_token:
    '',
  sm_login_url: 'https://smlogin.qtcorpaa.aa.com/login/SMLogin.jsp?TARGET=$SM$https://sm-pcf-ui-demo.apps.depaas.qcorpaa.aa.com',
  sm_roles: ['ADMIN'],
  sm_session_expires: new Date(),
  // tslint:disable-next-line:max-line-length
  sm_logout_url: 'https://smlogin.qtcorpaa.aa.com/login/SMLogout.jsp?originalTarget=https://sm-pcf-ui-demo.apps.depaas.qcorpaa.aa.com',
  lastname: 'DOE',
  email: 'john.doe@aa.com',
  sm_session_timeout: 180,
  firstname: 'JOHN',
  sm_keep_alive_interval: 140,
  user: '00123456',
  location: 'PHX',
  sm_idle_threshold: 150,
  sm_is_idle: false,
  sm_seconds_to_timeout: -1,
  sm_timed_out: false,
  sm_session: 'SMSESSION'
};

export class MockStore<T> {
  private state: BehaviorSubject<T> = new BehaviorSubject(undefined);

  setState(data: T) {
    this.state.next(data);
  }

  pipe<A>(operations: OperatorFunction<T, A>): Observable<A> {
    return this.state.pipe(operations);
  }

  dispatch(_: Action) {}
}
