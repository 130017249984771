/* tslint:disable:angular-7 */
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SiteminderAuthenticationEffects } from './+state/siteminder-authentication.effects';
import * as fromSiteminder from './+state/siteminder-authentication.reducer';
import { HasRoleDirective } from './has-role.directive';
import { ENABLE_WEB_AGENT, LOCATION_TOKEN, SiteminderAgentConfig, SM_AGENT_CONFIG, _SM_AGENT_CONFIG } from './siteminder-authentication-config';
import { SiteminderAuthenticationInterceptorService } from './siteminder-authentication-interceptor.service';
import { SiteminderAuthenticationService } from './siteminder-authentication.service';
import { SiteminderSessionTimeoutWarningDialogComponent } from './siteminder-session-timeout-warning-dialog/siteminder-session-timeout-warning-dialog.component';
export function getLocation() {
  return window.location;
}

export function getConfiguration(config: SiteminderAgentConfig): SiteminderAgentConfig {
  const DEFAULT_CONFIG: SiteminderAgentConfig = {
    url: '/api/sm',
    autoRedirect: true,
    keepAlive: true,
    includeTransactionId: false,
    includeClientId: false,
    app_id: '',
    enableWebAgent: false,
    includeSMSession: false
  };
  if (config.includeClientId === true && !config.app_id) {
    throw new Error('"app_id" is required when "includeClientId" is set to "true" ');
  }
  return {
    ...DEFAULT_CONFIG,
    ...config
  };
}

export function appInitializerFn(config: SiteminderAgentConfig, authService: SiteminderAuthenticationService) {
  const fn = () =>
    authService
      .loadUser()
      .toPromise()
      .then(user => {
        if (user[ENABLE_WEB_AGENT] !== 'NO') {
          authService.setupIdleTimer(user);
        } else {
          console.warn('Siteminder Agent is disabled on server.');
        }
      })
      .catch(_ => {
        console.error('Application failed to initialize. Please contact Administrator.');
        if (config.enableWebAgent === true && !config.url.startsWith('/')) {
          console.error('Please make sure to start local siteminder agent when running locally.');
          console.group('');
          console.error('To start local siteminder agent, run batch file "/sm_agent/start-sm-agent-local.bat".');
          console.groupEnd();
        }
      });
  return fn;
}

/**
 * Setup Siteminder Authentication module for the application
 * Usage:
 *
 * ```typescript
 * @NgModule({
 *   declarations: [AppComponent],
 *   imports: [
 *     BrowserModule,
 *     BrowserAnimationsModule,
 *     AppRoutingModule,
 *     NxModule.forRoot(),
 *     StoreModule.forRoot({}),
 *     EffectsModule.forRoot([AppEffects]),
 *     ToastrModule.forRoot({ timeOut: 10000, preventDuplicates: true }),
 *     environment.production
 *     ? SiteminderAuthenticationModule.forRoot()
 *     : SiteminderAuthenticationModule.forRoot({ url: 'http://localhost:3000/api/sm/FlightWeb', autoRedirect: false })
 *   ],
 *   bootstrap: [AppComponent]
 * })
 * export class AppModule {
 * }
 * ```
 * Notes:
 *
 * For development purpose, run the [SMAgent.Node](https://ghe.aa.com/00864385/sm-pcf/tree/master/SMAgent.Node)
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    DialogModule,
    ButtonModule,
    NgIdleKeepaliveModule.forRoot(),
    StoreModule.forFeature(fromSiteminder.SITEMINDER_AUTHENTICATION_FEATURE, {
      user: fromSiteminder.reducer
    }),
    EffectsModule.forFeature([SiteminderAuthenticationEffects])
  ],
  declarations: [SiteminderSessionTimeoutWarningDialogComponent, HasRoleDirective],
  exports: [SiteminderSessionTimeoutWarningDialogComponent, HasRoleDirective],
  providers: []
})
export class SiteminderAuthenticationModule {
  public static forRoot(config: SiteminderAgentConfig = {}): ModuleWithProviders {
    return {
      ngModule: SiteminderAuthenticationModule,
      providers: [
        {
          provide: _SM_AGENT_CONFIG,
          useValue: config
        },
        {
          provide: SM_AGENT_CONFIG,
          useFactory: getConfiguration,
          deps: [_SM_AGENT_CONFIG]
        },
        { provide: LOCATION_TOKEN, useFactory: getLocation },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SiteminderAuthenticationInterceptorService,
          multi: true
        },
        SiteminderAuthenticationService,
        config.enableWebAgent === true
          ? {
              provide: APP_INITIALIZER,
              useFactory: appInitializerFn,
              multi: true,
              deps: [SM_AGENT_CONFIG, SiteminderAuthenticationService]
            }
          : []
      ]
    };
  }
}
