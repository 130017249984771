import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService,AuthService,IFeedBack ,PermissionOSP,RolePermission } from '@techops-ui/osp/data-access-root';
import { map } from 'rxjs/operators';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { SortDescriptor } from '@progress/kendo-data-query';
import { process, State } from '@progress/kendo-data-query';

import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
@Component({
  selector: 'techops-ui-view-feedback',
  templateUrl: './view-feedback.component.html',
  styleUrls: ['./view-feedback.component.css']
})
export class ViewFeedbackComponent implements OnInit {
  @ViewChildren(GridComponent) private grids: QueryList<GridComponent>;
  feedbackForm: FormGroup;
  gridData: any = [];
  addFeedbackFormModal = false;
  submitted = false;
  employee:any;
  errorMessage: string;
  errordialogeopend = false;
  isLoading = false;
  public filterOpened = false;
  public allowUnsort = false;
  public gridView: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public state: State = {
    skip: 0,
    take: 10,
    sort: []
  };
  public sort: SortDescriptor[] = [
    
  ];
  response: string = '';
  isResSubmited = false;
  roleAccess: PermissionOSP = RolePermission.Default;
  constructor(private formBuilder: FormBuilder,private authServices:AuthService,  public datepipe: DatePipe,private customAuth:AuthService,private backendservice: BackendService,private authService: PingAuthenticationService) {}

  ngOnInit() {
    this.roleAccess = this.authServices.checkRoleAccess();
    this.initLoadReport();
    this.feedbackForm = this.formBuilder.group({
      aircraft: ['', Validators.required],
      station: ['', Validators.required],
      flight: ['', Validators.required],
      totalShipTime: ['', Validators.required],
      oilAddServicedBy: ['', Validators.required],
      dateAddedLocal: ['', Validators.required],
      timesAddedLocal: ['', Validators.required],
      engine1: [''],
      engine2: [''],
     apu: [''],
      
      
      feedbackComments: [''],
    
    });
   
    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      this.employee = data;
     
    });
   const decodedToken = this.customAuth.getDecodedAccessToken();
   console.log(decodedToken?decodedToken.amrapposp:"");
   console.log("AccessToken"+this.authService.AccessToken);
  }
  closeFeedbackform() {
    
    this.addFeedbackFormModal = false;
  }
  noSpecialChars(event: KeyboardEvent) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || (k >= 48 && k <= 57)) {
      return;
    }
    e.preventDefault();
  }

  addResponse(feedbackData:any){
    this.isResSubmited = true;
   if(!this.response){
      return;
   }
   feedbackData.response = `${feedbackData.response} <br> <br> ${this.response} - ${this.datepipe.transform(new Date(), 'MM/dd/yyyy h:mm a')}`
   this.backendservice.addResponse(feedbackData).subscribe(
    data => {
      if (data) {
       
        this.errorMessage = 'Response saved successfully';
        
       
        this.isResSubmited = false;
        this.errordialogeopend = true;
       this.response= "";
        
      }
      //alert("saved"+result)
    },
    (error: any) => {
      console.log(error);
    }
  );
  }
  resetForm(){
    this.submitted = false;
    this.feedbackForm.reset();
  }
  saveFeedBackDetails(){
     this.submitted = true;

     if (this.feedbackForm.invalid) {
       return;
     }
    const feedBackDetails = this.feedbackForm.value;
    const feedBackData = {
      "id": 0,
      "comment": feedBackDetails.feedbackComments,
      "response": '',
      "createdBy":feedBackDetails.oilAddServicedBy,
      "modifiedBy": (this.employee&& this.employee.uid) ? `${Number(this.employee.uid)}`:null,
       "createdDate": new Date().toISOString(),
       "modifiedDate" :new Date().toISOString(),
      "aircraft":feedBackDetails.aircraft ? feedBackDetails.aircraft.toUpperCase() :'', 
      "flight": feedBackDetails.flight,
      "station":feedBackDetails.station,
      "totalShipTime": Number(feedBackDetails.totalShipTime),
      "eng1": feedBackDetails.engine1 ? "Y" : "N",
      "eng2": feedBackDetails.engine2 ? "Y" : "N",
      "apu": feedBackDetails.apu ? "Y" : "N",
      "oilAddId": 17
    }
   
    this.backendservice.postFeedback(feedBackData).subscribe(
      data => {
        if (data) {
         
          this.errorMessage = 'Feedback saved successfully';
          
         this.addFeedbackFormModal = false;
          
          this.errordialogeopend = true;
          this.feedbackForm.reset();
          this.submitted = false;
          this.initLoadReport();
        }
        //alert("saved"+result)
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
 openFeedbackform() {
    this.addFeedbackFormModal = true;
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.gridData, this.state);
  }
  private initLoadReport(): void {
   
     this.isLoading = true;
     this.backendservice.getFeedBackList().subscribe(
       (result: IFeedBack[]) => {
         this.gridData = result
         this.gridData = this.gridData.map((mapresult: any) => {
          mapresult.isExpanded = false;
          return { ...mapresult };
        });
        this.gridView = process(this.gridData, this.state);
        this.expandGrid();
         this.isLoading = false;
       },
       (error: any) => {
         console.log(error);
         this.isLoading = false;
       }
     );
    // this.loadData();

  }


  closeErrorDialog(){
    this.errordialogeopend = false;
   
  }
  expandAndCollapse(index: number, isExpand: boolean) {
    if (isExpand) {
      this.gridData[index].isExpanded = true;
    } else {
      this.gridData[index].isExpanded = false;
    }
  }


  expandGrid() {
    setTimeout(() => {
      (this.gridData || []).forEach((_result: any, index: number) => {
        this.grids.toArray()[0].expandRow(index);
      });
    }, 0);
  }
}
