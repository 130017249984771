import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteminderUser } from '@techops-ui/common/siteminder-authentication';
import { BackendService } from '@techops-ui/osp/data-access-root';

interface ClassificationRecords {
  text: string;
  value: number;
}

@Component({
  selector: 'techops-ui-edit-admin-record',
  templateUrl: './edit-admin-record.component.html',
  styleUrls: ['./edit-admin-record.component.css']
})
export class EditAdminRecordComponent implements OnInit {
  editAdminRecordForm: FormGroup;
  formGroup: FormGroup;
  @Input() isFormEdit: boolean;
  @Input() editData: any;
  @Output() createEvent = new EventEmitter<boolean>();
  submitted: boolean;
  public classificationList: Array<ClassificationRecords> = [
    { text: '0', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 }
  ];
  public opened = true;
  public user: SiteminderUser;
  public selectedValue = 0;
  public onAccept() {
    this.submitted = true;
    if (this.editAdminRecordForm.invalid) {
      return;
    }
    const formData = this.editAdminRecordForm.value;
    // const getFormRawValues = this.editAdminRecordForm.getRawValue();
    formData.stationID = Number(this.editData.stationID);
    formData.airline = formData.airline;
    formData.stationCode = formData.stationCode;
    formData.alertMessage = this.editData.alertMessage;
    formData.timeZoneOffset = Number(formData.timeZoneOffset);
    formData.classification = Number(this.selectedValue);
    formData.region = this.editData.region;
    formData.manningStyle = this.editData.manningStyle;
    formData.defaultPage = this.editData.defaultPage;
    formData.stnName = this.editData.stnName;

    if (!this.isFormEdit) {
      this.backendservice.updateAdminRecord(formData, this.editData.stationID).subscribe(
        data => {
          if (data) {
            alert('Admin Record update successful');
            this.createEvent.emit();
            this.opened = false;
            this.submitted = true;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      alert('No changes.');
    }
  }
  public close() {
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }

  constructor(private formBuilder: FormBuilder, private backendservice: BackendService) {}

  ngOnInit() {
    this.editAdminRecordForm = this.formBuilder.group({
      airline: ['', Validators.required],
      stationCode: ['', Validators.required],
      timeZoneOffset: ['', Validators.required],
      region: ['', Validators.required],
      classification: ['', Validators.required]
    });

    if (!this.isFormEdit) {
      this.updateFormValues();
    } else {
      this.editAdminRecordForm.reset();
      this.submitted = false;
    }
  }
  updateFormValues() {
    const adminEdit = this.editData;

    this.editAdminRecordForm.patchValue({
      airline: adminEdit.airline,
      stationCode: adminEdit.stationCode,
      timeZoneOffset: adminEdit.timeZoneOffset,
      region: adminEdit.region,
      classification: adminEdit.classification,

      stationID: adminEdit.stationID,
      alertMessage: adminEdit.alertMessage,
      autoCloseOvernight: adminEdit.autoCloseOvernight,
      autoGenerateTasks: adminEdit.autoGenerateTasks,
      autoCloseOnTime: adminEdit.autoCloseOnTime,
      manningStyle: adminEdit.manningStyle,
      defaultPage: adminEdit.defaultPage,
      stnName: adminEdit.stnName,
      staffed: adminEdit.staffed
    });
    this.selectedValue = adminEdit.classification;
  }
}
