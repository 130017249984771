import { Injectable } from '@angular/core';
import { UserRoles, RolePermission } from './auth.roles';
// import { SiteminderUser } from '@techops-ui/common/siteminder-authentication';
// import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// import { PingAuthenticationService } from '@techops-ui/ping-authentication';
// import { map } from 'rxjs/operators';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import jwt_decode from 'jwt-decode';


@Injectable()
export class AuthService {
  public subscriptionData: Subscription;
  public user: any = {
    amrapposp:  [],
    firstname: '',
    lastname: '',
    user: '',
    sm_roles: [],
    email: '',
    location: '',
    SM_USER:'',
    ENABLE_WEB_AGENT: false
  };
  userDetails$ = new BehaviorSubject(null);

  constructor(private authService: PingAuthenticationService) {




    this.userDetails$.subscribe((res) => {
      if (res) {
      //  res.sm_roles =['Admin'];
       res.sm_roles = (res.amrapposp && Array.isArray(res.amrapposp)) ? res.amrapposp : [res.amrapposp] ;
        this.user = res;
        console.log(this.user)
      }
    })

  }

  getDecodedAccessToken(): any {
    try {
      const tempToken = this.authService.AccessToken;
     
      return jwt_decode(tempToken);
    } catch (Error) {
      return null;
    }
  }
  getRoleAccess(roles: string): any {
    switch (roles) {
     
      case UserRoles.OSP_ENGINEERING: {
        return RolePermission.OSPEngPermission;
      }
      case UserRoles.MOC_TECHNICIANS: {
        return RolePermission.MOCTechPermission;
      }
      case UserRoles.OSP_ADMIN: {
        return RolePermission.OSPAdmin;
      }
      case UserRoles.POS_ADMIN: {
        return RolePermission.AdminPOS;
      }
    
    }
  }

  addCommonRoles(json: any, myObject: any) {
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        if (json[key]) {
          myObject[key] = true;
        }
      }
    }
    return myObject;
  }
  get sessionUserInfo() {
  //return ['Admin'];
    const userSessionData = this.getDecodedAccessToken();
     
    if (userSessionData && userSessionData.amrapposp) {
      return  (userSessionData.amrapposp && Array.isArray(userSessionData.amrapposp)) ? userSessionData.amrapposp : [userSessionData.amrapposp];
    } else {
      return [];
    }

  }
  getUserRoles() {
    const userRole: any = this.sessionUserInfo || [];
    return this.user['sm_roles'].length ? this.user['sm_roles'] : userRole;
  }

  checkRoleAccess() {
    let defaultRolesDetails = RolePermission.Default;
    const userRole: any = this.sessionUserInfo || [];
    const userRoles = this.user['sm_roles'].length ? this.user['sm_roles'] : userRole;
    for (let i = 0; i < userRoles.length; i++) {
      const roles = this.getRoleAccess(userRoles[i]);
      defaultRolesDetails = this.addCommonRoles(roles, defaultRolesDetails);
    }

    return defaultRolesDetails;
  }
}
