import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OSPAppEnvironment } from '@techops-ui/osp/shared';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IThresholdReport,GetPosThresholds, IEngineDetails, IAdminReport,PredicitveDataCount, ThresholdReport, IAircraftReport, IFleet, IOilAdd,INotification, ISafeOspAircraft,IFeedBack, } from '../models/api-osp-report-data.model';

@Injectable()
export class BackendService {
  private url = this.environment ? this.environment.ospeventsApi : '';
  // private url = 'https://ospapi.aa.com/api';
  constructor(@Inject('environment') private environment: OSPAppEnvironment, private http: HttpClient) {}

  getAdminReport(): Observable<IAdminReport[]> {
    console.log(this.environment.ospeventsApi);
    return this.get(this.url + '/Admin');
  }
  getPosoutputReport(page:number,pageSize:number): Observable<PredicitveDataCount> {
    
    return this.get(this.url + `/PosAdmin/GetPosOutputScreen?page=${page}&pageSize=${pageSize}`)
  }

  getGetPosThresholds(): Observable<GetPosThresholds[]> {
    
    return this.get(this.url + `/PosAdmin/GetPosThresholds`)
  }
  filterPosoutputReport(page:number,pageSize:number,filter:any): Observable<PredicitveDataCount> {
    
    return this.putCustom(this.url + `/PosAdmin/GetPosOutputFilter?page=${page}&pageSize=${pageSize}`,filter)
  }

  exportRecords(filter:any): Observable<Blob> {
    
    return this.fileDownload(this.url + `/PosAdmin/GetPosOutputFiltertoExcel`,filter)
  }
  getFeedBackList(): Observable<IFeedBack[]> {
    
    return this.get(this.url + '/Feedback');
  }
  getThresholdList(): Observable<IThresholdReport[]> {
    return this.get(this.url + '/Threshold');
  }
  getOilAddList(): Observable<IOilAdd[]> {
    return this.get(this.url + '/OilAdd');
  }
  getDeletedOilAddList(): Observable<IOilAdd[]> {
    return this.get(this.url + '/OilAdd/GetOnlyDeletedOilAdd');
  }

  overRideEngDetails(overRideDetails: any) {
    return this.put(this.url + `/OilAdd/OverrideOilAdd/` + overRideDetails.id, overRideDetails);
  }
  permananteDeleteOilAdd(id: number): Observable<string> {
    return this.delete(this.url + '/OilAdd/permanentDeleteOilAdd/' + id);
  }
  deleteOilAdd(id: number): Observable<string> {
    return this.put(this.url + '/OilAdd/DeleteOilAdd/' + id, {});
  }
  deleteTheresholdValue(id: number): Observable<string> {
    return this.delete(this.url + '/Threshold?thresholdId=' + id);
  }
  getOilAddListByApplyFilter(filterdata: any): Observable<IOilAdd[]> {
    return this.putCustom(this.url + '/OilAdd/GetOilAddFilter', filterdata);
  }
  getEngineDetails(type: string, acNumber: string): Observable<IEngineDetails[]> {
    return this.get(this.url + `/SafeOsp/${type}/${acNumber}`);
  }
  getAircraftDetails(acNumber: string, carrier: string): Observable<IEngineDetails[]> {
    return this.get(this.url + `/SafeOsp/GetAircraftDetails/${acNumber}/${carrier}`);
  }
  getAircraftDetailsForURL(acNumber: string, carrier: string,cpnDetails:string,isFromGQL:boolean): Observable<IEngineDetails[]> {
    if(isFromGQL){
      return this.get(this.url + `/SafeOsp/GetAircraftDetails/${acNumber}/${carrier}?cpnandparttype=${cpnDetails}`);
    }else{
      return this.get(this.url + `/SafeOsp/GetAircraftDetails/${acNumber}/${carrier}`);
    }
    
  }
  createOildAddEvent(formData: any): Observable<IEngineDetails[]> {
    return this.postCustom(this.url + `/SafeOsp/PostEvent`, formData);
  }
  getSafeAircraftDetails(acNumber: string, career: string): Observable<ISafeOspAircraft[]> {
    return this.get(this.url + `/SafeOsp/GetAircraftDetails/${acNumber}/${career}`);
  }
  getAircraftList(): Observable<IAircraftReport[]> {
    return this.get(this.url + '/Aircraft');
  }
  getNotificationList(): Observable<INotification[]> {
    const url = this.url.replace("/api","");
    return this.get(url + '/Notification/GetAllOSPMailBoxes');

    
  }

  postNotification(request: INotification): Observable<string> {
    const url = this.url.replace("/api","");
    return this.post(url + '/Notification/AddOspFleetMailBox', request);
   
  }
  putNotification(request: INotification): Observable<string> {
    const url = this.url.replace("/api","");
    return this.put(url + '/Notification?id='+request.id, request);
 
  }
  updateNotification(request: INotification, id: string): Observable<INotification[]> {
    return this.put(this.url + '/Aircraft?acid=' + id, request);
  }
  getFleetList(): Observable<IFleet[]> {
    return this.get(this.url + '/Aircraft/GetFleet');
  }

  putCustom(url: string, body: any): Observable<any> {
    return this.http.put(url, body).pipe(map(r => r));
  }
  fileDownload(url: string, body: any): Observable<any> {
    return this.http.put(url, body,{
      responseType: 'blob'
    }).pipe(map(r => r));
  }
  postCustom(url: string, body: any): Observable<any> {
    return this.http.post(url, body).pipe(map(r => r));
  }
  put(url: string, body: any): Observable<any> {
    return this.http
      .put(url, body, {
        responseType: 'text'
      })
      .pipe(map(r => r));
  }
  delete(url: string): Observable<any> {
    return this.http
      .delete(url, {
        responseType: 'text'
      })
      .pipe(map(r => r));
  }
  post(url: string, body: any): Observable<any> {
    return this.http
      .post(url, body, {
        responseType: 'text'
      })
      .pipe(map(r => r));
  }
  get(url: string): Observable<any> {
    return this.http.get(url).pipe(map(r => r));
  }

 
  postThresholdList(request: ThresholdReport): Observable<ThresholdReport[]> {
    return this.post(this.url + '/Threshold/PostEvent', request);
  }
  updateThresholdList(request: ThresholdReport, id: string): Observable<ThresholdReport[]> {
    return this.put(this.url + '/Threshold?id=' + id, request);
  }
  updateAdminRecord(request: IAdminReport, id: string): Observable<IAdminReport[]> {
    return this.put(this.url + '/Admin?stationId=' + id, request);
  }
  postAircraftList(request: IAircraftReport): Observable<IAircraftReport[]> {
    return this.post(this.url + '/Aircraft/PostEvent', request);
  }
  updateAircraftRecord(request: IAircraftReport, id: string): Observable<IAircraftReport[]> {
    return this.put(this.url + '/Aircraft?acid=' + id, request);
  }

  updatePosThreshold(request: any, id: string) {
    return this.put(this.url +  '/PosAdmin/UpdatePosThreshold?id='+id, request);
  }
  addPosThreshold(request: any) {
    return this.post(this.url + '/PosAdmin/addPosThreshold', request);
  }
  editOilAdd(request: IOilAdd, id: string): Observable<IOilAdd[]> {
    return this.putCustom(this.url + '/OilAdd?oilAddId=' + id, request);
  }
  postOilAdd(request: IOilAdd): Observable<IOilAdd> {
    return this.postCustom(this.url + '/OilAdd/PostEvent', request);
  }
  postFeedback(request:any) {
    return this.post(this.url + '/Feedback/PostEvent', request);
  }
  addResponse(request:any) {
    return this.put(this.url + '/Feedback?id='+request.id, request);
  }
}
