import { NavigationActions, NavigationActionTypes } from './navigation.actions';

/**
 * Interface for the 'Navigation' data used in
 *  - NavigationState, and
 *  - navigationReducer
 */
export interface Navigation {
  NavItems: NavigationItem[];
  HelpItems: NavigationItem[];
  AccountItems: NavigationItem[];
  Version: string;
  Opened: boolean;
}

export interface NavigationItem {
  Text: string;
  Icon: string;
  Root: string;
  RouterLink: string;
  Path: string;
  Collapse: boolean;
  Visible: boolean;
  SubNav: SubNavItems[];
  Action?: Function;
  Target?: string;
}

interface SubNavItems {
  GroupName: string;
  Items: SubNavItem[];
}

interface SubNavItem {
  Text: string;
  Icon: string;
  Root: string;
  RouterLink: string;
  Path: string;
  Visible: boolean;
  Target?: string;
}

/**
 * Interface to the part of the Store containing NavigationState
 * and other information related to NavigationData.
 */
export interface NavigationState {
  readonly navigation: Navigation;
}

export const initialState: Navigation = {
  NavItems: [],
  HelpItems: [],
  AccountItems: [],
  Version: '',
  Opened: null
};

export function navigationReducer(state = initialState, action: NavigationActions): Navigation {
  switch (action.type) {
    case NavigationActionTypes.LoadNavigation: {
      return { ...state, ...action.payload };
    }
    case NavigationActionTypes.ToggleSideMenu: {
      return { ...state, Opened: action.payload };
    }
    default:
      return state;
  }
}
