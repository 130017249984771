import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BackendService, ISafeOspAircraft } from '@techops-ui/osp/data-access-root';
import { Observable } from 'rxjs';
import { CommonTFPGatewayService } from '@techops-ui/common/data-access/tfp-gateway';
import { EngineComponentStatus, EngineDetails } from '../models/component-status';
import { GET_ENGINE_INFO } from '../queries/engine.query';
import { GET_AIRCRAFT_INFO } from '../queries/aircraft.query';
import { ApolloQueryResult } from 'apollo-client';
import { map } from 'rxjs/operators';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Component({
  selector: 'techops-ui-view-oiladd-screen',
  templateUrl: './view-oiladd-screen.component.html',
  styleUrls: ['./view-oiladd-screen.component.css']
})
export class ViewOiladdScreenComponent implements OnInit {
  oilAddForm: FormGroup;
  formGroup: FormGroup;
  errordialogeopend = false;
  apierrordialogeopend=false;
  fleetName = '';
  resonList = [
    'ACARS issue - aircraft RGA screen ON time not available',
    'AMT Internet access unavailable at aircraft',
    'Logbook audit identification missing oil add',
    'Oil add made on behalf of contract maintenance personnel(Non-AA Employee)',
    'Station Internet access unavailable',
    'APU Hour Meter Changed',
    'Other'
  ];
  isShowOSPAdmin = false;
  submitted = false;
  scrollContainer: any;
  viewCreatedRecord: any;
  constructor(
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private backendservice: BackendService,
    private gatewayService: CommonTFPGatewayService,
    private authService: PingAuthenticationService
  ) {}
  @Output() createEvent = new EventEmitter<any>();
  @Input() isFormCreate: boolean;
  @Input() editData: any;
  public noseNumber: string;
  // @Input() ospData: any;
  // @Input() rgnData: any;
  // @Input() gqlData: any;
  @Input() isFormEdit: boolean;
  enginequery: any;
  aircraftquery: any;
  public opened = true;
  safeospaircraft: any;
  showEngine: boolean;
  showLoading: boolean;
  enginesList: any[];
  currentDate = new Date();
  isLoading = false;
  errorMessage = '';
  employee: any;
  viewOilAddRecord: any;
  isValidDate = false;
  isValidTime = false;

     onDateChange(){
   const formData = this.oilAddForm.value;
   if(!formData.dateAddedLocal){
   this.isValidDate = false;
  }

    const todayTime = this.currentDate.setHours(0,0,0,0);
    this.isValidDate = new Date(formData.dateAddedLocal) > new Date(todayTime)
  }

   onTimeChange(){
  const formData = this.oilAddForm.value;
  if(!formData.timesAddedLocal){
  this.isValidTime = false;
  }

    const todayDate =new Date();
    const todayTime = this.currentDate.setHours(0,0,0,0);
    if(new Date(formData.dateAddedLocal) > new Date(todayTime)){
      this.isValidTime= true;
    }

   this.isValidTime = (new Date(formData.dateAddedLocal) >= new Date(todayTime) && new Date(formData.timesAddedLocal) >= todayDate);
  }

  public onAccept() {
    this.submitted = true;

    if (this.oilAddForm.invalid) {
      return;
    }
    const formData = this.oilAddForm.value;

    if (formData.engine1ServiceBy === '' && formData.engine2ServiceBy === '' && formData.apuServiceBy === '') {
      alert('Please enter atleast one engine');
      return;
    }
   

    let eng1Details;
    let eng2Details;
    let apuDetails;
    //  const GQLEngDetails = this.enginesList;
    //  eng1Details = GQLEngDetails && GQLEngDetails[0].componentStatus && GQLEngDetails[0].componentStatus[0] ? GQLEngDetails[0].componentStatus[0] : '';
    //  eng2Details = GQLEngDetails && GQLEngDetails[1].componentStatus && GQLEngDetails[1].componentStatus[0] ? GQLEngDetails[1].componentStatus[0] : '';
    //  apuDetails = GQLEngDetails && GQLEngDetails[2].componentStatus && GQLEngDetails[2].componentStatus[0] ? GQLEngDetails[2].componentStatus[0] : '';
    if (this.isFormCreate) {
      const GQLEngDetails = this.enginesList;
      eng1Details = GQLEngDetails && GQLEngDetails[0] && GQLEngDetails[0].componentStatus && GQLEngDetails[0].componentStatus[0] ? GQLEngDetails[0].componentStatus[0] : {};
      eng2Details = GQLEngDetails && GQLEngDetails[1] && GQLEngDetails[1].componentStatus && GQLEngDetails[1].componentStatus[0] ? GQLEngDetails[1].componentStatus[0] : {};
      apuDetails = GQLEngDetails && GQLEngDetails[2] && GQLEngDetails[2].componentStatus && GQLEngDetails[2].componentStatus[0] ? GQLEngDetails[2].componentStatus[0] : {};
    } else {
      const GQLEngDetails = this.enginesList;
      eng1Details = GQLEngDetails && GQLEngDetails[0] && GQLEngDetails[0].componentStatus && GQLEngDetails[0].componentStatus[0] ? GQLEngDetails[0].componentStatus[0] : {};
      eng2Details = GQLEngDetails && GQLEngDetails[1] && GQLEngDetails[1].componentStatus && GQLEngDetails[1].componentStatus[0] ? GQLEngDetails[1].componentStatus[0] : {};
      apuDetails = GQLEngDetails && GQLEngDetails[2] && GQLEngDetails[2].componentStatus && GQLEngDetails[2].componentStatus[0] ? GQLEngDetails[2].componentStatus[0] : {};
   
      // const oilData = this.editData.viewOSPDetails;
      // eng1Details = {
      //   serialNumber: oilData.engine1.serialNumber,
      //   partNumber:  (oilData.engine1 && oilData.engine1.cpn) ? oilData.engine1.cpn : ''
      // };
      // eng2Details = {
      //   serialNumber: oilData.engine2 ? oilData.engine2.serialNumber:0,
      //   partNumber: (oilData.engine2 && oilData.engine2.cpn) ? oilData.engine2.cpn : ''
      // };
      // apuDetails = {
      //   serialNumber: oilData.apu ? oilData.apu.serialNumber : 0,
      //   partNumber: oilData.apu && oilData.apu.cpn ? oilData.apu.cpn : ''
      // };
    }
  
  if(formData.engine1ServiceBy){
   if(!eng1Details.serialNumber || !eng1Details.partNumber){
    alert('For engine 1 Serial Number and CPN number is missing');
    return;
   }
  }
  if(formData.engine2ServiceBy){
    if(!eng2Details.serialNumber || !eng2Details.partNumber){
     alert('For engine 2 Serial Number and CPN number is missing');
     return;
    }
   }
   if(formData.apuServiceBy){
    if(!apuDetails.serialNumber || !apuDetails.partNumber){
     alert('For APU Serial Number and CPN number is missing');
     return;
    }
   }
   this.isLoading = true;
    const finalOildAddInput: any = {
      aircraft: formData.aircraft,
      carrier: 'US',
      authcode: '',
      station: formData.station,
      flight: formData.flight,
      createdBy: this.employee ?  Number(this.employee.uid) : '',
      dateAddedLocal: this.datepipe.transform(formData.dateAddedLocal, 'yyyy-MM-dd'),
      timeAddedLocal: this.datepipe.transform(formData.timesAddedLocal, 'HH:mm'),
      dateAddedUTC: this.datepipe.transform(formData.dateAddedLocal, 'yyyy-MM-dd'),
      engine1: formData.engine1ServiceBy ?  {
        pintsAdded: Number(formData.engine1PintsAdded),
        servicedByID: formData.engine1ServiceBy,
        servicedByName: this.employee ?  `${this.employee.firstname} ${this.employee.last_name}` : '',
        serialNumber: this.isFormCreate ? eng1Details.mfgSerialNumber : eng1Details.mfgSerialNumber,
        cpn: eng1Details.partNumber
      }: null,
      engine2:formData.engine2ServiceBy? {
        pintsAdded: Number(formData.engine2PintsAdded),
        servicedByID: formData.engine2ServiceBy,
        servicedByName: this.employee ? `${this.employee.firstname} ${this.employee.last_name}` : '',
        serialNumber: this.isFormCreate ? eng2Details.mfgSerialNumber : eng2Details.mfgSerialNumber,
        cpn: eng2Details.partNumber
      }:null,
      apu: formData.apuServiceBy ? {
        pintsAdded: Number(formData.apuPintsAdded),
        servicedByID: formData.apuServiceBy,
        servicedByName: this.employee ? `${this.employee.firstname} ${this.employee.last_name}` : '',
        serialNumber: this.isFormCreate ? apuDetails.mfgSerialNumber : apuDetails.mfgSerialNumber,
        cpn: apuDetails.partNumber
      }:null,
      totalShipTime: formData.totalShipTime ? Number(formData.totalShipTime) : '',
      apuHours: formData.apu ? Number(formData.apu) : formData.apu,
      comments: formData.additionalComments,
      apuSerialNumber: '',
      finalHourMeterReading: 0,
      isSafeApp: false,
      editedDateAddedLocal: '1900-01-01',
      hourMeterReplacedDate: formData.meterReplaceDate ? this.datepipe.transform(formData.meterReplaceDate, 'yyyy-MM-dd') : '',
      oldHourMeter: formData.oldHourMeter ? Number(formData.oldHourMeter) : 0,
      newHourMeter: formData.newHourMeter ? Number(formData.newHourMeter) : 0,
      reason: formData.reason,
      sendMail: true,
      fleet: this.fleetName
    };

    if (!this.isFormCreate) {
      // alert('This is Edit Save')
     
     const editData = this.editData.viewOSPDetails;
     const patchEditData = {
       ...finalOildAddInput,
       authcode:editData.authorizationCode,
       engine1: formData.engine1ServiceBy ?  {
         ...editData.engine1,
         pintsAdded: Number(formData.engine1PintsAdded),
         servicedByID: formData.engine1ServiceBy,
         servicedByName: this.employee ?  `${this.employee.firstname} ${this.employee.last_name}` : '',
         serialNumber: this.isFormCreate ? eng1Details.mfgSerialNumber : eng1Details.mfgSerialNumber,
         cpn: eng1Details.partNumber ,
         
       }: null,
       engine2:formData.engine2ServiceBy? {
         ...editData.engine2,
         pintsAdded: Number(formData.engine2PintsAdded),
         servicedByID: formData.engine2ServiceBy,
         servicedByName: this.employee ? `${this.employee.firstname} ${this.employee.last_name}` : '',
         serialNumber: this.isFormCreate ? eng2Details.mfgSerialNumber : eng2Details.mfgSerialNumber,
         cpn: eng2Details.partNumber,
        
       }:null,
       apu: formData.apuServiceBy ? {
         ...editData.cpn,
         pintsAdded: Number(formData.apuPintsAdded),
         servicedByID: formData.apuServiceBy,
         servicedByName: this.employee ? `${this.employee.firstname} ${this.employee.last_name}` : '',
         serialNumber: this.isFormCreate ? apuDetails.mfgSerialNumber : apuDetails.mfgSerialNumber,
         cpn: apuDetails.partNumber,
        
       }:null,
     };
      this.backendservice.editOilAdd(patchEditData, this.editData.viewOSPDetails.id).subscribe(
        data => {
          if (data) {
            //  alert('Threshold update successfully');
            this.viewOilAddRecord = data;
            this.isLoading = false;
            this.errorMessage = 'Oiladd updated successfully';
            this.errordialogeopend = true;
           // this.closeErrorDialog();
          }
        },
        (error: any) => {
          console.log(error);
          this.errorMessage = error.message;
          this.openAPIErrorDialog();
          this.isLoading = false;
        }
      );
    } else {
      this.backendservice.postOilAdd(finalOildAddInput).subscribe(
        data => {
          if (data) {
            this.isLoading = false;
            this.viewOilAddRecord = data;
            this.errorMessage = 'Oiladd created successfully';
            // this.errordialogeopend = true;
            this.closeErrorDialog();
          }
          //alert("saved"+result)
        },
        (error: any) => {
          this.errorMessage = error.message;
          this.openAPIErrorDialog();
          this.isLoading = false;
        }
      );
    }
  }

  ngOnInit() {
    //  const regexPatternPintsAdded = /^[+]?([1-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    //  const regexPatternAPUHours = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;

    this.oilAddForm = this.formBuilder.group({
      aircraft: ['', Validators.required],
      station: ['', Validators.required],
      flight: ['', Validators.required],
      totalShipTime: ['', Validators.required],
      dateAddedLocal: ['', Validators.required],
      timesAddedLocal: ['', Validators.required],
      engine1ServiceBy: [''],
      // engine1PintsAdded: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(regexPatternPintsAdded)]],
      engine1PintsAdded: ['', [Validators.maxLength(30)]],
      engine2ServiceBy: [''],
      // engine2PintsAdded: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(regexPatternPintsAdded)]],
      engine2PintsAdded: ['', [Validators.maxLength(30)]],
      apuServiceBy: [''],
      apuPintsAdded: [''],
      // apu: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(regexPatternAPUHours)]],
      apu: ['', [Validators.maxLength(30)]],
      reason: ['', Validators.required],
      additionalComments: [''],
      isFeedBackCommetsEnable: [''],
      feedbackComments: [''],
      oldHourMeter: [0, []],
      newHourMeter: [0, []],
      meterReplaceDate: [''],
      cpn: [''],
      pn: ['']
    });
    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      this.employee = data;
      console.log(this.employee);
    });
    const engine1ServiceBy = this.oilAddForm.get('engine1ServiceBy');
    const engine1PintsAdded = this.oilAddForm.get('engine1PintsAdded');
    const engine2ServiceBy = this.oilAddForm.get('engine2ServiceBy');
    const engine2PintsAdded = this.oilAddForm.get('engine2PintsAdded');
    const apuServiceBy = this.oilAddForm.get('apuServiceBy');
    const apuPintsAdded = this.oilAddForm.get('apuPintsAdded');
    engine1ServiceBy.valueChanges.subscribe(val => {
      if (val) {
        engine1PintsAdded.setValidators([Validators.required, Validators.maxLength(30)]);
      } else {
        engine1PintsAdded.setValidators([]);
      }
      engine1PintsAdded.updateValueAndValidity();
    });

    engine2ServiceBy.valueChanges.subscribe(val => {
      if (val) {
        engine2PintsAdded.setValidators([Validators.required, Validators.maxLength(30)]);
      } else {
        engine2PintsAdded.setValidators([]);
      }
      engine2PintsAdded.updateValueAndValidity();
    });
    apuServiceBy.valueChanges.subscribe(val => {
      if (val) {
        apuPintsAdded.setValidators([Validators.required, Validators.maxLength(30)]);
      } else {
        apuPintsAdded.setValidators([]);
      }
      apuPintsAdded.updateValueAndValidity();
    });
    /*     const reason = this.oilAddForm.get('reason');
    const additionalComments = this.oilAddForm.get('additionalComments');
    const isFeedBackCommetsEnable = this.oilAddForm.get('isFeedBackCommetsEnable');
    const feedbackComments = this.oilAddForm.get('feedbackComments');
    const apuServiceBy = this.oilAddForm.get('apuServiceBy');
    const apuPintsAdded = this.oilAddForm.get('apuPintsAdded'); */

    /*     reason.valueChanges.subscribe(val => {
      if (val === 'orther') {
        additionalComments.setValidators([Validators.required]);
      } else {
        additionalComments.setValidators([]);
      }
      additionalComments.updateValueAndValidity();
    });
    apuServiceBy.valueChanges.subscribe(val => {
      if (val) {
        apuPintsAdded.setValidators([Validators.required, Validators.maxLength(30)]);
      } else {
        apuPintsAdded.setValidators([]);
      }
      apuPintsAdded.updateValueAndValidity();
    });
    isFeedBackCommetsEnable.valueChanges.subscribe(val => {
      if (val) {
        feedbackComments.setValidators([Validators.required]);
      } else {
        feedbackComments.setValidators([]);
      }
      feedbackComments.updateValueAndValidity();
    }); */
    if (!this.isFormCreate) {
      this.updateFormValuesEdit();
    } else {
      //this.oilAddForm.reset();
      this.submitted = false;
    }
  }
  get formData() {
    return this.oilAddForm.value;
  }

  showAdminFeedback() {
    this.isShowOSPAdmin = !this.isShowOSPAdmin;
  }
  openAPIErrorDialog() {
    this.apierrordialogeopend = true;
  }
 closeAPIErrorDialog() {
    this.apierrordialogeopend = false;
  }
  closeErrorDialog() {
    this.errordialogeopend = false;
    this.oilAddForm.reset();
    this.submitted = false;
    this.createEvent.emit(this.viewOilAddRecord);
  }
  saveAndUpdateDetails() {
    this.submitted = true;

    if (this.oilAddForm.invalid) {
      return;
    }
  }
  updateFormValuesEdit() {
    const oilData = this.editData.viewOSPDetails;
    console.log(oilData);
    this.oilAddForm.patchValue({
      aircraft: oilData.aircraft ? oilData.aircraft : 0,
      station: oilData.station ? oilData.station : 0,
      flight: oilData.flight ? oilData.flight : 0,
      totalShipTime: oilData.totalShipTime ? oilData.totalShipTime : 0,
      dateAddedLocal: oilData.dateAddedLocal ? new Date(oilData.dateAddedLocal) : 0,
      timesAddedLocal: oilData.dateAddedLocal ? new Date(oilData.dateAddedLocal) : '',
      timeZoneOffset: oilData.timeZoneOffset ? oilData.timeZoneOffset : 0,
      region: oilData.region ? oilData.region : 0,
      classification: oilData.classification ? oilData.classification : 0,
      engine1ServiceBy: oilData.engine1 ? oilData.engine1.servicedById : 0,
      engine2ServiceBy: oilData.engine2 ? oilData.engine2.servicedById : 0,
      engine1PintsAdded: oilData.engine1 ? oilData.engine1.pintsAdded : 0,
      engine2PintsAdded: oilData.engine2 ? oilData.engine2.pintsAdded : 0,
      apuServiceBy: oilData.apu ? oilData.apu.servicedById : 0,
      apuPintsAdded: oilData.apu ? oilData.apu.pintsAdded : 0,
      apu: oilData.apu ? oilData.apuHours : 0,
      reason: oilData.reason,
      additionalComments: oilData.comments
      //oilData.additionalComments
      // stationID: oilData.stationID,
      // alertMessage: oilData.alertMessage,
      // autoCloseOvernight: oilData.autoCloseOvernight,
      // autoGenerateTasks: oilData.autoGenerateTasks,
      // autoCloseOnTime: oilData.autoCloseOnTime,
      // manningStyle: oilData.manningStyle,
      // defaultPage: oilData.defaultPage,
      // stnName: oilData.stnName,
      // staffed: oilData.staffed
    });
    this.runGQLandRGN();
  }

  /*   updateFormValuesforAdd(dataitem) {
    this.oilAddForm.patchValue({      
      station: oilData.station? oilData.station : 0,
      flight: oilData.flight? oilData.flight : 0,
      totalShipTime: oilData.totalShipTime? oilData.totalShipTime : 0,
      dateAddedLocal: oilData.dateAddedLocal? new Date(oilData.dateAddedLocal) : 0, 
      timeZoneOffset: oilData.timeZoneOffset? oilData.timeZoneOffset : 0,
      region: oilData.region? oilData.region : 0,
      classification: oilData.classification? oilData.classification : 0,
      engine1ServiceBy: this.editData.engine1? JSON.stringify(this.editData.engine1.servicedByID).replace('"', '') : 0,
      engine2ServiceBy: this.editData.engine2? JSON.stringify(this.editData.engine2.servicedByID).replace('"', '') : 0,
      engine1PintsAdded: this.editData.engine1? JSON.stringify(this.editData.engine1.pintsAdded).replace('"', '') : 0,
      engine2PintsAdded: this.editData.engine2? JSON.stringify(this.editData.engine2.pintsAdded).replace('"', '') : 0,
      apuServiceBy: this.editData.apu? JSON.stringify(this.editData.apu.servicedByID).replace('"', '') : 0,
      apuPintsAdded: this.editData.apu? JSON.stringify(this.editData.apu.pintsAdded).replace('"', '') : 0,
      apu: this.editData.apu? JSON.stringify(this.editData.apu.serialNumber).replace('"', '') : 0,
      reason: oilData.reason,
      additionalComments: JSON.stringify(oilData)//oilData.additionalComments      
    });    
  } */

  runGQLandRGN() {
    // Running GQL service
    this.noseNumber = !this.isFormCreate ? this.editData.viewOSPDetails.aircraft : this.oilAddForm.controls.aircraft.value; // "3AC" // "702"
    this.showEngine = true;
    this.showLoading = true;
    this.getFleetDetails(this.noseNumber);
    this.getEngineInfo(this.noseNumber).subscribe(x => {
      if (x) {
        this.enginesList = x;
        this.showLoading = false;
        if (this.isFormCreate && this.enginesList && this.enginesList.length) {
          const engDetails = this.enginesList[0].componentStatus[0];
          this.oilAddForm.patchValue({
            cpn: engDetails.mfgSerialNumber,
            pn: engDetails.partNumber
          });
        }
      }
    });
    this.getSafeAircraftDetails(this.noseNumber);
    // if (this.isFormCreate) this.updateFormValuesforAdd();
  }
  getFleetDetails(noseNumber: string) {
    this.getFleetInfo(noseNumber).subscribe(fleetDetails => {
      if (fleetDetails) {
        this.fleetName = fleetDetails.aircraftDetails.fleet.fleetName;
      }
    });
  }

  getEngineInfo(noseNumber: string): Observable<any> {
    this.enginequery = this.gatewayService.query<any>(GET_ENGINE_INFO, {
      input: {
        noseNumber: noseNumber
      }
    });

    return (this.enginequery = this.enginequery.pipe(
      map((res: ApolloQueryResult<any>) => {
        return this.mapEngine(res.data.engines);
      }) /*  */
    ));
  }

  getFleetInfo(noseNumber: string): Observable<any> {
    this.aircraftquery = this.gatewayService.query<any>(GET_AIRCRAFT_INFO, {
      input: {
        airlineCode: 'US',
        noseNumber: noseNumber
      }
    });

    return (this.aircraftquery = this.aircraftquery.pipe(
      map((res: ApolloQueryResult<any>) => {
        return res.data.aircraft;
      }) /*  */
    ));
  }

  mapEngine(data: any) {
    const engineArray: EngineDetails[] = [];
    if (data) {
      let engineDetail: EngineDetails;
      data.engines.forEach((item: any) => {
        engineDetail = {
          componentStatus: this.mapComponentStatus(item.componentStatus)
        };
        engineArray.push(engineDetail);
      });
    }

    return engineArray;
  }

  mapComponentStatus(componentStatus: any): EngineComponentStatus[] {
    const componentStatusArray: EngineComponentStatus[] = [];
    (componentStatus || []).forEach((item: any) => {
      const engineTimeCycles: EngineComponentStatus = {
        partNumber: item.partNumber,
        serialNumber: item.serialNumber,
        mfgSerialNumber: item.mfgSerialNumber,
        description: item.partDescription,
        positionCode: item.positionCode,
        tsoCycles: item.tso.cycles,
        tsoHours: item.tso.hours,
        totalTimeCycles: item.totalTime.cycles,
        totalTimeHours: item.totalTime.hours,
        tsoAtInstallCycles: item.tsoAtInstall.cycles,
        tsoAtInstallHours: item.tsoAtInstall.hours,
        tsrCycles: item.tsr.cycles,
        tsrHours: item.tsr.hours
      };
      componentStatusArray.push(engineTimeCycles);
    });
    return componentStatusArray;
  }
  private getSafeAircraftDetails(acNumber: string): void {
    this.backendservice.getSafeAircraftDetails(acNumber, 'US').subscribe(
      (result: ISafeOspAircraft[]) => {
        const safeaircraftDetails = result;
        this.safeospaircraft = safeaircraftDetails.length ? safeaircraftDetails[0] : null;
        if (this.isFormCreate && this.safeospaircraft) {
          this.oilAddForm.patchValue({
            station: this.safeospaircraft.station,
            flight: this.safeospaircraft.flight,
            apu: this.safeospaircraft.apuHours,
            totalShipTime: this.safeospaircraft.totalShipHours
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  onNoseChange() {
    if (this.oilAddForm.controls.aircraft.value.length === 3 && this.isFormCreate) {
      // alert('Added Nose Number');
      // alert(this.oilAddForm.controls.aircraft.value.length);

      this.noseNumber = this.oilAddForm.controls.aircraft.value;
      this.runGQLandRGN();
      // this.updateFormValuesEdit();
      // this.clearAutoPopulateData();
      // this.loadingEngines = true;
      // this.listEGTEZone = [];
      // this.getTimesandCycles(this.createEGTEReportForm.controls.aircraftnosenumber.value);
      // this.getEngineDesandGroup(this.createEGTEReportForm.controls.aircraftnosenumber.value);
    }
  }
  noSpecialChars(event: KeyboardEvent) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || (k >= 48 && k <= 57)) {
      return;
    }
    e.preventDefault();
  }
}
