/*
 * Public API Surface of siteminder-authentication
 */
export {
  IdleEndAction,
  IdleStartAction,
  SessionExtendAction,
  SiteminderAuthenticationActions,
  SiteminderAuthenticationActionTypes,
  TimeOutAction,
  TimeoutWarningAction,
  UnauthorizedRequestAction
} from './lib/+state/siteminder-authentication.actions';
export { SiteminderUser } from './lib/+state/siteminder-authentication.interfaces';
export {
  getAccessToken,
  getAttributeValue,
  getHasAnyRole,
  getHasRole,
  getIsUserIdle,
  getIsUserInitialized,
  getIsUserTimedOut,
  getRedirectUrl,
  getSecondsToTimeOut,
  getSMSession,
  getUser
} from './lib/+state/siteminder-authentication.reducer';
export { HasRoleDirective } from './lib/has-role.directive';
export { SiteminderAgentConfig } from './lib/siteminder-authentication-config';
export { SiteminderAuthenticationModule } from './lib/siteminder-authentication.module';
export { SiteminderSessionTimeoutWarningDialogComponent } from './lib/siteminder-session-timeout-warning-dialog/siteminder-session-timeout-warning-dialog.component';
export { MockStore } from './lib/+state/siteminder-authentication.test-data';
