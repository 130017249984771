import { Component, Input } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { NavigationItem } from '@techops-ui/common/data-access/navigation';

@Component({
  selector: 'aa-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.css']
})
export class HelpPopupComponent {
  @Input()
  show: boolean;
  @Input()
  anchor: string;
  @Input()
  items: NavigationItem[];
  @Input()
  version: string;

  anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  margin = { horizontal: -15, vertical: 20 };
  duration = 300;
  type = 'fade';
  direction = 'down';

  get animate(): any {
    return {
      type: this.type,
      direction: this.direction,
      duration: this.duration
    };
  }
  close() {
    //Missing
  }
}
