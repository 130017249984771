import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor() {}

  pdf(blob: Blob) {
    const newBlob = new Blob([blob], { type: 'application/pdf' });
    const url = URL.createObjectURL(newBlob);
    return url;
  }
}
