import { LtermState } from './lterm.interfaces';

export const initialUserLtermState: LtermState = {
  userid: '',
  stationCode: '',
  defaultPrinter: '',
  lterms: [],
  Savedlterms: [],
  loadState: 'Load State initial',
  defaultStation: '',
  isLoading: false,
  errors: null
};
