import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from '../../models';
import { Navigation } from '@techops-ui/common/data-access/navigation';

@Component({
  selector: 'aa-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent {
  @Input()
  showPreferences: boolean;
  @Input()
  product: string;
  @Input()
  appName: string;
  @Input()
  employee: Employee;
  @Input()
  logoutUrl: string;
  @Input()
  navigation: Navigation;
  @Input()
  showApps: Boolean;
  @Input()
  apps: Navigation;
  @Output()
  close = new EventEmitter();
  @Output()
  showPrefsPopup = new EventEmitter();

  selectedMenuItem = 'Menu';

  readonly MENU_MAIN = 'Menu';
  readonly MENU_APPS = 'Apps';
  readonly MENU_HELP = 'Help';
  readonly MENU_ACCOUNT = 'Account';

  selectMenuItem(menuItem: string) {
    this.selectedMenuItem = menuItem;
  }

  closeSideMenu(): void {
    this.close.emit();
  }

  userPreferencesClicked() {
    this.close.emit();
    this.showPrefsPopup.emit();
  }
}
