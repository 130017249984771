import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flightSymbol'
})
export class FlightSymbolPipe implements PipeTransform {
  transform(value: string): any {
    if (value == null) {
      return null;
    }
    return value.toLowerCase().indexOf('aa') > -1 ? 'assets/logo-LAA.png' : 'assets/logo-LUS.png';
  }
}
