import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteminderUser } from '@techops-ui/common/siteminder-authentication';
import { BackendService,PermissionOSP } from '@techops-ui/osp/data-access-root';


@Component({
  selector: 'techops-ui-view-threshold-screen',
  templateUrl: './view-threshold-screen.component.html',
  styleUrls: ['./view-threshold-screen.component.css']
})
export class ViewThresholdScreenComponent implements OnInit {
  addThresholdForm: FormGroup;
  formGroup: FormGroup;
  @Input() isFormCreate: boolean;
  @Input() editData: any;
  @Input() roleAccess:PermissionOSP;
  submitted: boolean;
  public calculationMethods: Array<string> = ['N/A', '25', '100'];
  public partType: Array<string> = ['E', 'A'];
  public suddenIncreasMethod: Array<string> = ['Option A', 'Option B', 'Option C'];
  public manual: Array<string> = ['AMM', 'FIM', 'TSM'];
  public opened = true;
  public user: SiteminderUser;
  errorMessage: string;
  errordialogeopend = false;
  isSelectedSuddenIncress = false;
  @Output() createEvent = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private backendservice: BackendService) {}

  ngOnInit() {
    const urlreg = /^(https|HTTPS?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www|WWW\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    this.addThresholdForm = this.formBuilder.group({
      cpn: ['', [Validators.required, Validators.maxLength(14)]],
      engineType: ['', [Validators.required, Validators.maxLength(20)]],
      partType: ['', [Validators.required]],
      effectiveDateUtc: ['', [Validators.required]],
      suddenIncreaseMethod: ['', [Validators.required, Validators.maxLength(255)]],
      suddenIncrease: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^([0-9]{0,7})(\.[0-9]{1,2})?$/)]],
      manualType: ['', Validators.required],
      runningAverageNormal: ['', [Validators.required, Validators.pattern(/^([0-9]{0,7})(\.[0-9]{1,2})?$/)]],
      runningAverageHigh: ['', [Validators.required, Validators.pattern(/^([0-9]{0,7})(\.[0-9]{1,2})?$/)]],
      avgCalcMethod: ['', Validators.required],
      spotOilNormal: ['', [Validators.required, Validators.pattern(/^([0-9]{0,7})(\.[0-9]{1,2})?$/)]],
      spotOilHigh: ['', Validators.required],
      spotCalcMethod: ['', Validators.required],
      ammtaskUrl: ['', [Validators.pattern(urlreg)]],
      atareference: ['', [Validators.pattern(urlreg)]],
      manualTaskIdentifier: ['', [Validators.maxLength(100)]],
      atareferenceEng2: ['', [Validators.pattern(urlreg)]],
      manualTaskIdentifierEng2: ['', [Validators.maxLength(100)]],
      acl: ['', [Validators.maxLength(100)]],
      ndcl: ['', [Validators.maxLength(100)]]
    });

    if (!this.isFormCreate) {
      this.updateFormValues();
    } else {
      this.addThresholdForm.reset();
      this.submitted = false;
    }
  }

  onSuddenIncressChange(){
    
    if (this.addThresholdForm.value.suddenIncreaseMethod === 'Option A') {
     this.isSelectedSuddenIncress = true;
    } else {
     this.isSelectedSuddenIncress = false;
    }
  }

  onPartTypeChange() {
    if (this.addThresholdForm.value.partType === 'A') {
      this.addThresholdForm.patchValue({ suddenIncreaseMethod: 'Option C' });
      this.addThresholdForm.controls['suddenIncreaseMethod'].disable();
    } else {
      this.addThresholdForm.controls['suddenIncreaseMethod'].enable();
    }
  }
  public onAccept() {
    this.submitted = true;
    if (this.addThresholdForm.invalid) {
      return;
    }
    const formData = this.addThresholdForm.value;
    const getFormRawValues = this.addThresholdForm.getRawValue();
    formData.suddenIncrease = Number(formData.suddenIncrease);
    formData.runningAverageNormal = Number(formData.runningAverageNormal);
    formData.runningAverageHigh = Number(formData.runningAverageHigh);
    formData.spotOilNormal = Number(formData.spotOilNormal);
    formData.spotOilHigh = Number(formData.spotOilHigh);
    formData.suddenIncreaseMethod = getFormRawValues.suddenIncreaseMethod;

    if (!this.isFormCreate) {
      this.backendservice.updateThresholdList(formData, this.editData.id).subscribe(
        data => {
          if (data) {
            this.errorMessage = 'Threshold update successfully';
            this.errordialogeopend = true;
           // this.createEvent.emit();
            this.submitted = false;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      this.backendservice.postThresholdList(formData).subscribe(
        data => {
          if (data) {
            this.errorMessage = 'Threshold saved successfully';
            this.errordialogeopend = true;
           // this.createEvent.emit();
            this.addThresholdForm.reset();
            this.submitted = false;
          }
          //alert("saved"+result)
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  updateFormValues() {
    const thresholdEdit = this.editData;

    this.addThresholdForm.patchValue({
      cpn: thresholdEdit.cpn,
      engineType: thresholdEdit.engineType,
      partType: thresholdEdit.partType,
      effectiveDateUtc: thresholdEdit.effectiveDateUtc,
      suddenIncreaseMethod: thresholdEdit.suddenIncreaseMethod,
      suddenIncrease: thresholdEdit.suddenIncrease,
      manualType: thresholdEdit.manualType,
      runningAverageNormal: thresholdEdit.runningAverageNormal,
      runningAverageHigh: thresholdEdit.runningAverageHigh,
      avgCalcMethod: thresholdEdit.avgCalcMethod,
      spotOilNormal: thresholdEdit.spotOilNormal,
      spotOilHigh: thresholdEdit.spotOilHigh,
      spotCalcMethod: thresholdEdit.spotCalcMethod,
      ammtaskUrl: thresholdEdit.ammtaskUrl,
      atareference: thresholdEdit.atareference,
      manualTaskIdentifier: thresholdEdit.manualTaskIdentifier,
      atareferenceEng2: thresholdEdit.atareferenceEng2,
      manualTaskIdentifierEng2: thresholdEdit.manualTaskIdentifierEng2,
      acl:thresholdEdit.acl,
      ndcl:thresholdEdit.ndcl
    });
    this.onSuddenIncressChange();
  }
  public close() {
    this.opened = false;
  }


  deleteThresholdValues(thresoldId: number) {
    this.backendservice.deleteTheresholdValue(thresoldId).subscribe(
      (result: string) => {
        if (result) {
          this.errorMessage = 'Threshold Deleted Successfully';
          
         
          
          this.errordialogeopend = true;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  closeErrorDialog(){
    this.errordialogeopend = false;
    this.createEvent.emit();
    this.addThresholdForm.reset();
  }
  public open() {
    this.opened = true;
  }
}
