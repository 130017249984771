import { Action } from '@ngrx/store';
import { LtermData } from './lterm.interfaces';

export enum LtermActionTypes {
  LoadUserLterms = '[LTERM] LoadUserLterms',
  LoadUserLtermsSuccess = '[LTERM] LoadUserLtermsSuccess',
  LoadUserLtermsFail = '[LTERM] LoadUserLtermsFail',
  LoadUserLtermsByUserId = '[LTERM] LoadUserLtermsByUserId',
  LoadUserLtermsSuccessByUserId = '[LTERM] LoadUserLtermsSuccessByUserId',
  LoadUserLtermsFailByUserId = '[LTERM] LoadUserLtermsFailByUserId',
  SaveUserLterms = '[LTERM] SaveUserLterms'
}

export class LoadUserLterms implements Action {
  readonly type = LtermActionTypes.LoadUserLterms;

  constructor(public payload: { userid: string; station: string }) {}
}
export class LoadUserLtermsSuccess implements Action {
  readonly type = LtermActionTypes.LoadUserLtermsSuccess;
  constructor(public payload: LtermData[]) {}
}

export class LoadUserLtermsFail implements Action {
  readonly type = LtermActionTypes.LoadUserLtermsFail;
  constructor(public payload: any) {}
}

export class LoadUserLtermsByUserId implements Action {
  readonly type = LtermActionTypes.LoadUserLtermsByUserId;
  constructor(public payload: { userid: string }) {}
}
export class LoadUserLtermsSuccessByUserId implements Action {
  readonly type = LtermActionTypes.LoadUserLtermsSuccessByUserId;
  constructor(public payload: LtermData[]) {}
}

export class LoadUserLtermsFailByUserId implements Action {
  readonly type = LtermActionTypes.LoadUserLtermsFailByUserId;
  constructor(public payload: any) {}
}

export class SaveUserLterms implements Action {
  readonly type = LtermActionTypes.SaveUserLterms;

  constructor(public payload: { defaultStation: string; DefaultPrinter: string; Lterms: LtermData[] }) {}
}
export type LtermActions =
  | LoadUserLterms
  | LoadUserLtermsSuccess
  | LoadUserLtermsFail
  | SaveUserLterms
  | LoadUserLtermsByUserId
  | LoadUserLtermsSuccessByUserId
  | LoadUserLtermsFailByUserId;
