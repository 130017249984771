export interface IThresholdReport {
  id: number;
  cpn: string;
  engineType: string;
  spotOilNormal: number;
  spotOilHigh: number;
  suddenIncrease: number;
  spotCalcMethod: string;
  avgCalcMethod: string;
  runningAverageNormal: number;
  runningAverageHigh: number;
  effectiveDateUtc: Date;
  dateInserted: Date;
  suddenIncreaseMethod: string;
  atareference: string;
  url: string;
  manualType: string;
  manualTaskIdentifier: string;
  acl: number;
  ndcl: number;
  partType: string;
  ammtaskUrl: string;
  atareferenceEng2?: string;
  manualTaskIdentifierEng2?: string;
  isExpanded?: boolean;
}
export interface IThreshold {
  id: number
  fleet: string
  noseNumber: string
  eC_Threshold: number
  eE_Threshold: number
  eC_OffSet: number
  eE_OffSet: number
  updatedBy: string
  updatedDate: string
}
export interface IAdminReport {
  stationID: number;
  airline: string;
  stationCode: string;
  alertMessage: string;
  autoCloseOvernight: boolean;
  autoGenerateTasks: boolean;
  autoCloseOnTime: boolean;
  timeZoneOffset: number;
  classification: number;
  region: string;
  manningStyle: string;
  defaultPage: string;
  stnName: string;
  staffed: boolean;
}

export interface IFeedBack{
  id: number
  comment: string
  response: string
  createdBy: string
  modifiedBy: string
  createdDate: string
  modifiedDate: string
  aircraft: any
  flight: any
  station: any
  totalShipTime: any
  eng1: any
  eng2: any
  apu: any
  oilAddId: any
}
export class ThresholdReport {
  id: number | null;
  cpn: string | null;
  engineType: string | null;
  spotOilNormal: number | null;
  spotOilHigh: number | null;
  suddenIncrease: number | null;
  spotCalcMethod: string | null;
  avgCalcMethod: string | null;
  runningAverageNormal: number | null;
  runningAverageHigh: number | null;
  effectiveDateUtc: Date | null;
  dateInserted: Date | null;
  suddenIncreaseMethod: string | null;
  atareference: string | null;
  url: string | null;
  manualType: string | null;
  manualTaskIdentifier: string | null;
  acl: number | null;
  ndcl: number | null;
  partType: string | null;
  ammtaskUrl: string | null;
  atareferenceEng2?: string | null;
  manualTaskIdentifierEng2?: string | null;
  isExpanded?: boolean | null;
}
export class IAircraftReport {
  acid: number;
  cc: string;
  ac: string;
  fleet: string;
  status: string;
  engineType: string;
  dataSource: string;
}
export class INotification {
  id?:number;
  decsFlee: string;
  emails:string;
  phone: string;
  employeeId:string;
  carrier: string;
}

export class IFleet {
  id: number;
  carrier: string;
  fleet: string;
  ospUserFleetMapping: string[];
}

export interface IOilAdd {
  dateAddedLocal: Date;
  aircraft: string;
  flight: string;
  totalShipTime: Date;
  engine1: IEngine;
  engine2: IEngine;
  apu: IEngine;
  authorizationCode: string;
}

interface IEngine {
  hoursSinceLastPositiveOilAdd: string;
  serialNumber: number;
  pintsAdded: string;
  spotRate: string;
  averageRate: string;
  predictedOilAdd: string;
}

export interface IEngineDetails {
  number: number;
  carrier: number;
  fleet: number;
  station: string;
  flight: number;
  totalShipHours: number;
  apuHours: number;
  hasEngineChange: boolean;
  phone: string;
  servicedEngines: string;
  ammEngUrl: string;
  ammApuUrl: string;
  dataSource: string;
  mtc: string;
  dateAddedLocal: string;
  eng1PtsAdded: number;
  eng2PtsAdded: number;
  apuPtsAdded: number;
  servicedBy: string;
  authCode: string;
  aircraft: string;
  position: string;
  voids: string;
}

export interface ISafeOspAircraft {
  number: string;
  carrier: string;
  fleet: string;
  station: string;
  flight: string;
  totalShipHours: 0;
  apuHours: 0;
  hasEngineChange: true;
  phone: string;
  servicedEngines: [string];
  ammEngUrl: string;
  ammApuUrl: string;
  dataSource: string;
  mtc: string;
  dateAddedLocal: Date;
  eng1PtsAdded: 0;
  eng2PtsAdded: 0;
  apuPtsAdded: 0;
  servicedBy: string;
  authCode: string;
  aircraft: string;
  position: string;
  voids: [null];
}


export interface PredicitveDataCount {
  posOutout:PredicitveData[]
  count:number
}

export interface GetPosThresholds {
  id: number
  fleet: string
  noseNumber: string
  eC_Threshold: number
  eE_Threshold: number
  eC_OffSet: number
  eE_OffSet: number
  updatedBy: string
  updatedDate: string
}

export interface PredicitveData {
  noseNumber: string
  engineType: string
  arrivalStation: string
  arrivalStationClass: number
  estimateArrival: string
  eC_EngineFlag: boolean
  eC_OilNeeded: number
  eE_EngineFlag: boolean
  eE_OilNeeded: number
  nxtFlightNumber: any
  nxtFlightOrgDateTime: any
  nxtDepartStation: any
  nxtArrivalStation: string
  nxtArrivalStationClass: number
  nxtEstimateArrival: string
  nxtEC_EngineFlag: boolean
  nxtEC_OilNeeded: any
  nxtEE_EngineFlag: boolean
  nxtEE_OilNeeded: number
  acmS_MessageUTC: string
 
}