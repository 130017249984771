// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { OSPAppEnvironment, OSPEnvironment } from '@techops-ui/osp/shared';
import { OSPEnvironmentDev, OSPEnvironmentLocal, OSPEnvironmentProd, OSPEnvironmentStage, OSPEnvironmentTest } from '../app/configuration';

export let environment: OSPAppEnvironment = OSPEnvironmentDev;

switch (location.hostname) {
  case OSPEnvironment.Local:
    environment = OSPEnvironmentLocal;
    break;
  case OSPEnvironment.Dev:
    environment = OSPEnvironmentDev;
    break;

  case OSPEnvironment.Stage:
    environment = OSPEnvironmentStage;
    break;

  case OSPEnvironment.Prod:
    environment = OSPEnvironmentProd;
    break;
  case OSPEnvironment.Test:
    environment = OSPEnvironmentTest;
    break;
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
