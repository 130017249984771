import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasMenuItemsPipe } from './has-menu-items/has-menu-items.pipe';
import { EmployeeInitialsPipe } from './employee-initials/employee-initials.pipe';
import { FlightSymbolPipe } from './flight-symbol/flight-symbol.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HasMenuItemsPipe, EmployeeInitialsPipe, FlightSymbolPipe],
  exports: [HasMenuItemsPipe, EmployeeInitialsPipe, FlightSymbolPipe]
})
export class PipesModule {}
