import { NgModule } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { PrintService } from './services/print.service';
import { HttpService } from './services/http.service';
import { NoCacheInterceptor } from './interceptors/no-cache.interceptor';

@NgModule({
  providers: [AuthenticationService, PrintService, HttpService, NoCacheInterceptor]
})
export class HelpersModule {}
