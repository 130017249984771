import { Navigation } from '@techops-ui/common/data-access/navigation';

export const techopsApps: Navigation = {
  NavItems: [
    {
      Text: 'Line & MOC',
      Icon: 'aa-icon-appicon-linemoc',
      Root: '',
      RouterLink: '',
      Path: 'https://techopshub.me.aa.com',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Engineering',
      Icon: 'aa-icon-appicon-engineering',
      Root: '',
      RouterLink: '',
      Path: 'https://engineering-techops.apps.aa.com/lookup',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Stores',
      Icon: 'aa-icon-appicon-stores',
      Root: '',
      RouterLink: '',
      Path: 'https://stores-techops.apps.aa.com/',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'Parts Ordering',
      Icon: 'aa-icon-appicon-partsrequest',
      Root: '',
      RouterLink: '',
      Path: 'https://partsrequests-techops.apps.aa.com/lookup/search',
      Visible: true,
      Collapse: true,
      SubNav: []
    },
    {
      Text: 'PO/RO Receiving',
      Icon: 'aa-icon-appicon-partsreceiving',
      Root: '',
      RouterLink: '',
      Path: 'https://partsreceiving-techops.apps.aa.com/',
      Visible: true,
      Collapse: true,
      SubNav: []
    }
  ],
  HelpItems: [],
  AccountItems: [],
  Version: '',
  Opened: null
};
