import { OSPAppEnvironment } from '@techops-ui/osp/shared';
import { GatewayEnvironmentDev } from '@techops-ui/common/data-access/gateway';
import { TFPGatewayEnvironmentDev } from '@techops-ui/common/data-access/tfp-gateway';

export const OSPEnvironmentDev: OSPAppEnvironment = {
  production: false,
  ospeventsApi: 'https://osp-api-qa.maverick.aa.com/api',
  //Gateway
  ...GatewayEnvironmentDev,
  // feature flags
  // if needed add dependency here

  //TFP
  tfpgatewayApi: TFPGatewayEnvironmentDev.tfpgatewayApi + 'graphql',
  tfpAppName: 'osp',
  tfpVersion: '1.0',
  username: '',
  password: ''
};
