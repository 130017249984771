import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasMenuItems',
  pure: false
})
export class HasMenuItemsPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value !== '') {
      if (value.SubNav && value.SubNav.length) {
        for (let i = 0; i < value.SubNav.length; i++) {
          if (value.SubNav[i].Items && value.SubNav[i].Items.length && value.SubNav[i].Items.filter((x: any) => x.Visible === true).length) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
