import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationItem } from '@techops-ui/common/data-access/navigation';
import { Router } from '@angular/router';

@Component({
  selector: 'aa-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.css']
})
export class NavigationTreeComponent implements OnInit {
  @Input()
  items: NavigationItem[];
  @Input()
  showIcons: boolean;
  @Output()
  close = new EventEmitter();

  constructor(private router: Router) {}

  get path() {
    return this.router.url;
  }

  ngOnInit() {}

  linkClicked(action?: Function): void {
    if (action) {
      action();
    }
    this.close.emit();
  }
}
