import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'techops-ui-safe-osp-landingpage',
  templateUrl: './safe-osp-landingpage.component.html',
  styleUrls: ['./safe-osp-landingpage.component.css']
})
export class SafeOspLandingpageComponent implements OnInit {
  oilAddForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {}

  ngOnInit() {
    
    this.oilAddForm = this.formBuilder.group({
      aircraft: ['', Validators.required]
    });
    this.route.queryParams.subscribe(params => {
      if (params['airCraftNumber']) {
        this.oilAddForm.patchValue({ aircraft: params['airCraftNumber'] });
      }
    });
  }

  redirectByAC() {
    const formValue = this.oilAddForm.value;
    this.router.navigate(['usersafeoiladd', formValue.aircraft.toUpperCase()]);
  }
}
