import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppsPopupComponent } from './components/apps-popup/apps-popup.component';
import { HelpPopupComponent } from './components/help-popup/help-popup.component';
import { UserPopupComponent } from './components/user-popup/user-popup.component';
import { HeaderComponent } from './components/header/header.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AppItemComponent } from './components/app-item/app-item.component';
import { CommonDataAccessNavigationModule } from '@techops-ui/common/data-access/navigation';
import { NavigationTreeComponent } from './components/navigation-tree/navigation-tree.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@techops-ui/common/pipes';
import { VersionComponent } from './components/version/version.component';
import { CommonLtermUiModule } from '@techops-ui/common/feature-lterm';
import { CommonDataAccessLtermModule } from '@techops-ui/common/data-access-lterm';
import { IsSelectedPipe } from './pipes/is-selected.pipe';
import { HeadroomModule } from '@ctrl/ngx-headroom';

@NgModule({
  imports: [
    CommonModule,
    PopupModule,
    CommonDataAccessNavigationModule,
    RouterModule,
    PipesModule,
    HeadroomModule,
    CommonLtermUiModule,
    CommonDataAccessLtermModule
  ],
  declarations: [
    AppsPopupComponent,
    HelpPopupComponent,
    UserPopupComponent,
    HeaderComponent,
    SideMenuComponent,
    AppItemComponent,
    NavigationTreeComponent,
    VersionComponent,
    IsSelectedPipe
  ],
  exports: [HeaderComponent, AppItemComponent]
})
export class TechopsHeaderModule {}
