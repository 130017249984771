import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, take, tap } from 'rxjs/operators';
import { v1 as uuidv1 } from 'uuid';
import { AUTHORIZATION, SiteminderAgentConfig, SMSESSION, SM_AGENT_CONFIG, X_CLIENT_ID, X_TRANSACTION_ID } from './siteminder-authentication-config';
import { SiteminderAuthenticationService } from './siteminder-authentication.service';
@Injectable({
  providedIn: 'root'
})
export class SiteminderAuthenticationInterceptorService implements HttpInterceptor {
  constructor(@Inject(SM_AGENT_CONFIG) private config: SiteminderAgentConfig, private authService: SiteminderAuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if accessing siteminder agent url then just continue
    if (req.url === this.config.url) {
      return next.handle(req).pipe(
        filter((response: HttpResponse<any>) => response.type === HttpEventType.Response),
        tap((evnt: HttpResponse<any>) => {
          if (!(evnt.body && evnt.body.sm_access_token)) {
            this.authService.unAuthorizeRequest(req.url);
            throw new Error('Siteminder Session Timedout.Redirecting to login page...');
          }
        }),
        catchError((err: HttpErrorResponse, _) => {
          this.authService.unAuthorizeRequest(req.url);
          return throwError(err);
        })
      );
    }
    return this.authService.user().pipe(
      take(1),
      mergeMap(user => {
        let headers = req.headers.set(AUTHORIZATION, `Bearer ${user.sm_access_token}`);
        if (this.config.includeSMSession) {
          const smSession = user.sm_session || '';
          headers = headers.set(SMSESSION, smSession);
        }
        if (this.config.includeClientId) {
          headers = headers.set(X_CLIENT_ID, this.config.app_id);
        }
        if (this.config.includeTransactionId) {
          headers = headers.set(X_TRANSACTION_ID, uuidv1());
        }
        const authReq = req.clone({
          headers: headers
        });
        return next.handle(authReq).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.authService.unAuthorizeRequest(req.url);
            }

            return throwError(err);
          })
        );
      })
    );
  }
}
